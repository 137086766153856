import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CircularProgress, Grid } from '@mui/material';
import TableMui from '../mui/TableMui';
import { Link } from 'react-router-dom';
import Paginate from '../mui/Paginate';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import apiClient from '../api/apiClient';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
const limit=5;
const Manger = () => {
  const filter = useSelector((state) => state.filter?.filter);
  const [data, setdata] = useState([])
  const [ShowDel, setShowDel]= useState(false);
  const [id,setId]=useState()
  const [loading, setloading]= useState(false)
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0)


  const fatchData = async () => {
    setloading(true);
  
    try {
      const result = await apiClient.get(`/user/user?location=${filter || ""}&page=${page}&limit=${limit}`);
      console.log(result, "Fetched data");
  
      // Ensure the data is correctly handled before setting the state
      const fetchedData = result?.data?.results?.map((item, index) => ({
        ...item,
        sr1: page === 1 ? index + 1 : (page - 1) * limit + (index + 1), // Calculate serial number
      })) || [];
  
      // Set data and count in the state
      setdata(fetchedData);
      if (!count) setCount(result?.data?.count || 0);
  
    } catch (error) {
      console.error("Error fetching data:", error);
      // setError("Failed to fetch data. Please try again later.");
    } finally {
      setloading(false);
    }
  };


  useEffect(() => {
    fatchData()
  }, [filter,page]);

  

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  console.log(data, "uuu")


  // const data = [
  //   {
  //     number: "1",
  //     Name: "Usman",
  //     Email: "learner@gmail.com",
  //     Contact: "090078601",
  //     Location: "Sialkot",
  //   },
  //   {
  //     number: "2",
  //     Name: "John",
  //     Email: "john@example.com",
  //     Contact: "123456789",
  //     Location: "New York",
  //   },
  //   {
  //     number: "3",
  //     Name: "Alice",
  //     Email: "alice@example.com",
  //     Contact: "987654321",
  //     Location: "London",
  //   },
  //   {
  //     number: "4",
  //     Name: "Bob",
  //     Email: "bob@example.com",
  //     Contact: "555555555",
  //     Location: "Tokyo",
  //   },
  //   {
  //     number: "5",
  //     Name: "Eva",
  //     Email: "eva@example.com",
  //     Contact: "111111111",
  //     Location: "Paris",
  //   },
  //   {
  //     number: "6",
  //     Name: "David",
  //     Email: "david@example.com",
  //     Contact: "999999999",
  //     Location: "Berlin",
  //   },
  // ];
  
  return (
    <div>
  <div className="d-flex justify-content-end">
     
      <Link to="/add-manager">
        <Button variant="contained" className='btn btn_bg'>
          Add Manager
        </Button>
      </Link>
    </div>


    <Box sx={{my:5}}>

    {!loading?<TableMui
    styleTableThead={{ 
    '& th': {
    color:"white",
    backgroundColor:"#4dbdb7",
    fontWeight: "700",
    whiteSpace: "nowrap",
    },
    }}
    th={{
    role: "Role",
    name: "Name",
    email: "Email",
    contact: "contact",
    location:"location",
    action:"Action",
    edit:'Edit'
    }}
    td={data}
    customFields={[
    {
    name: "location",
    data: (value, item) => (
      <Box className="" style={{width:"100px"}}>
        <>
        {item.location?.map((item)=>
        <p>
          {item.loc_no +" "+ item.location } 
        </p>
        )}
        </>
      {/* <p>{value.map(item=>item.location, item.loc_no ).join(",")}</p> */}
      {/* <p>{item.location.location}</p> */}
      </Box>
    )
    },
    

    {
      name: "action",
      data: (value, item) => (
        <Box className="" style={{width:"100px"}}>
         <DeleteIcon 
                  onClick={()=>  {setShowDel(true);
                      setId(item._id)
                  }
                  }
                    style={{ color: 'red' }}
              />
        {/* <p>{value.map(item=>item.location, item.loc_no ).join(",")}</p> */}
        {/* <p>{item.location.location}</p> */}
        </Box>
      )
      }
      ,

    {
      name: "edit",
      data: (value, item) => (
        <Box className="" style={{width:"100px"}}>
           <Link to="/edit-manager"  state={item}>
         <EditIcon 

                    style={{ color: '#009685' }}
              />
           </Link>

        {/* <p>{value.map(item=>item.location, item.loc_no ).join(",")}</p> */}
        {/* <p>{item.location.location}</p> */}
        </Box>
      )
      }




    ]}

    />:<div className="d-flex justify-content-center align-items-center"><CircularProgress/></div> }

                        { ShowDel  &&
                             <DelModal
                             fatchData={fatchData}
                            id ={id}
                            show= {ShowDel}
                            onHide={()=>{setShowDel(false)}}
                            />
                              } 

{/* <Paginate count={count} limit={limit} page={page} onChange={handlePageChange}/> */}


    </Box>
    <div className=" d-flex justify-content-end my-3">
        <Paginate count={count} limit={limit} page={page} onChange={handlePageChange} />
      </div>
    </div>
  )
}

export default Manger


function DelModal(props) {
  console.log(props.id, "manger id");
  const handleDelete=async()=>{
    const result=await apiClient.delete(`/user/user/${props.id}`)
    // console.log(result.data.message, "ress")
    if(result.ok) return  toast.success("Manager deleted successfully" )
    props.fatchData();
  }
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className=' ' closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className='text-center w-100'>
        <Box
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              backgroundColor: '#3E4755',
              p: '4px',
            }}
          >
  <DeleteIcon style={{ fontSize: '18px', color: 'white' }} />
    </Box>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className='text-center'>Delete Manager</h4>
        <p className='text-center'>
       Are you sure you want to delete <br /> this Manager?
        </p>
      </Modal.Body>
      <Modal.Footer className='d-flex flex-column' >
          <Button onClick={()=>{
          handleDelete();
          props.onHide();
          }} sx={{borderRadius:"20px"}} fullWidth variant='contained' className='btn_bg_secondery'>
          Yes
        </Button>
    

        <Button sx={{borderRadius:"20px"}} 
        onClick={()=>{
          props.onHide()
        }}
         fullWidth variant='contained' className='mt-2 btn_bg_secondery'>
          No
        </Button>

    </Modal.Footer>
        </Modal>
  );
}