import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CircularProgress, Grid } from '@mui/material';
import TableMui from '../mui/TableMui';
import apiClient from '../api/apiClient';
import { toast } from "react-toastify";
import { formatDate } from '../modules/helpers';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Paginate from '../mui/Paginate';
import TextDisplay from '../components/TextDisplay'
import { FaTrash } from 'react-icons/fa';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover, // Hover background color
    color: theme.palette.error.main, // Change icon color on hover
  },
}));


const limit = 10;

const Resident = () => {
  const filter = useSelector((state) => state.filter?.filter);
  const [data, setdata] = useState([]);
  const [feedbackcount, setfeedbackcount] = useState("")
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const {userType} = useSelector((state)=> state.auth )




  const handleDelete = async (id) => {
    try {
      // Make the delete request
      const response = await apiClient.delete(`/feedback/${id}`);

      // Check if the response was successful
      if (response.status === 204) {
        toast.success("Feedback deleted successfully!");
      } else {
        // Handle the case where the deletion was not successful
        toast.error(response.data?.message || "Failed to delete feedback.");
      }
    } catch (error) {
      // Handle any errors that occurred during the request
      toast.error(`Error: ${error.message || "An error occurred while deleting feedback."}`);
    }
    fetchdata()
    //    setdata((pre)=>
    // pre.map((feedback)=>feedback._id.toString() !== id)  
    //   )
  };

  const fetchdata = async () => {
    try {
      setLoading(true);
      // seterror(false); // Reset error state before starting new request

      const result = await apiClient.get(`/feedback?feedbackType=resident&location=${filter || ""}&page=${page}&limit=${limit}`);
      console.log(result, "showround data");

      let fetchedData = result?.data?.feedback?.map((item) => ({
        ...item,
        fullName: `${item.first_name} ${item.last_name}`, // Concatenate firstName and lastName
        dateOfRequest: formatDate(item.dateOfRequest),
        dateOfVisit: formatDate(item.dateOfVisit)
      }));

      let counts = result?.data;
      setfeedbackcount(counts);

      for (let index = 0; index < fetchedData?.length; index++) {
        let sr;
        if (page === 1) {
          sr = (page - 1) + (index + 1); // For page 1
        } else {
          sr = (page - 1) * limit + (index + 1); // For other pages
        }
        fetchedData[index].sr1 = sr;
      }

      if (!count) setCount(result?.data?.count);
      console.log(result.data?.results, "asas");

      setdata(result?.data?.results || []); // Ensure data is set even if results is null
      console.log("certificate data", result?.data?.results);

      console.log(fetchedData);
      setdata(fetchedData || []); // Ensure data is set even if data is null
    } catch (error) {
      console.error("Error fetching data:", error);
      // seterror(true); // Set error state to handle UI updates
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  useEffect(() => {
    fetchdata()
  }, [filter, page]);

  console.log(data, "fetch data")

  return (
    <div
    className=' mt-5 mt-md-0'
    
    >
      <Box className="container ">

        <Grid container spacing={2}>

          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ maxWidth: 300, backgroundColor: "#FEFFD1", padding: 1 }}>
              <CardContent sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "12px" }}>
                <Typography className='d-flex justify-content-center align-items-center text-white p-3 shadow-sm  rounded' sx={{ width: "40px", height: "40px", backgroundColor: "#EAEC92" }} color="text.secondary" gutterBottom>
                  {feedbackcount?.count}
                </Typography>
                <Typography className='fs_10px custom-text' component="div">
                  Feedback Received
                </Typography>
              </CardContent>
            </Card>
          </Grid>


          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ maxWidth: 300, backgroundColor: "#C9F4D1", padding: 1 }}>
              <CardContent sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "12px" }}>
                <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width: "40px", height: "40px", backgroundColor: "#3CAB4F" }} color="text.secondary" gutterBottom>
                  {feedbackcount?.positiveFeedbackCount}
                </Typography>
                <Typography className='fs_10px custom-text' variant="h7" component="div">
                  Positive Feedback
                </Typography>
              </CardContent>
            </Card>
          </Grid>


          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ maxWidth: 300, backgroundColor: "#C9F4D1", padding: 1 }}>
              <CardContent sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "12px" }}>
                <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm white_space rounded' sx={{ width: "50px", height: "50px", backgroundColor: "#3CAB4F", fontSize: "11px" }} color="text.secondary" gutterBottom>
                  {feedbackcount?.positiveFeedbackCountPercentage} %
                </Typography>
                <Typography className='fs_10px custom-text' variant="h7" component="div">
                  Percentage Positive
                </Typography>
              </CardContent>
            </Card>
          </Grid>



          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ maxWidth: 300, backgroundColor: "#FEEBB4", padding: 1 }}>
              <CardContent sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "12px" }}>
                <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width: "40px", height: "40px", backgroundColor: "#fbbc05" }} color="text.secondary" gutterBottom>
                  {feedbackcount?.pendingFeedbackCount}
                </Typography>
                <Typography className='fs_10px custom-text' variant="h7" component="div">
                  Feedback Pending
                </Typography>
              </CardContent>
            </Card>
          </Grid>


          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ maxWidth: 300, backgroundColor: "#C9F4D1", padding: 1 }}>
              <CardContent sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "12px" }}>
                <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width: "40px", height: "40px", backgroundColor: "#3CAB4F" }} color="text.secondary" gutterBottom>
                  {feedbackcount?.completedFeedbackCount}
                </Typography>
                <Typography className='fs_10px custom-text' variant="h7" component="div">
                  Feedback Completed
                </Typography>
              </CardContent>
            </Card>
          </Grid>


          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ maxWidth: 300, backgroundColor: "#ffb3b3", padding: 1 }}>
              <CardContent sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "12px" }}>
                <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width: "40px", height: "40px", backgroundColor: "#ef5959" }} color="text.secondary" gutterBottom>
                  {feedbackcount?.overdueFeedbackCount}
                </Typography>
                <Typography className='fs_10px custom-text' variant="h7" component="div">
                  Feedback Overdue
                </Typography>
              </CardContent>
            </Card>
          </Grid>


        </Grid>
      </Box>

      <div className="row my-5">
        <div className="col-12 d-flex justify-content-end">
          <div class="dropdown">
            <button class="btn customBtnColor dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              Submit Feedback
            </button>
            <ul class="dropdown-menu">
              <li><Link className='dropdown-item' to="/add-positive-Resident-feedback">Add Positive Feedback</Link>
              </li>
              <li><Link className='dropdown-item' to="/add-negative-Resident-feedback">Add Negative Feedback</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Box sx={{ my: 5 }}>

        {!loading ? <TableMui
          styleTableThead={{
            '& th': {
              color: "white",
              backgroundColor: "#4dbdb7",
              fontWeight: "700",
              whiteSpace: "nowrap",
            },
          }}
          // th={{
          //   "sr1": "Ref Number",
          //   // "location.loc_no": "Ref Number",
          //   dateOfRequest: "Date Submitted",
          //   "type": "Positive/Negative",
          //   "manager.name": "Name",
          //   "additionalFields.feedback": "Description of Feedback",
          //   Status: "Status",
          // }}
          // td={data}

          th={{
            "serial": "Ref Number",
            dateOfRequest: "Date Submitted",
            dateOfVisit: "Date of Visit",
            "type": "Positive / Negative",
            // "first_name": "Name",
            // "additionalFields.feedback": "Description of Feedback",

            description: 'Description of Feedback',


            "fullName": "Name",
            Status: "Status",
          ...(userType !== 'MN' && { Delete: 'Delete' }) 


            // "manager.name": "Name",

          }}
          td={data}
          // td={data.map(item => ({
          //   ...item,
          //   fullName: `${item.first_name} ${item.last_name}`, // Concatenate firstName and lastName
          // }))}

          customFields={[
            {
              name: "Delete",
              data: (value, item) => (
                <Box className="" style={{ width: "100px" }}>


                  <StyledIconButton className='' onClick={() => {
                    // console.log('assasa')
                    handleDelete(item._id)

                  }}>
                    <FaTrash />
                  </StyledIconButton>



                </Box>

              ),
            },
            {
              name: "dateOfVisit",
              data: (value, item) => (
                <>

                  <span style={{ fontSize: 14 }}>

                    {item.dateOfVisit}

                  </span>
                  {/* {
  
                } */}
                </>


              )
            },
            {
              name: "serial",
              data: (value, item) => (
                <>
                 <Link style={{ textDecoration: "none" }} to={`/Resident-details/${item._id}`} state={'All'}>

                    <span style={{ fontSize: 14, color: '#0D6EFD' }}>

                    {   item.serialName}


                    </span>
                  </Link>
                  {/* {
  
                } */}
                </>


              )
            },
            {
              name: "description",
              data: (value, item) => (
                // "additionalFields.feedback": "Description of Feedback",
                <>
                  <TextDisplay
                  text={item.additionalFields?.feedback}
                      // text={`
                      //   ${item.additionalFields?.feedback.slice(0, 50)}
                      // ${item.additionalFields?.feedback.length > 50 ? "..." : ""}`}
                  />
                  {/* 
   <span style={{fontSize:14}}>
    {}
      </span> */}


                </>
              )
            },
            {
              name: "Status",
              data: (value, item) => (
                <Box className="" style={{ width: "100px" }}>
                  {console.log(item.status, "stst")}
                 <Link style={{ textDecoration: "none" }} to={`/Resident-details/${item._id}`} state={'All'}>

                    <Button
                      variant="contained"
                      // className='btn-pending'
                      style={{
                        // backgroundColor: "#FEBE1A",
                        textDecoration: "none",
                        position: "relative",
                        display: "inline-block",
                        color: "#fff",
                        padding: "8px",
                        // width: "100%",
                        // backgroundColor:
                        background:
                          item.status === "pending" ? "linear-gradient(to bottom, #fed56d, #FEB804)" :
                            item.status === "overdue" ? "linear-gradient(to bottom, #ff7272, #FF5252)" :
                              item.status === "completed" ? "linear-gradient(to bottom, #75b45e, #2FAF01)" :
                                "",
                        minWidth: "120px"

                      }}
                    >
                      <span
                        // className='btn-inner-pending'
                        style={{
                          display: "block",
                          color: "#fff",
                          width: "100%",
                          paddingTop: "6px",
                          paddingBottom: "6px",
                          background:
                            item.status === "pending" ? "linear-gradient(to top, #fed56d, #FEB804)" :
                              item.status === "overdue" ? "linear-gradient(to top, #ff7272, #FF5252)" :
                                item.status === "completed" ? "linear-gradient(to top, #75b45e, #2FAF01)" :
                                  ""
                        }}

                      >
                        {item.status}
                      </span>
                    </Button>
                  </Link>
                  {/* {item.Types === "type2" ? (
              ) : item.Types === "type3" ? (
                // Conditional Rendering: Pending Button
                <Button
                  variant="contained"
                  className='btn-pending'
                  // style={{ backgroundColor: "#FEB804", textDecoration: "none" }}
                  // onClick={() => {
                    // }}
                  >
                    <span className='btn-inner-pending'>Pending</span>
                  </Button>
                ) : (
                  <Button
                  variant="contained"
                  className='btn-complete'
                >
                  <span className='btn-inner-complete'>Complete</span>
                </Button>
                    )
  
                    } */}

                </Box>

              ),
            },
            // {
            //   name: "serial",
            //   data: (value, item) => (
            //     <Box className="d-flex gap-3 " style={{ width: "100px" }}>
            //       <Link style={{ textDecoration: "none" }} to={`/Resident-details/${item._id}`} state={'Resident'}>
            //         {/* {item.location.loc_no} */}
            //         {   item.serialName}
            //         </Link>


            //     </Box>

            //   ),
            // }
          ]}

        /> : <div className="d-flex justify-content-center align-items-center"><CircularProgress /></div>}


      </Box>

      <div className=" d-flex justify-content-end my-3">
        <Paginate count={count} limit={limit} page={page} onChange={handlePageChange} />
      </div>
    </div>
  )
}

export default Resident