import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, NavLink, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { buttonStyle, listItemStyle } from "./SideNavStyles";
import { logout } from "../redux/authSlice";
import { TfiWorld } from "react-icons/tfi";
import { HiOutlineUsers } from "react-icons/hi";
import { AiOutlineUser } from "react-icons/ai";
import { HiUserGroup } from "react-icons/hi";
import { FaStethoscope } from "react-icons/fa";
import { IoMdCard } from "react-icons/io";
import { FaUserNurse } from "react-icons/fa";
import { GoHome } from "react-icons/go";
import { FaUserGear } from "react-icons/fa6";
import { IoLocationSharp } from "react-icons/io5";

const drawerWidth = 210;
const listItemDataInitial = [
  {
    label: "Dashboard",
    link: `/dashboard`,
    icon: <HiOutlineUsers style={{ fontSize: "20px" }} />
  },
  {
    label: "All",
    link: "/all-feedback",
    icon: <TfiWorld style={{fontSize:"20px"}}/>
  },
  {
    label: "Relative",
    link: "/relative-feedback",
    icon: <HiUserGroup style={{ fontSize: "20px" }} />
  },
  {
    label: "Resident",
    link: "/resident-feedback",
    icon: <AiOutlineUser style={{ fontSize: "20px" }} />
  },
  {
    label: "HCP",
    link: "/hcp-feedback",
    icon: <FaStethoscope style={{ fontSize: "20px" }} />
  },
  {
    label: "Visitor",
    link: "/visitor-feedback",
    icon: <IoMdCard style={{ fontSize: "20px" }} />
  },
  {
    label: "Staff",
    link: "/staff-feedback",
    icon: <FaUserNurse style={{ fontSize: "20px" }} />
  },
  {
    label: "Showround",
    link: "/showround-feedback",
    icon: <GoHome style={{ fontSize: "20px" }} />
  },
  // {
  //   label: "Manager",
  //   link: "/manager",
  //   icon: <FaUserGear style={{fontSize:"20px"}}/>
  // },
  // {
  //   label: "Location",
  //   link: "/location",
  //   icon: <IoLocationSharp style={{fontSize:"20px"}}/>
  // },
  {
    label: "Request Meeting",
    link: "/request-meeting",
    icon: <HiOutlineUsers style={{ fontSize: "20px" }} />
  },
  // {
  //   label: "Req care Metting",
  //   link: "/request-care-metting",
  //   icon: <HiOutlineUsers style={{fontSize:"20px"}}/>
  // },
  // {
  //   label: "Req care Metting",
  //   link: "/request-caremetting",
  //   icon: <IoLocationSharp style={{fontSize:"20px"}}/>
  // },
];



function SideNav(props) {
  const userType = useSelector((state) => state.auth.userType);
  const [listItemData, setListItem] = useState([]);

  useEffect(() => {
    let itemsData = [...listItemDataInitial];
    if (userType === "AD") {
      itemsData = itemsData.concat([
        {
          label: "Location",
          link: "/location",
          icon: <IoLocationSharp style={{ fontSize: "20px" }} />
        },
        {
          label: "Manager",
          link: "/manager",
          icon: <FaUserGear style={{ fontSize: "20px" }} />
        }
      ]);
    }
    setListItem(itemsData);
  }, [userType]);

  const { window } = props;
  const container = window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <div className="sidebar-container" style={{ backgroundColor: "#00a098", height: "100vh", paddingTop: "20px", paddingLeft: "20px" }}>
      <div className="sidebar-content" style={{ backgroundColor: "#00a098", height: "100%", borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px", overflowY: "auto" }}>
        <Link to='/dashboard'>
          <div className="p-3 mx-auto" style={{ width: "9rem", cursor: "pointer" }}>
            <img width={"100%"} src={require("../assets/dashboradlogo.svg").default} alt="Dashboard Logo" />
          </div>
        </Link>
        <List>
          {listItemData.map((value, i) => (
            <div key={i} className="mx-4 rounded">
              <RenderItem value={value} i={i} />
            </div>
          ))}
        </List>
      </div>
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{
        width: { md: drawerWidth },
        flexShrink: { md: 0 },
        bgcolor: "black",
      }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={props.mobileOpen}
        onClose={props.handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            bgcolor: "#FFFFFF",
            color: "#AFB7BE",
            zIndex: { md: 1000, xs: 1200 },
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            bgcolor: "black",
            border: 0,
            color: "#AFB7BE",
            zIndex: { md: 1100, xs: 1200 },
            height: "100vh",
            overflowY: "auto", // Enable scroll if content overflows
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

SideNav.propTypes = {
  window: PropTypes.func,
};

export default SideNav;

const RenderItem = ({ value, i }) => (
      value.link ? <ListItem
      key={i}
      component={NavLink}
      to={value?.link || ""}
      disablePadding
      sx={listItemStyle}
    >
      <ListItemButton
        className="list-item list_text "
        sx={{ ...buttonStyle }}
      >
        <ListItemIcon className="myIconClass" sx={{ color: "white", marginRight: -3 }}> {value.icon}</ListItemIcon>
        <ListItemText className="" primary={<Typography variant="body2" style={{ fontSize: 14, color: "white", paddingLeft: 0 }} title={value.label}>{value.label}</Typography>} />
      </ListItemButton>
    </ListItem> :

      <ListItemButton className="list-item list_text no_link_list_item "
        sx={{ ...buttonStyle, "&:hover": { backgroundColor: "" } }}
      >
        <ListItemIcon className="myIconClass" sx={{ color: "white", }}>{value.icon}</ListItemIcon>
        <ListItemText className="" primary={<Typography variant="body2" style={{ fontSize: 14, color: "red", fontWeight: "bold" }} title={value.label}>{value.label}</Typography>} />
      </ListItemButton>
);
