import { Avatar, Box, Button } from '@mui/material'
import React, { useState } from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link, useParams } from 'react-router-dom';
import html2pdf from 'html2pdf.js';
import { MarkModal } from '../components/MarkModal';
import logo from '../assets/logo.png'


const Feedbackdetails = () => {
    const {id} = useParams();

    console.log(id)

     const [modalshow, setmodalshow] = useState(false);
     const [hide, sethide] = useState("d-none");
     const [show, setshow] = useState("d-block");
 
    const downloadPdf = async() => {
      await sethide("d-block");
      await setshow("d-none");
       
        const element = await document.getElementById('forDownload'); 
        const pdfOptions = {
          margin: 5,
          filename: 'page.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };
    
        html2pdf().from(element).set(pdfOptions).save();
        
        sethide("d-none");
        setshow("d-block");
      };
  return (
    <div  id='forDownload' className='bg-white pt-3'>
        <div className='d-sm-flex justify-content-between align-items-center'>
         <Link to={"/relative-feedback"}>
        <Button variant="contained" className= {`btn btn_bg m-3 ${show}`} startIcon={<ArrowBackIosIcon />}>
          Back
        </Button>
      </Link>
      <div className="d-flex align-items-center "><h4 className='m-3 text-center w-100'>Relative Feedback</h4>
       <img id="hidden-image" className={`${hide}`} src={logo} style={{width:"100px", height:"100px"}} alt="" />
      </div>
      <div className='me-lg-5 pe-lg-5 m-3'>
      <button className={`btn btn-dark me-lg-5 me-3 ${show}`}  onClick={downloadPdf}>Download</button>
      </div>
        </div>

        <Box className="container">

        <Grid  container spacing={2}>
                <Grid className='' item md={8}>
                    <Box sx={{my:3}}>
                <Typography variant="body1">
                        Date of visit: 
                    </Typography>

                    <Typography sx={{p:2}} className='border' variant="body1">
                        12 May 2023
                    </Typography>
                    </Box>


                    

                    <Box sx={{my:3}}>
                    <Typography variant="body1">
                    First Name
                    </Typography>

                    <Typography sx={{p:2}} className='border' variant="body1">
                    Marshmallow
                    </Typography>
                    </Box>


                    <Box sx={{my:3}}>
                    <Typography variant="body1">
                    Last Name
                    </Typography>

                    <Typography sx={{p:2}} className='border' variant="body1">
                    Parnell
                    </Typography>
                    </Box>


                    <Box sx={{my:3}}>
                    <Typography variant="body1">
                    Who are you visting
                    </Typography>

                    <Typography sx={{p:2}} className='border' variant="body1">
                        Grand Canyon, Arizona
                    </Typography>
                    </Box>



                    <Box sx={{my:3}}>
                    <Typography variant="body1">
                    Does your feedback relate to a specific member of staff?
                    </Typography>

                    <FormGroup>
                    <FormControlLabel control={<Checkbox defaultChecked />} label="Yes" />
                    <FormControlLabel control={<Checkbox  />} label="No" />
                    </FormGroup>

                    <Typography sx={{p:2}} className='border' variant="body1">
                        Elsa Beatrice
                    </Typography>
                    </Box>


                    <Box sx={{my:3}}>
                    <Typography variant="body1">
                    Please details your feedback
                    </Typography>

                    <Typography sx={{p:3}} className='border' variant="body1">
                        Lorem ipsum dolor sit amet.
                    </Typography>
                    </Box>



                    <Box sx={{my:3}}>
                    <Typography variant="body1">
                    Would you like us to response to your feedback?
                    </Typography>

                    <FormGroup>
                    <FormControlLabel control={<Checkbox defaultChecked />} label="Yes" />
                    <FormControlLabel control={<Checkbox  />} label="No" />
                    </FormGroup>

                    <Typography variant="body1">
                    Please enter your email/telephone number.
                    </Typography>

                    <Typography sx={{p:2}} className='border' variant="body1">
                    +975675544
                    </Typography>
                    </Box>

                </Grid>

                <Grid  item md={4}>
                <Paper sx={{p:3, my:6}} elevation={1} className="your-paper-class">
                <FormGroup>
                <FormControlLabel control={<Checkbox />} label="Mark as complete" onClick={() => setmodalshow(true)}/>
                </FormGroup>
                <Typography variant="body1">
                  Add comment
                    </Typography>


                    <Typography sx={{p:2}} className='border' variant="body1">
                        Type.....
                    </Typography>

                    <Box className="d-flex justify-content-end " sx={{my:3}}>
                    <button className='btn btn_bg'>
                        Save
                    </button>
                    </Box>

                    <Typography sx={{fontWeight:"bold"}} variant="body1">
                    Comment
                    </Typography>


                    <Box className="d-flex gap-1 justify-content-between ">
                    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />

                    <Box>
                    <Typography sx={{fontWeight:"bold"}} variant="body1">
                        Herry
                    </Typography>

                    <Typography sx={{fontSize:"12px"}} variant="body1">
                    Dr. Bego has also worked for multinational 
                    insurance companies in a global role leading 
                    </Typography>
                    </Box>

                    <Box className="d-flex gap-2">
                        <span className='fs_10px'>29 min</span>
                        <MoreVertIcon/>
                    </Box>

                    </Box>
                    <hr />
                    <Box className="d-flex gap-1 justify-content-between ">
                    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />

                    <Box>
                    <Typography sx={{fontWeight:"bold"}} variant="body1">
                        Herry
                    </Typography>

                    <Typography sx={{fontSize:"12px"}} variant="body1">
                    Dr. Bego has also worked for multinational 
                    insurance companies in a global role leading 
                    </Typography>
                    </Box>

                    <Box className="d-flex gap-2">
                        <span className='fs_10px'>29 min</span>
                        <MoreVertIcon/>
                    </Box>

                    </Box>
                    <hr />

                    <Box className="d-flex gap-1 justify-content-between ">
                    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />

                    <Box>
                    <Typography sx={{fontWeight:"bold"}} variant="body1">
                        Herry
                    </Typography>

                    <Typography sx={{fontSize:"12px"}} variant="body1">
                    Dr. Bego has also worked for multinational 
                    insurance companies in a global role leading 
                    </Typography>
                    </Box>

                    <Box className="d-flex gap-2">
                        <span className='fs_10px'>29 min</span>
                        <MoreVertIcon/>
                    </Box>

                    </Box>
                    <hr />
                </Paper>
                </Grid>

         </Grid>

      
    </Box>


 {
    modalshow && <MarkModal
    show={modalshow}
        onHide={() => setmodalshow(false)}
    />
 }
    </div>
  )
}

export default Feedbackdetails