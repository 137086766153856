const listItemStyle={
    color: "inherit",
    "&.active": { backgroundColor: "white", color: "red",borderRadius:"10px",
    "& .myIconClass, & .MuiTypography-root": {
      color: "black"
    } }
  }
  const buttonStyle={
  
  "&:hover": {
    color: "white",
    "& .myIconClass, & .MuiTypography-root": {
      color: ""
    }
  }
  }

  export {listItemStyle,buttonStyle}