import React, { useState } from 'react';
import { Modal, ModalFooter } from 'react-bootstrap';
import { toast } from 'react-toastify';
import apiClient from '../api/apiClient';

export const UpdatePasswordModal = (props) => {
  const [email, setEmail] = useState(props.data.email || '');
  const [name, setName] = useState(props.data.name || '');
  const [contact, setContact] = useState(props.data.contact|| '');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPasswordFields, setShowPasswordFields] = useState(true);

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      return toast.error("Please enter a valid email address");
    }

    if (showPasswordFields) {
      if (!currentPassword) {
        return toast.error("Current password cannot be empty");
      }

      if (newPassword !== confirmPassword) {
        return toast.error("New password and confirm password do not match");
      }
    }

    const data = {
      email,
      name,
      contact,
      passwordCurrent:currentPassword,
      password:newPassword,
    };

    try {
      console.log(data, "Profile Data");

      const result = await apiClient.patch('/auth/updateMyPassword', data);

      if (result.status !== 200) {
        return toast.error(result.data?.message || "Error updating profile");
      }

      toast.success(result.data?.message || "Profile updated successfully");
      props.onHide();

    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='rounded-3 mt-4'
    >
      <Modal.Body className='text-center rounded-3'>
        <form onSubmit={handleSubmit}>
          <label htmlFor="email" className='fw-bold mt-3 text-start w-100'>Email:</label>
          
          <input
            type="email"
            id="email"
            className='form-control'
            placeholder='Walter White'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

      
<label htmlFor="name" className='fw-bold mt-3 text-start w-100'>Name:</label>
          <input
            type="text"
            id="name"
            className='form-control'
            placeholder='Walter White'
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />


    
<label htmlFor="name" className='fw-bold mt-3 text-start w-100'>Contact:</label>
          <input
            type="number"
            id="number"
            className='form-control'
            placeholder='Walter White'
            value={contact}
            onChange={(e) => setContact(e.target.value)}
            required
          />


       
              <label htmlFor="current-password" className='fw-bold mt-3 text-start w-100'>Current Password:</label>
              <input
                type="password"
                id="current-password"
                className='form-control'
                placeholder='Current Password'
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
              />

              <label htmlFor="new-password" className='fw-bold mt-3 text-start w-100'>New Password:</label>
              <input
                type="password"
                id="new-password"
                className='form-control'
                placeholder='New Password'
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />

              <label htmlFor="confirm-password" className='fw-bold mt-3 text-start w-100'>Confirm New Password:</label>
              <input
                type="password"
                id="confirm-password"
                className='form-control'
                placeholder='Confirm New Password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
        
          <ModalFooter>
            <button type="submit" className='btn btn-success'>Save</button>
          </ModalFooter>
        </form>
      </Modal.Body>
    </Modal>
  );
};
