import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function NegativeReqMeeting() {

    const navigate = useNavigate();

    const handelBack = () => {
        navigate(-1);
    }

    function handleData(e) {
        e.preventDefault();
    }

    const currentDate = new Date().toISOString().split('T')[0];
    return (
        <div className='container'>

            <form onSubmit={handleData} className='py-4'>
                <Link onClick={handelBack} to='/req-metting' className='btn btn_bg'><ArrowBackIosIcon />Back</Link>

                <h3 className='h1Color text-center'>Please complete this form to submit your feedback</h3>

                <div className="row justify-content-center">

                    <div className="col-lg-7 col-12 mb-3">
                        <label for="calendar" class="form-label fw-bold">Date of Submit:</label>
                        <input type="date" class="form-control pointer" id="calendar" defaultValue={currentDate} />
                    </div>
                    <div className="col-lg-7 col-12 mb-3">
                        <label for="fname" class="form-label fw-bold">First Name:</label>
                        <input type="name" class="form-control" id="fname" placeholder="Please enter your first name" />
                    </div>
                    <div className="col-lg-7 col-12 mb-3">
                        <label for="lname" class="form-label fw-bold">Last Name:</label>
                        <input type='name' class="form-control" id="lname" placeholder="Please enter your last name" />
                    </div>

                    <div className="col-lg-7 col-12 mb-3">
                        <label for="couldFeedback" class="form-label fw-bold">Which Service User would you like a meeting about?</label>
                        <input type='text' class="form-control" id="couldFeedback" placeholder="Please Type" />
                    </div>
                    <div className="col-lg-7 col-12 mb-3">
                        <label for="comments" class="form-label fw-bold">Who would you like a meeting with?</label>
                        <input type='text' className="form-control" id='comments' placeholder='Please type' />
                    </div>
                    <div className="col-lg-7 col-12 mb-3">
                        <label for="pom" class="form-label fw-bold">Purpose of Meeting</label>
                        <input type='text' className="form-control" id='pom' placeholder='Please type' />
                    </div>
                    <div className="col-lg-7 col-12 mb-3">
                        <label for="whatMeeting" class="form-label fw-bold">What sort of meeting would you like?</label>
                        <div>
                            <select class="form-select" id='whatMeeting' aria-label="Default select example">
                                <option selected disabled>Please Select</option>
                                <option value="1">Meeting One</option>
                                <option value="2">Meeting Two</option>
                                <option value="3">Meeting Three</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-lg-7 col-12 mb-3">
                        <label for="method" class="form-label fw-bold">Preferred method of contact to arrange meeting</label>
                        <div>
                            <select class="form-select" id='method' aria-label="Default select example">
                                <option selected disabled>Please Select</option>
                                <option value="1">Online Meeting</option>
                                <option value="2">Physical Meeting</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-lg-7 col-12 mb-3">
                        <label for="contact" class="form-label fw-bold">Contact information:</label>
                        <input type='tel' className="form-control" id='contact' placeholder='Please enter your phone number' />
                    </div>
                    <div className="col-lg-7 col-12">
                        <input type="submit" value="Submit" className='my-3 text-white customBtn' />
                    </div>
                </div>

            </form>
        </div>
    )
}

export default  NegativeReqMeeting
