import { Box, CircularProgress, Grid ,useMediaQuery,
  useTheme,
 } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import {  useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import apiClient from '../api/apiClient';
import TextDisplay from '../components/TextDisplay';
import { formatDate } from '../modules/helpers';
import Paginate from '../mui/Paginate';
import TableMui from '../mui/TableMui';
import { FiSearch } from 'react-icons/fi';
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.error.main,
  },
}));

const limit = 10;
const All = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [name, setSearchName] = useState('');
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(false);

  const filter = useSelector((state) => state.filter?.filter);
  const {userType} = useSelector((state)=> state.auth )
  // console.log('usertype gkhj' , userType)

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    setPage(1);
  };
  const resetFilters = () => {
    setSearchName('');
    setSelectedStatus('');
    setStartDate('');
    setEndDate('');
    setPage(1);
    setData([])
    fetchdata(true);
  };


  const handleDelete = async (id) => {
    try {
      // Make the delete request
      const response = await apiClient.delete(`/feedback/${id}`);

      // Check if the response was successful
      if (response.status === 204) {
        toast.success("Feedback deleted successfully!");
      } else {
        // Handle the case where the deletion was not successful
        toast.error(response.data?.message || "Failed to delete feedback.");
      }
    } catch (error) {
      // Handle any errors that occurred during the request
      toast.error(`Error: ${error.message || "An error occurred while deleting feedback."}`);
    }
    fetchdata()
    //    setdata((pre)=>
    // pre.map((feedback)=>feedback._id.toString() !== id)  
    //   )
  };
  

  const fetchdata = async (reset = false) => {

    try {
      setloading(true);
      
        const result = await apiClient.get(`/feedback?first_name=${reset ? '' : name}&location=${filter || ""}&status=${reset ? '' : selectedStatus}&startDate=${ reset ? '' : startDate}&endDate=${reset ? '' : endDate}&page=${page}&limit=${limit}`);

      // Check if result.data is not null and contains the expected properties
      if (result?.data?.feedback) {
        const formattedData = result.data.feedback.map((item) => ({
          ...item,
          fullName: `${item.first_name} ${item.last_name}`,
          dateOfRequest: formatDate(item.dateOfRequest),
          dateOfVisit: formatDate(item.dateOfVisit),
        }));
        setCount(result.data.count);
        setData(formattedData);
      } else {
        console.error("Unexpected response structure:", result);
      }
     
    } catch (error) {
      console.error("Error fetching data:", error);
      // Optionally, you can set an error state here to display an error message in the UI
      // setError(true); // Assuming you have an error state to handle UI updates
    } finally {
      setloading(false);
    }
  };


  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    fetchdata();
  }, [filter, page]);




  return (
    <div>
      {/* <Grid container spacing={3} className='my-3 p-4'>

        <Grid item xs={12} md={4}>
          <div className='position-relative mt-4'>
            <input type="search"
              value={name}
              onChange={(e) => setSearchName(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && fetchdata(true)}

              className='form-control' placeholder='search' />
          </div>
        </Grid>


        <Grid item xs={12} md={4}>
          <div className='pt-4'>
            <select name="" className='form-select' id="" onChange={handleStatusChange}>
              <option value="" selected disabled>Select Feedback</option>
              <option value="">All</option>
              <option value="completed">Feedback completed</option>
              <option value="pending">Feedback pending</option>
              <option value="overdue">Feedback overdue</option>
             
            </select>
          </div>

        </Grid>

        <Grid className='' item xs={12} md={4}>
          <label htmlFor=""> Date Range:</label>
          <div className="d-flex mb-2">
            <input type="date" className='form-control' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            <span className='p-2'>to</span>
            <input type="date" className='form-control' value={endDate} onChange={(e) => setEndDate(e.target.value)} />
            <button className='btn btn-secondary ms-2' onClick={() => fetchdata(true)}>Search</button>
          </div>
        </Grid>



      </Grid> */}
      <Grid container spacing={2} className="my-3    px-md-5 ">
       
        <Grid item xs={12} md={12}>
          <Box className=" row   align-items-center  justify-content-between ">

           <Box  className='col-12 col-md-7 '>
           <label htmlFor="date-range">Date Range:</label>
            <Box className="d-flex mb-2  flex-md-row flex-column">
              <input type="date" className="form-control" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
              <span className="p-2">to</span>
              <input type="date" className="form-control" value={endDate} min={startDate} onChange={(e) => setEndDate(e.target.value)} />
            </Box>
            </Box> 



            <Box  className="pt-3  col-12 col-md-3 d-flex  
          align-items-center 
        justify-content-between gap-2  mx-2">

          <Button 
          style={{backgroundColor:"#00A098" , border:"none" }}
          
          variant="primary" className="" onClick={() => fetchdata()}>Search</Button>
          <Button 
          style={{ backgroundColor: "gray", border:"none"}}
          
          onClick={resetFilters}>Reset</Button>
        </Box>


          </Box>
         
        </Grid>

        

        <Grid item xs={12} md={6}>
          <Box className="position-relative ">
            <input
              type="search"
            
              value={name}
              onChange={(e) => setSearchName(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && fetchdata()}
              className="form-control"
              placeholder="Search"
            />
            {/* <FiSearch onClick={() => fetchdata(true)} className="fs-4 position-absolute translate-middle" style={{ top: "50%", right: "15px", cursor: "pointer" }} /> */}
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box className="">
            <select className="form-select" onChange={handleStatusChange} value={selectedStatus}>
              <option value="" disabled>Select Feedback</option>
              <option value="">All</option>
              <option value="completed">Feedback completed</option>
              <option value="pending">Feedback pending</option>
              <option value="overdue">Feedback overdue</option>
            </select>
          </Box>
        </Grid>


      </Grid>
      


      {!loading ? <TableMui

        styleTableThead={{
          '& th': {
            color: "white",
            backgroundColor: "#4dbdb7",
            fontWeight: "700",
            whiteSpace: "pre-wrap",

            // border: "2px solid gray",
          },

        }}
        th={{
          "serial" : "Ref Number",
          typeOfFeedback: "Type Of Feedback",
          dateOfRequest: "Date Submitted",
          dateOfVisit: "Date of Visit",
          "type": "Positive / Negative",
          description: 'Description of Feedback',
          // "first_name": "Name",
          // "additionalFields.feedback": "Description of Feedback",
         fullName: "Name", 
          Status: "Status",
          ...(userType !== 'MN' && { Delete: 'Delete' }) 
          // "manager.name": "Name",

        }}
       
        td={data}



        cellstyle={{
        
            // padding: isSmallScreen ? '8px' : '16px',
            // fontSize: isSmallScreen ? '0.75rem' : '1rem',
            // textOverflow: 'ellipsis',
            // whiteSpace: 'nowrap',
            // overflow: 'hidden',
            // maxWidth: isSmallScreen ? '80px' : '150px',
    
        }}
        customFields={[
          {
            name: "Delete",
            data: (value, item) => (
              <Box className="" style={{ width: "100px" }}>


                <StyledIconButton className='' onClick={() => {
                  // console.log('assasa')
                  handleDelete(item._id)

                }}>
              <FaTrash />
            </StyledIconButton>

            

              </Box>

            ),
          },
         
          {
            name: "serial",
            data: (value, item) => (
              <>
                <Link style={{ textDecoration: "none" }} to={`/${item.feedback}-details/${item._id}`} state={'All'}>
                <span style={{fontSize:14,color:'#0D6EFD'}}>
                
                {   item.serialName}
                
                </span>
                </Link>
              </>


            )
          },
          {
            name: "typeOfFeedback",
            data: (value, item) => (
              <>
                <span style={{ fontSize: 14 }}>
                  {item.feedback}
                  {/* {item.} */}

                </span>
              </>


            )
          },
          {
            name: "description",
            data: (value, item) => (
              // "additionalFields.feedback": "Description of Feedback",
              <>
                <TextDisplay
                  // text={item.additionalFields.feedback}
                  text={item.additionalFields?.feedback
                  }
                />
                {/* 
 <span style={{fontSize:14}}>
  {}
    </span> */}


              </>
            )
          },
          // {
          //   name: "sr1",
          //   data: (value, item) => (
          //     <>
          //       <Link style={{ textDecoration: "none" }} to={`/fbstaff-details/${item._id}`} state={'All'}>
          //         <span style={{ fontSize: 14, color: '#0D6EFD' }}>

          //           {item.feedback.charAt(0).toUpperCase() + item.feedback.slice(1, 3) + value}

          //         </span>
          //       </Link>


          //     </>


          //   )
          // },




          {
            name: "Status",
            data: (value, item) => (
              <Box className="" style={{ width: "100px" }}>
                {/* {console.log(item.status, "stst")} */}
                <Link style={{ textDecoration: "none" }}  to={`/${item.feedback}-details/${item._id}`} state={'All'}>

                  <Button
                    variant="contained"
                    // className='btn-pending'
                    style={{
                      // backgroundColor: "#FEBE1A",
                      textDecoration: "none",
                      position: "relative",
                      display: "inline-block",
                      color: "#fff",
                      padding: "8px",
                      // width: "100%",
                      // backgroundColor:
                      background:
                        item.status === "pending" ? "linear-gradient(to bottom, #fed56d, #FEB804)" :
                          item.status === "overdue" ? "linear-gradient(to bottom, #ff7272, #FF5252)" :
                            item.status === "completed" ? "linear-gradient(to bottom, #75b45e, #2FAF01)" :
                              "",
                      minWidth: "120px"

                    }}
                  >
                    <span
                      // className='btn-inner-pending'
                      style={{
                        display: "block",
                        color: "#fff",
                        width: "100%",
                        paddingTop: "6px",
                        paddingBottom: "6px",
                        background:
                          item.status === "pending" ? "linear-gradient(to top, #fed56d, #FEB804)" :
                            item.status === "overdue" ? "linear-gradient(to top, #ff7272, #FF5252)" :
                              item.status === "completed" ? "linear-gradient(to top, #75b45e, #2FAF01)" :
                                ""
                      }}

                    >
                      {item.status}
                    </span>
                  </Button>
                </Link>

              </Box>

            ),
          },
          // {
          //   name: "sr1",
          //   data: (value, item) => (
          //     <Box className="d-flex gap-3 " style={{ width: "100px" }}>



          //     </Box>

          //   ),
          // }

        ]
        }

      // sr={[
      //   {
      //     name: "sr",
      //     data: (value, item) => (
      //       <Box className="" style={{width:"100px"}}>
      //       {console.log(item,value, "stst1")}
      //             <Button
      //             variant="contained"
      //             // className='btn-pending'
      //             style={{ 
      //               // backgroundColor: "#FEBE1A",
      //              textDecoration: "none", 
      //              position: "relative",
      //             display: "inline-block",
      //             color: "#fff",
      //             padding: "8px",
      //             // width: "100%",
      //             // backgroundColor:
      //             background:
      //             item.status === "pending" ? "linear-gradient(to bottom, #fed56d, #FEB804)" :
      //             item.status === "overdue" ? "linear-gradient(to bottom, #ff7272, #FF5252)" :
      //             item.status === "completed" ? "linear-gradient(to bottom, #75b45e, #2FAF01)" :
      //             "",
      //             minWidth: "120px"

      //            }}
      //           >
      //             <span 
      //             // className='btn-inner-pending'
      //             style={{
      //               display: "block",
      //               color: "#fff",
      //               width: "100%",
      //               paddingTop: "6px",
      //               paddingBottom: "6px",
      //               background: 
      //               item.status === "pending" ? "linear-gradient(to top, #fed56d, #FEB804)":
      //               item.status === "overdue" ? "linear-gradient(to top, #ff7272, #FF5252)":
      //               item.status === "completed" ? "linear-gradient(to top, #75b45e, #2FAF01)":
      //               ""
      //             }}

      //             >
      //               {item.status}
      //               </span>
      //           </Button>
      //         </Box>

      //     ),
      //   },
      // ]}

      /> : <div className="d-flex justify-content-center align-items-center"><CircularProgress /></div>}

      <div className=" d-flex justify-content-end my-3">
        <Paginate count={count} limit={limit} page={page} onChange={handlePageChange} />
      </div>


    </div>
  )
}

export default All