import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import apiClient from '../../api/apiClient';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';

export default function NegativeRelativeForm() {
    const selectlocation= useSelector((state) => state.filter.filter);
    console.log(selectlocation, "ll");

    const initialState = {
        type: "negative",
        feedback:"relative",
        dateOfRequest: new Date(),
        dateOfVisit:"",
        first_name: "",
        last_name: "",
        staf_name: "",
        additionalFields: {
            feedback: "",
            contact: "",
            who_visit: "",
            how_improve: "",
        }
    };

    const [user, setUser] = useState(initialState);
    const [selectedButton, setSelectedButton] = useState('No');
    const [selectedButton1, setSelectedButton1] = useState('No');
    const [locData, setLoacdata] = useState()
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const fetchdata = async (e) => {
        try {
            setLoading(true);
            const result = await apiClient.get(`/location`);
            console.log(result, "result getted");
            // Extract location IDs from the response data
            const locationIds = result.data.location[0]._id;
    
            // Now you have an array of location IDs
            console.log(locationIds, "aaaa neg");
    
            // Set the location IDs using setLoacdata
            setLoacdata(locationIds); 
            setLoading(false)
        } catch (error) {
            console.error('Error fetching location data:', error);
        }
    }

    useEffect(() => {
        fetchdata()
    }, []);

    const validateForm = () => {
        if (!user.dateOfVisit) {
            toast.error("Date of Visit is required");
            return false;
        }
        if (!user.first_name.trim()) {
            toast.error("First Name is required");
            return false;
        }
        if (!user.last_name.trim()) {
            toast.error("Last Name is required");
            return false;
        }
        if (selectedButton === 'Yes' && !user.staf_name.trim()) {
            toast.error("Staff Name is required if your feedback relates to a specific member of staff");
            return false;
        }
        if (!user.additionalFields.feedback.trim()) {
            toast.error("Feedback is required");
            return false;
        }
        if (selectedButton1 === 'Yes' && !user.additionalFields.contact.trim()) {
            toast.error("Contact information is required if you want us to respond to your feedback");
            return false;
        }


        console.log("location data for select location",locData);
        if(!locData){
            toast.error("please select location");
            return false;
        }
        return true;
    };





    const handleData = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        apiClient.setHeader("location" , locData)
        const result = await apiClient.post('/feedback', user, {headers:{location: `${selectlocation}`}});
        console.log(result, "resultt")
        console.log(user.loc_no, "userddddddddddddd");
        navigate("/relative-feedback")
    }

    

    const handelBack = () => {
        navigate(-1);
    }

    // const handleInputs = (e) => {
    //     const { name, value } = e.target;
    //     setUser(prevState => ({
    //         ...prevState,
    //         [name]: value,
    //     }));
    // };

    const handleInput = (key, value) => {
        const keys = key.split(':');
        if (keys.length === 1) {
            setUser(prevFormValues => ({
                ...prevFormValues,
                [key]: value
            }));
        } else {
            // this will handle nested objects like detail.recipient_email
            setUser(prevFormValues => ({
                ...prevFormValues,
                [keys[0]]: {
                    ...prevFormValues[keys[0]],
                    [keys[1]]: value
                }
            }));
        }
    };

    const currentDate = new Date().toISOString().split('T')[0];

    return (
        <div className='container'>
            {!loading?<form onSubmit={handleData} className='py-4'>
                <Link onClick={handelBack} className='btn btn_bg'><ArrowBackIosIcon />Back</Link>
                <h3 className='h1Color text-center'>Please complete this form to submit your feedback</h3>
                <div className="row justify-content-center">
                    {/* <div className="col-lg-7 col-12 mb-3">
                        <label for="calendar" className="form-label fw-bold">Date of Submit:</label>
                        <input
                            type="date"
                            name='dateOfRequest'
                            className="form-control pointer"
                            id="calendar"
                            defaultValue={user.dateOfRequest}
                            value={user.dateOfRequest}
                            // onChange={handleInput()}
                            onChange={(e) => handleInput(e.target.name, e.target.value)}
                        />
                    </div> */}
                    <div className="col-lg-7 col-12 mb-3">
                        <label for="calendar" className="form-label fw-bold">Date of Visit:</label>
                        <input
                            type="date"
                            name='dateOfVisit'
                            className="form-control pointer"
                            id="calendar"
                            defaultValue={''}
                            value={user.dateOfVisit}
                            // onChange={handleInput()}
                            onChange={(e) => handleInput("dateOfVisit", e.target.value)}
                        />
                    </div>
                    <div className="col-lg-7 col-12 mb-3">
                        <label for="fname" className="form-label fw-bold">First Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            name='first_name'
                            id="fname"
                            placeholder="Please enter your first name"
                            value={user.first_name}
                            // onChange={handleInput()}
                            onChange={(e) => handleInput(e.target.name, e.target.value)}
                        />
                    </div>
                    <div className="col-lg-7 col-12 mb-3">
                        <label for="lname" className="form-label fw-bold">Last Name:</label>
                        <input
                            type="text"
                            className="form-control"
                            name='last_name'
                            id="lname"
                            placeholder="Please enter your Last name"
                            value={user.last_name}
                            // onChange={handleInput()}
                            onChange={(e) => handleInput(e.target.name, e.target.value)}
                        />
                    </div>
                   
                    <div className="col-lg-7 col-12 mb-3">
                        <label for="fname" className="form-label fw-bold">Who are you visiting:</label>
                        <input
                            type="text"
                            className="form-control"
                            name='additionalFields:who_visit'
                            id="fname"
                            placeholder="Please enter who you are visiting"
                            value={user.additionalFields.who_visit}
                            // onChange={handleInput()}
                            onChange={(e) => handleInput("additionalFields:who_visit", e.target.value)}
                        />
                    </div>
                    <div className="col-lg-7 col-12 mb-3">
                        <label for="sFeedback" className="form-label fw-bold">Does your feedback relate to a specific member of staff?</label><br />
                        <input
                            type="checkbox"
                            id="sFeedback1"
                            className='checkbox-btn'
                            name="sFeedback"
                            checked={selectedButton === 'Yes'}
                            onClick={() => setSelectedButton('Yes')}
                        />
                        <label for="sFeedback1" className='me-2'>Yes</label>
                        <input
                            type="checkbox"
                            id="sFeedback2"
                            className='checkbox-btn'
                            name="sFeedback"
                            checked={selectedButton === 'No'}
                            onClick={() => setSelectedButton('No')}
                        />
                        <label for="sFeedback2">No</label>
                        {selectedButton === 'Yes' && (
                            <>
                                 <input
                            className="form-control mt-3"
                            rows="4"
                            name='staf_name'
                            placeholder='Please enter your staff name here'
                            value={user.staf_name}
                            // onChange={handleInput()}
                            onChange={(e) => handleInput("staf_name", e.target.value)}
                        ></input>
                            </>
                        )}
                    </div>
                    <div className="col-lg-7 col-12 mb-3">
                        <label for="Feedback" className="form-label fw-bold">Your Feedback:</label>
                        <textarea
                            className="form-control"
                            name='additionalFields:feedback'
                            id='Feedback'
                            rows="4"
                            placeholder='Please enter your feedback here'
                            value={user.additionalFields.feedback}
                            // onChange={handleInput()}
                            onChange={(e) => handleInput("additionalFields:feedback", e.target.value)}
                            ></textarea>
                    </div>
                    <div className="col-lg-7 col-12 mb-3">
                        <label for="fname" className="form-label fw-bold">How can we improve:</label>
                        <input
                            type="text"
                            className="form-control"
                            name='how_improve'
                            id="fname"
                            placeholder="Please enter improvement"
                            value={user.how_improve}
                            // onChange={handleInput()}
                            onChange={(e) => handleInput("additionalFields:how_improve", e.target.value)}
                        />
                    </div>

                    
                    <div className="col-lg-7 col-12">
                        <label for="sFeedback" className="form-label fw-bold">Would you like us to respond to your feedback?</label>
                        <div>
                            <input
                                type="checkbox"
                                id="rFeedback1"
                                name="rFeedback"
                                className='checkbox-btn'
                                checked={selectedButton1 === 'Yes'}
                                onClick={() => setSelectedButton1('Yes')}
                            />
                            <label for="rFeedback1" className='me-2'>Yes</label>
                            <input
                                type="checkbox"
                                id="rFeedback2"
                                name="rFeedback"
                                className='checkbox-btn'
                                checked={selectedButton1 === 'No'}
                                onClick={() => setSelectedButton1('No')}
                            />
                            <label for="rFeedback2">No</label>
                        </div>
                        {
                            selectedButton1 === 'Yes' && (
                                <>
                                    <input
                                        type="text"
                                        name="additionalFields:contact"
                                        id="email-phone"
                                        className="form-control mt-3"
                                        placeholder='Enter your email or phone number'
                                        value={user.additionalFields.contact}
                                        // onChange={handleInput()}
                                        onChange={(e) => handleInput(e.target.name, e.target.value)}
                                    />
                                </>
                            )}
                    </div>
                    <div className="col-lg-7 col-12">
                        <input type="submit" value="Submit" name='submitBtn' className='my-3 text-white customBtn' />
                    </div>
                </div>
            </form>:<div className="d-flex justify-content-center align-items-center"><CircularProgress/></div> }
        </div>
    )
}
