import { Button, Modal } from 'react-bootstrap';
import React from 'react'
import { FaCheckCircle } from "react-icons/fa";
export const EmailModal = (props) => {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='rounded-3'
    >
      
      <Modal.Body className='text-center rounded-3'>
      <FaCheckCircle  className="display-1" style={{color:"#029E96"}}/>
        <h5 className='mt-2'>Email Sent</h5>
        <p>Verification Email is sent to your email. Check your email to reset your password.</p>
      </Modal.Body>
      
    </Modal>
  );
}
  