import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import html2pdf from "html2pdf.js";
import { MarkModal } from "../components/MarkModal";
import logo from "../assets/logo.png";
import { Result } from "postcss";
import apiClient from "../api/apiClient";
import { formatDate } from "../modules/helpers";
import { toast } from "react-toastify";
import moment from "moment";
import { Modal } from "react-bootstrap";
import jsPDF from "jspdf";
import "jspdf-autotable";

const FbReqdetal = () => {
  const [data, setdata] = useState("");
  const [comment, setcomment] = useState("");
  const [getcomment, setgetcomment] = useState([]);
  const [isclicked, setIsclicked] = useState(false);
  const [isnavigate, setisnavigate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [feedbackStatus, setFeedbackStatus] = useState(false);
  const [expandedComments, setExpandedComments] = useState([]);

  const toggleShowMore = (index) => {
    if (expandedComments.includes(index)) {
      setExpandedComments(expandedComments.filter((i) => i !== index));
    } else {
      setExpandedComments([...expandedComments, index]);
    }
  };
  const { id } = useParams();
  console.log(id);
  console.log(id, "fbreq del");
  const navigate = useNavigate();
  const location = useLocation();
  const handelsubmit = async (e) => {
    e.preventDefault();
    let shouldNavigate = false;

    if (feedbackStatus === "completed" && isclicked) {
      const updateSuccessful = await updateFeedback();
      if (updateSuccessful) {
        shouldNavigate = true; // Set the flag to true if the update is successful
      } else {
        // Reset the state if the update fails
        setFeedbackStatus("");
        setIsclicked(false);
        return; // Exit if the status update fails
      }
    }
    if (comment !== "") {
      const result = await apiClient.post(`comment`, {
        comment,
        feedbackId: id,
      });
      if (result.status === 200) {
        toast.success("comment added");
      } else {
        toast.error("error");
      }
      setcomment("");

      fetchdata();
    }
    if (shouldNavigate) {
      navigate(`/request-meeting`);
    }
  };

  const updateFeedback = async () => {
    try {
      const result = await apiClient.put(`req-metting/${id}`, {
        status: "completed",
      });
      if (result.status === 200) {
        console.log("Feedback status updated successfully to completed");
        toast.success("Feedback status updated successfully");
        return true; // Return true if the update is successful
      } else {
        setFeedbackStatus("");
        setIsclicked(false);
        toast.error("Error updating feedback status");
        return false; // Return false if the update fails
      }
    } catch (error) {
      setFeedbackStatus("");
      setIsclicked(false);

      toast.error("Error updating feedback status");
      console.error("Error updating feedback status");
      return false; // Return false if the update fails
    }
  };

  const fetchdata = async () => {
    setLoading(true);
    const result = await apiClient.get(`req-metting/${id}`);
    setdata(result.data);
    setFeedbackStatus(result.data.singleMeetingReq?.status);
    setLoading(false);
    //   const commentdata = await apiClient.get("comment")
    // //   console.log(commentdata, "cmnt data")
    //   setgetcomment(commentdata.data.comment)
    //   console.log(commentdata.data.comment, "getcomment data")
    //   console.log(result, "req result")
  };

  useEffect(() => {
    fetchdata();
  }, []);

  console.log(data, "data req detail");

  const [modalshow, setmodalshow] = useState(false);
  const [hide, sethide] = useState("d-none");
  const [show, setshow] = useState("d-block");

  // const downloadPdf = async () => {
  //   await sethide("d-block");
  //   await setshow("d-none");

  //   const element = await document.getElementById("forDownload");
  //   const pdfOptions = {
  //     margin: 1,
  //     filename: "page.pdf",
  //     image: { type: "jpeg", quality: 0.98 },
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  //   };

  //   html2pdf().from(element).set(pdfOptions).save();

  //   sethide("d-none");
  //   setshow("d-block");
  // };

  const handleCheckboxChange = () => {
    if (feedbackStatus !== "completed") {
      setmodalshow(true);
    } else {
      setFeedbackStatus("");
    }
  };

  const downloadPdf = async () => {
    const doc = new jsPDF();

    doc.text("Request Meeting", 85, 22);
    doc.addImage(logo, "PNG", 170, 4, 30, 20); // Adjust the x, y, width, height as needed

    const feedbackData = [
      ["Ref Number:", data?.singleMeetingReq?.serialName],
      ["Date of submitted:", formatDate(data?.singleMeetingReq?.dateOfRequest)],
      ["Status:", data?.singleMeetingReq?.status],
      ["First Name:", data?.singleMeetingReq?.firstName],
      ["Last Name:", data?.singleMeetingReq?.lastName],
      ["Which resident / service user would you like a meeting about?:", data?.singleMeetingReq?.serviceUser],
      ["Who would you like a Meeting with?:", data?.singleMeetingReq?.meetingWith],
      ["Purpose of meeting:", data?.singleMeetingReq?.purposeOfMeeting],
      ["What sort of meeting would you like?:", data?.singleMeetingReq?.meetingType],
      ["Preferred method of contract to arrange meeting:", data?.singleMeetingReq?.preferredContactMethod],
      ["Contact Information:", data?.singleMeetingReq?.contactInformation],
      data?.singleMeetingReq?.markCompletedDate && [
        "Mark as Completed Date:",
        formatDate(data?.singleMeetingReq?.markCompletedDate) || "N/A",
      ],
    ].filter(Boolean);

    doc.autoTable({
      startY: 30,
      head: [["Field", "Value"]],
      body: feedbackData,

      headStyles: {
        fillColor: "#00a098", // Background color for header cells
        textColor: "#FFFFFF", // Text color inside the header cells (optional)
      },
    });

    if (data?.comment?.length) {
      const commentData = data.comment.map((item) => [
        item.user.name,
        item.comment, // Comment text
        moment(item.createdAt).format("DD-MM-YY"), // Date in DD-MM-YYYY format
        moment(item.createdAt).format("HH:mm"), // Time in HH:mm format
      ]);

      doc.text("Comments", 14, doc.autoTable.previous.finalY + 10);

      doc.autoTable({
        startY: doc.autoTable.previous.finalY + 15,
        head: [["User", "Comment", "Date", "Time"]],
        body: commentData,

        headStyles: {
          fillColor: "#00a098", // Background color for header cells
          textColor: "#FFFFFF", // Text color inside the header cells (optional)
        },
      });
    }

    doc.save(
      `${data?.singleMeetingReq?.serialName || 1}-Request-Metting-feedback.pdf`
    );
  };
  // console.log(moment(data.comment[0].createdAt).format("DD-MM-YYYY-HH:mm"))
  // {moment(item.createdAt).format("DD-MM-YYYY")}
  return (
    <div id="forDownload" className="  bg-white pt-3">
      <div className="d-sm-flex justify-content-between align-items-center w-100">
        <div>
          <Link
            style={{ textDecoration: "none" }}
            className=""
            to={"/request-meeting"}
          >
            <Button
              variant="contained"
              className={`d-flex btn btn_bg m-3  ${show}`}
            >
              <ArrowBackIosIcon /> Back
            </Button>
          </Link>
        </div>
        <div className="d-flex align-items-center justify-content-between w-100">
          <h4 className="m-3 text-center ">Request Meeting</h4>
          <img
            id="hidden-image"
            className={`${hide}`}
            src={logo}
            style={{ width: "100px", height: "100px" }}
            alt=""
          />
        </div>
        <div className="me-lg-5 pe-lg-5 m-3">
          <button
            style={{ backgroundColor: "#00A098" }}
            className={`btn text-white me-lg-5 me-3 ${show}`}
            onClick={downloadPdf}
          >
            Download
          </button>
        </div>
      </div>

      {loading ? (
        <div
          className="d-flex justify-content-center 
align-items-center  pb-5"
        >
          <CircularProgress />
        </div>
      ) : (
        <Box className="container">
          <Grid container spacing={2}>
            <Grid className="" item md={8}>
              <Box sx={{ my: 3 }}>
                <Typography variant="body1">First Name</Typography>

                <Typography sx={{ p: 2 }} className="border" variant="body1">
                  {data?.singleMeetingReq?.firstName}
                </Typography>
              </Box>

              <Box sx={{ my: 3 }}>
                <Typography variant="body1">Last Name</Typography>

                <Typography sx={{ p: 2 }} className="border" variant="body1">
                  {data?.singleMeetingReq?.lastName}
                </Typography>
              </Box>
              <Box sx={{ my: 3 }}>
                <Typography variant="body1">Which resident / service user would you like a meeting about?</Typography>

                <Typography sx={{ p: 2 }} className="border" variant="body1">
                  {data?.singleMeetingReq?.serviceUser}
                </Typography>
              </Box>
              <Box sx={{ my: 3 }}>
                <Typography variant="body1">Who would you like a Meeting with?</Typography>

                <Typography sx={{ p: 2 }} className="border" variant="body1">
                  {data?.singleMeetingReq?.meetingWith}
                </Typography>
              </Box>
              <Box sx={{ my: 3 }}>
                <Typography variant="body1">Purpose of Meeting</Typography>

                <Typography sx={{ p: 2 }} className="border" variant="body1">
                  {data?.singleMeetingReq?.purposeOfMeeting}
                </Typography>
              </Box>
              <Box sx={{ my: 3 }}>
                <Typography variant="body1">What sort of meeting would you like?</Typography>

                <Typography sx={{ p: 2 }} className="border" variant="body1">
                  {data?.singleMeetingReq?.meetingType}
                </Typography>
              </Box>


              <Box sx={{ my: 3 }}>
                <Typography variant="body1">Preferred method of contact to arrange meeting</Typography>

                <Typography sx={{ p: 2 }} className="border" variant="body1">
                  {data?.singleMeetingReq?.preferredContactMethod}
                </Typography>
              </Box>
              <Box sx={{ my: 3 }}>
                <Typography variant="body1">Contact Information</Typography>

                <Typography sx={{ p: 2 }} className="border" variant="body1">
                  {data?.singleMeetingReq?.contactInformation}
                </Typography>
              </Box>

              {data?.singleMeetingReq?.markCompletedDate && (
                <Box sx={{ my: 3, width: "100%" }}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", mb: 1 }}
                  >
                    Mark as Completed Date:
                  </Typography>
                  <Box
                    sx={{
                      p: 2,
                      width: "100%",
                      bgcolor: "#f5f5f5", // Light gray background to simulate a disabled input
                      border: "1px solid #ccc", // Border similar to a text field
                      borderRadius: "4px", // Rounded corners
                      color: "rgba(0, 0, 0, 0.6)", // Slightly muted text color
                    }}
                  >
                    <Typography variant="body1">
                      {formatDate(data?.singleMeetingReq?.markCompletedDate)}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Grid>

            <Grid item md={4}>
              <Paper sx={{ p: 3, my: 6 }} elevation={1} className={`${show}`}>
                <FormGroup className={`${show}`}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={feedbackStatus === "completed"}
                        disabled={
                          data?.singleMeetingReq?.markCompletedDate
                            ? true
                            : false
                        }
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="Mark as complete"
                    // onClick={() => setmodalshow(true)}
                  />
                </FormGroup>
                <Typography className={`${show}`} variant="body1">
                  Add comment
                </Typography>

                <form className={`${show}`} onSubmit={handelsubmit}>
                  <TextareaAutosize
                    minRows={1}
                    id="outlined-basic"
                    value={comment}
                    onChange={(e) => setcomment(e.target.value)}
                    variant="outlined"
                    placeholder="Type your comment"
                    style={{ width: "100%", padding: "10px", fontSize: "14px" }}
                  />

                  <Box className="d-flex justify-content-end " sx={{ my: 3 }}>
                    <button
                      type="submit"
                      className={`btn btn_bg ${
                        feedbackStatus !== "completed" && comment === ""
                          ? "disable"
                          : ""
                      }`}
                      disabled={
                        comment === "" && feedbackStatus !== "completed"
                      }
                    >
                      Save
                    </button>
                  </Box>
                </form>

                <Typography sx={{ fontWeight: "bold" }} variant="body1">
                  Comment
                </Typography>

                <div
                  //  className=''
                  className="scroll"
                  style={{ height: "400px", width: "100%", overflowY: "auto" }}
                >
                  {data?.comment?.map((item, index) => (
                    <div className="m-3 ">
                      {/* {item.comment.comment, "comment"} */}
                      <Box
                        className="  justify-content-between"
                        sx={{ marginBottom: "16px", padding: "8px 0" }}
                      >
                        <Box
                          sx={{
                            display: "",
                            flexDirection: "column",
                            gap: 2,
                            p: 2,
                            borderRadius: 2,
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            backgroundColor: "#fff",
                            "&:hover": {
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.15)",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 2,
                            }}
                          >
                            <Avatar
                              alt={item.user.name.toUpperCase()}
                              src="/static/images/avatar/1.jpg"
                              sx={{ width: 50, height: 50 }}
                            />
                            <Box>
                              <Typography
                                sx={{
                                  fontWeight: "bold",
                                  fontSize: "1rem",
                                  color: "#333",
                                }}
                                variant="body1"
                              >
                                {item.user.name}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "0.875rem",
                                  color: "text.secondary",
                                }}
                                variant="body2"
                              >
                                {moment(item.createdAt).format(
                                  "DD-MM-YYYY HH:mm"
                                )}
                              </Typography>
                            </Box>
                          </Box>
                          <Typography
                            sx={{
                              fontSize: "0.875rem",
                              color: "#555",
                              lineHeight: 1.6,
                              marginTop: "12px",
                              whiteSpace: "normal",
                              wordWrap: "break-word",
                            }}
                            variant="body2"
                          >
                            {expandedComments.includes(index)
                              ? item.comment
                              : `${item.comment.slice(0, 100)}${
                                  item.comment.length > 100 ? "..." : ""
                                }`}
                          </Typography>
                          {item.comment.length > 100 && (
                            <Typography
                              onClick={() => toggleShowMore(index)}
                              className="text-primary"
                              sx={{
                                fontSize: "0.875rem",
                                cursor: "pointer",
                                textAlign: "right",
                                marginTop: "8px",
                                "&:hover": {
                                  textDecoration: "underline",
                                },
                              }}
                              variant="body2"
                            >
                              {expandedComments.includes(index)
                                ? "Show Less"
                                : "Read More"}
                            </Typography>
                          )}
                        </Box>
                      </Box>

                      <hr />
                    </div>
                  ))}
                </div>
              </Paper>
            </Grid>
            <Paper
              sx={{ p: 3, my: 1 }}
              elevation={1}
              className={"your-paper-clas  w-100 download-comment-section ".concat(
                hide
              )}
            >
              <FormGroup className={`${hide}`}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={
                        data?.singleMeetingReq?.markCompletedDate ? true : false
                      }
                      checked={feedbackStatus === "completed"}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Mark as complete"
                  // onClick={() => setmodalshow(true)}
                />
              </FormGroup>
              <Typography className={`${show}`} variant="body1">
                Add comment
              </Typography>

              <form className={`${show}`} onSubmit={handelsubmit}>
                <TextField
                  id="outlined-basic"
                  value={comment}
                  onChange={(e) => setcomment(e.target.value)}
                  label="Type"
                  variant="outlined"
                />

                <Box className="d-flex justify-content-end " sx={{ my: 3 }}>
                  <button
                    type="submit"
                    className={`btn btn_bg ${
                      feedbackStatus !== "completed" && comment === ""
                        ? "disable"
                        : ""
                    }`}
                    disabled={comment === "" && feedbackStatus !== "completed"}
                  >
                    Save
                  </button>
                </Box>
              </form>

              <Typography sx={{ fontWeight: "bold" }} variant="body1">
                Comment
              </Typography>

              <div className="" style={{ height: "400px", overflowY: "auto" }}>
                {data?.comment?.map((item) => (
                  <div>
                    {/* {item.comment.comment, "comment"} */}
                    <Box className=" d-flex gap-1 justify-content-between">
                      <Avatar
                        alt={item?.user?.name}
                        src="/static/images/avatar/1.jpg"
                      />
                      <Box className="">
                        <Typography sx={{ fontWeight: "bold" }} variant="body1">
                          {item?.user?.name.toUpperCase()}
                        </Typography>
                        <Typography sx={{ fontSize: "12px" }} variant="body1">
                          {item.comment}
                          {/* jiji */}
                        </Typography>
                      </Box>
                      <Box className="d-flex gap-2">
                        <span className="fs_10px">
                          {moment(item.createdAt).format("DD-MM-YYYY-HH:mm")}

                          {/* {item.createdAt} */}
                        </span>
                        <MoreVertIcon />
                      </Box>
                    </Box>
                    <hr />
                  </div>
                ))}
              </div>
            </Paper>
          </Grid>
        </Box>
      )}

      {modalshow && (
        <ReqMettingModal
          show={modalshow}
          id={id}
          setmodalshow={setmodalshow}
          setFeedbackStatus={setFeedbackStatus}
          setIsclicked={setIsclicked}
          onHide={() => {
            setmodalshow(false);
            if (feedbackStatus !== "completed") {
              setFeedbackStatus("");
            }
          }}
        />
      )}
    </div>
  );
};

export default FbReqdetal;

const ReqMettingModal = (props) => {
  // const [status, setstatus] = useState("completed")
  const { id, setFeedbackStatus, setIsclicked, setmodalshow, ...modalProps } =
    props;
  console.log(id, "id in props");
  // console.log(props, "props")

  const handleConfirm = () => {
    setFeedbackStatus("completed"); // Update isChecked to true
    setmodalshow(false); // Close the modal
    setIsclicked(true); // Close the modal
  };

  return (
    <Modal
      {...modalProps}
      // onHide
      // show
      // onExit
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="rounded-3"
    >
      <Modal.Body className="text-center rounded-3">
        {/* <FaCheckCircle  className="display-1" style={{color:"#029E96"}}/>
          <h5 className='mt-2'>Email Sent</h5> */}
        <p>Are you sure you want to mark this submission as complete?</p>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between align-items-center">
        <div
          style={{ textDecoration: "none", color: "white" }}
          className=""
          to={-1}
        >
          <button
            className="btn btn-success btn-block m-1"
            onClick={handleConfirm}
          >
            Yes
          </button>
        </div>
        <button className="btn btn-danger btn-block m-1" onClick={props.onHide}>
          No
        </button>
      </Modal.Footer>
    </Modal>
  );
};
