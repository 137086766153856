import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import apiClient from "../../api/apiClient";
import { useSelector } from "react-redux";
import Rating from "@mui/material/Rating";
import { CircularProgress } from '@mui/material';
import { toast } from "react-toastify";

function PositiveShowRoundForm() {
  const selectlocation = useSelector((state) => state.filter.filter);
  console.log(selectlocation, "ll");
  const initialState = {
    dateOfRequest: new Date(),
    dateOfVisit:"",
    rating: "",
    mostLike: "",
    improvement: "",
    otherComment: "",
  };

  const [user, setUser] = useState(initialState);
  const [locData, setLoacdata] = useState([]);
    const [loading, setloading]=useState(false);

  const navigate = useNavigate();

  const fetchdata = async (e) => {
    try {
            setloading(true);
      const result = await apiClient.get(`/location`);
      console.log(result, "result getted");
      // Extract location IDs from the response data
      const locationIds = result.data.location[0]._id;

      // Now you have an array of location IDs
      console.log(locationIds, "aaaa neg");

      // Set the location IDs using setLoacdata
      setLoacdata(locationIds);
            setloading(false)
    } catch (error) {
      console.error("Error fetching location data:", error);
    }
  };

  useEffect(() => {
    fetchdata();
  }, []);


  const validateForm = () => {
    if (!user.dateOfRequest) {
        toast.error("Date of Visit is required");
        return false;
    }

    if (!user.mostLike.trim()) {
        toast.error("What did you like the most? field is required");
        return false;
    }
    if (!user.improvement.trim()) {
        toast.error("What could we improve? field is required");
        return false;
    }
    if (!user.otherComment.trim()) {
        toast.error("Other Commment is required");
        return false;
    }


    console.log("location data for select location",locData);
    if(!locData){
        toast.error("please select location");
        return false;
    }
    return true;
};

  const handleData = async (e) => {
    e.preventDefault();
    e.preventDefault();
        if (!validateForm()) {
            return;
        }
    apiClient.setHeader("location", locData);
    const result = await apiClient.post("/show-round", user, {
      headers: { location: `${selectlocation}` },
    });
    console.log(result, "resultt");
    console.log(user.loc_no, "userddddddddddddd");
    navigate("/showround-feedback");
  };

  const handleChange = (name, value) => {
    console.log("Selected Value:", value);
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const currentDate = new Date().toISOString().split("T")[0];
  return (
    <div className="container">
      {!loading?<form onSubmit={handleData} className=" py-4">
        <Link to="/showround-feedback" className="btn btn_bg">
          <ArrowBackIosIcon />
          Back
        </Link>

        <h3 className="h1Color text-center">
          Please complete this form to submit your feedback
        </h3>

        <div className="row justify-content-center">
          {/* <div className="col-lg-7 col-12 mb-3">
            <label for="calendar" class="form-label fw-bold">
              Date of Submit:
            </label>
            <input
              type="date"
              class="form-control pointer"
              value={user.dateOfRequest}
              onChange={(e) => handleChange("dateOfRequest", e.target.value)}
              id="calendar"
              defaultValue={user.dateOfRequest}
            />
          </div> */}
          <div className="col-lg-7 col-12 mb-3">
                        <label for="calendar" className="form-label fw-bold">Date of Visit:</label>
                        <input
                            type="date"
                            name='dateOfVisit'
                            className="form-control pointer"
                            id="calendar"
                            defaultValue={''}
                            value={user.dateOfVisit}
              onChange={(e) => handleChange("dateOfVisit", e.target.value)}
                        />
                    </div>
          <div className="col-lg-7 col-12 mb-3">
            <label for="fname" class="form-label fw-bold">
              Rate your visit:
            </label>
            <div className="">
              <Rating
                name="simple-controlled"
                value={user.rating}
                onChange={(e) => handleChange("rating", e.target.value)}
              />
            </div>
            {/* <input
              type="number"
              class="form-control"
              id="fname"
              value={user.rating}
              onChange={(e) => handleChange("rating", e.target.value)}
              placeholder="Please enter your first name"
            /> */}
          </div>

          <div className="col-lg-7 col-12 mb-3">
            <label for="mostFeedback" class="form-label fw-bold">
              What did you like the most?
            </label>
            <textarea
              rows="4"
              class="form-control"
              id="mostFeedback"
              value={user.mostLike}
              onChange={(e) => handleChange("mostLike", e.target.value)}
              placeholder="What did you like the most?"
            />
          </div>

          
          <div className="col-lg-7 col-12 mb-3">
            <label for="comments" class="form-label fw-bold">
              Any other comments?
            </label>
            <textarea
              className="form-control"
              id="comments"
              rows="4"
              value={user.otherComment}
              onChange={(e) => handleChange("otherComment", e.target.value)}
              placeholder="Please enter your feedback here"
            ></textarea>
          </div>
          <div className="col-lg-7 col-12 mb-3">
            <label for="couldFeedback" class="form-label fw-bold">
              What could we improve?
            </label>
            <textarea
              rows="4"
              class="form-control"
              id="couldFeedback"
              value={user.improvement}
              onChange={(e) => handleChange("improvement", e.target.value)}
              placeholder="What could we improve?"
            />
          </div>
          <div className="col-lg-7 col-12">
            <input
              type="submit"
              value="Submit"
              className="my-3 text-white customBtn"
            />
          </div>
        </div>
      </form>:<div className="d-flex justify-content-center align-items-center"><CircularProgress/></div> }
    </div>
  );
}

export default PositiveShowRoundForm;
