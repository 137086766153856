import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import apiClient from "../../api/apiClient";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";

function PositiveReqMeeting() {
  const selectlocation = useSelector((state) => state.filter.filter);
  console.log(selectlocation, "ll");
  const initialState = {
    type: "ReqMetting",
    dateOfRequest:new Date(),
    firstName: "",
    lastName: "",
    serviceUser: "",
    meetingWith: "",
    purposeOfMeeting: "",
    meetingType: "",
    preferredContactMethod: "",
    contactInformation: "",
  };
  const [user, setUser] = useState(initialState);
  const [locData, setLoacdata] = useState([]);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();

  const fetchdata = async (e) => {
    try {
      setloading(true);
      const result = await apiClient.get(`/location`);
      const locationIds = result.data.location[0]._id;
      setLoacdata(locationIds);
      setloading(false);
    } catch (error) {
      console.error("Error fetching location data:", error);
      toast.error("Failed to fetch location data");
    }
  };

  useEffect(() => {
    fetchdata();
  }, []);

  const handelBack = () => {
    navigate(-1);
  };

  const validateForm = () => {
    const {
      firstName,
      lastName,
      serviceUser,
      meetingWith,
      purposeOfMeeting,
      meetingType,
      preferredContactMethod,
      contactInformation,
    } = user;

    if (!firstName.trim()) {
      toast.error("First Name is required");
      return false;
    }

    if (!lastName.trim()) {
      toast.error("Last Name is required");
      return false;
    }

    if (!serviceUser.trim()) {
      toast.error("Service User is required");
      return false;
    }

    if (!meetingWith.trim()) {
      toast.error("Meeting With is required");
      return false;
    }

    if (!purposeOfMeeting.trim()) {
      toast.error("Purpose of Meeting is required");
      return false;
    }

    if (!meetingType) {
      toast.error("Please select a meeting type");
      return false;
    }

    if (!preferredContactMethod) {
      toast.error("Please select a preferred contact method");
      return false;
    }

    if (!contactInformation.trim()) {
      toast.error("Contact Information is required");
      return false;
    }

    if (
      preferredContactMethod === "Email" &&
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contactInformation)
    ) {
      toast.error("Please enter a valid email address");
      return false;
    }

    // if (
    //   preferredContactMethod === "Telephone" &&
    //   !/^\d{10}$/.test(contactInformation)
    // ) {
    //   toast.error("Please enter a valid 10-digit phone number");
    //   return false;
    // }

    return true;
  };

  const handleData = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setloading(true);
    try {
      apiClient.setHeader("location", locData);
      const result = await apiClient.post("/req-metting", user, {
        headers: { location: `${selectlocation}` },
      });
      console.log(result)
      if(result.status === 200){

        toast.success("Request submitted successfully");
        navigate("/request-meeting");
      }else{
        toast.error("Failed to submit request");
 
      }
    } catch (error) {
      console.error("Error submitting request:", error);
      toast.error("Failed to submit request");
    } finally {
     
      setloading(false);
    }
  };

  const handleChange = (name, value) => {
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const currentDate = new Date();

  return (
    <div className="container">
      {!loading ? (
        <form onSubmit={handleData} className="py-4">
          <Link onClick={handelBack} to="/req-metting" className="btn btn_bg">
            <ArrowBackIosIcon />
            Back
          </Link>

          <h3 className="h1Color text-center">
            Please complete this form to submit your feedback
          </h3>

          <div className="row justify-content-center">
            {/* <div className="col-lg-7 col-12 mb-3">
              <label htmlFor="calendar" className="form-label fw-bold">
                Date of Submit:
              </label>
              <input
                type="date"
                className="form-control pointer"
                value={user.dateOfRequest}
                onChange={(e) => handleChange("dateOfRequest", e.target.value)}
                id="calendar"
                defaultValue={user.dateOfRequest}
              />
            </div> */}

            

            <div className="col-lg-7 col-12 mb-3">
              <label htmlFor="fname" className="form-label fw-bold">
                First Name:
              </label>
              <input
                type="text"
                className="form-control"
                id="fname"
                value={user.firstName}
                onChange={(e) => handleChange("firstName", e.target.value)}
                placeholder="Please enter your first name"
                required
              />
            </div>

            <div className="col-lg-7 col-12 mb-3">
              <label htmlFor="lname" className="form-label fw-bold">
                Last Name:
              </label>
              <input
                type="text"
                className="form-control"
                id="lname"
                value={user.lastName}
                onChange={(e) => handleChange("lastName", e.target.value)}
                placeholder="Please enter your last name"
                required
              />
            </div>

            <div className="col-lg-7 col-12 mb-3">
              <label htmlFor="couldFeedback" className="form-label fw-bold">
                Which resident / service user would you like a meeting about?
              </label>
              <input
                type="text"
                className="form-control"
                id="couldFeedback"
                value={user.serviceUser}
                onChange={(e) => handleChange("serviceUser", e.target.value)}
                placeholder="Please Type"
                required
              />
            </div>

            <div className="col-lg-7 col-12 mb-3">
              <label htmlFor="comments" className="form-label fw-bold">
                Who would you like a meeting with?
              </label>
              <input
                type="text"
                className="form-control"
                id="comments"
                value={user.meetingWith}
                onChange={(e) => handleChange("meetingWith", e.target.value)}
                placeholder="Please type"
                required
              />
            </div>

            <div className="col-lg-7 col-12 mb-3">
              <label htmlFor="pom" className="form-label fw-bold">
                Purpose of Meeting
              </label>
              <input
                type="text"
                className="form-control"
                id="pom"
                value={user.purposeOfMeeting}
                onChange={(e) =>
                  handleChange("purposeOfMeeting", e.target.value)
                }
                placeholder="Please type"
                required
              />
            </div>

            <div className="col-lg-7 col-12 mb-3">
              <label htmlFor="whatMeeting" className="form-label fw-bold">
                What sort of meeting would you like?
              </label>
              <div>
                <select
                  className="form-select"
                  value={user.meetingType}
                  onChange={(e) => handleChange("meetingType", e.target.value)}
                  id="whatMeeting"
                  aria-label="Default select example"
                  required
                >
                  <option value="" disabled selected>
                    Please Select
                  </option>
                  <option value="Face to Face">Face to Face</option>
                  <option value="Video Call"> Video Call </option>
                  <option value="Telephone">Telephone</option>
                </select>
              </div>
            </div>

            <div className="col-lg-7 col-12 mb-3">
              <label htmlFor="contacted" className="form-label fw-bold">
                Preferred method of contact to arrange meeting
              </label>
              <div>
                <select
                  className="form-select"
                  value={user.preferredContactMethod}
                  onChange={(e) =>
                    handleChange("preferredContactMethod", e.target.value)
                  }
                  id="contacted"
                  aria-label="Default select example"
                  required
                >
                  <option value="" disabled selected>
                    Please Select
                  </option>
                  <option value="Email">Email</option>
                  <option value="Telephone">Telephone</option>
                </select>
              </div>
            </div>

            <div className="col-lg-7 col-12 mb-3">
              <label htmlFor="contact" className="form-label fw-bold">
                Contact information
              </label>
              <input
                type="text"
                className="form-control"
                id="contact"
                value={user.contactInformation}
                onChange={(e) =>
                  handleChange("contactInformation", e.target.value)
                }
                placeholder="Please type"
                required
              />
            </div>

            <div className="text-center mt-3">
              <button type="submit" className="btn btn_bg">
                Submit Request
              </button>
            </div>
          </div>
        </form>
      ) : (
        <div className="text-center">
          <CircularProgress />
        </div>
      )}
    </div>
  );
}

export default PositiveReqMeeting;
