import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoPersonOutline } from "react-icons/io5";
import { Grid, Box, Typography, TextField } from '@mui/material';
import Paper from '@mui/material/Paper';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import apiClient from "../api/apiClient";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { login } from "../redux/authSlice";



const LoginManger = () => {
  const navigate = useNavigate();
  const dispatch=useDispatch();
  const [loc_no, setloc_no] = useState("");
  // const [password, setpassword] = useState("")
  const [loading, setLoading] = useState(false);


  const handelSubmit = async(e)=>{
    e.preventDefault();
    // console.log(email, password, "passw")
    // alert()
    setLoading(true);
    const result = await apiClient.post("managerauth/", {loc_no});
    // console.log(result, "eee");
    setLoading(false);
    if(!result.ok) return toast.error(result?.data?.message|| "error");
    console.log(result, "res")
    const role = result.data?.manager;
    console.log(role, "role")
    // dispatch(loginManger(role));
    navigate("/dashboard")
  }


    return (
        <>
        <Grid className='d-flex justify-content-center align-items-center rounded-5'  style={{height:"100vh"}}   container spacing={0} >

        <Grid className=' rounded-5'item xs={12} md={10} >
        <Paper elevation={20} sx={{height:'90vh', borderRadius:"30px" }}>
        <Grid className='  rounded-5' sx={{height:"90vh"}} item container spacing={0} xs={12} >
        <Grid className=' py-5 rounded-start-5 d-none d-sm-block h-100' item xs={12} md={6} sx={{backgroundColor:"#e9faf9"}} >
        <Box className="d-flex justify-content-center p-4">
          <img src={require("../assets/smalllogo.svg").default} alt="" />
        <Typography sx={{p:3, color:"#00968f"}} variant="h5">
          Care Feedback Solutions
        </Typography>
        </Box>
        

        <Box className="d-flex justify-content-center p-4">
            <div style={{width:"20rem"}}>
          <img className="img-fluid" src={require("../assets/homepage.svg").default} alt="" />
            </div>
        </Box>
       
        </Grid>

        <Grid className=" py-5 px-sm-5 px-3"  item xs={12} md={6}>
        <Typography className="pt-3 fw-bold"  variant="h4" align="">
        Welcome
         </Typography>

     <Typography className="py-3" variant="body1" align="" sx={{color:"rgb(154,157,157)"}}>
     “Welcome to the Care Feedback Solutions web management system, where you can review, action and follow up on all your feedback received. <br />
      Please log in below, if it is your first time using the system, please click forgot account to set a password.”
     </Typography>


     <form onSubmit={handelSubmit}>
     <Grid container sx={{py:3}} spacing={2}>
      <Grid item xs={12}>
        <TextField
          id="outlined-email"
          label="Manger location no"
          type="number"
          variant="outlined"
          fullWidth
          value={loc_no}
          onChange={(e)=>setloc_no(e.target.value)}
          InputProps={{
            startAdornment: (
              <EmailIcon style={{ marginRight: '8px', color: 'rgba(0, 0, 0, 0.54)' }} />
            ),
          }}
          inputProps={{ min: 0 }}
        />
      </Grid>

      {/* <Grid item xs={12}>
        <TextField
          id="outlined-password"
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e)=>setpassword(e.target.value)}
          fullWidth
          InputProps={{
            startAdornment: (
              <LockIcon style={{ marginRight: '8px', color: 'rgba(0, 0, 0, 0.54)' }} />
            ),
          }}
        />
      </Grid> */}

      <Box className="d-flex justify-content-end w-100 text-dark " >
        <Link to='/sendmail' className="text-dark btn pe-0">Forget Account?</Link>
      </Box>
    </Grid>

     <Box className="d-sm-flex justify-content-between  mt-3 ">
        <div className="d-flex gap-2 align-items-center my-sm-0 my-3">
        <input type="checkbox" name="" id="rem" />
        <label  htmlFor="rem">
        Remember Me
        </label>
        </div>
            {/* <Link to={'/dashboard'} style={{textDecoration:"none", color:"white"}}> */}
            <button type="submit" variant="contained" className="btn_bg btn px-5  rounded-pill" >
             {loading?(
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
             ):(
              "Login"
              )}
            </button>

              {/* {loading ? 'Loading...' : 'Login'} */}
            {/* </Link> */}
        </Box>
     </form>





        </Grid>
        </Grid>


        
      </Paper>
                    
      </Grid>
    </Grid>
        </>
    );
}

export default LoginManger;