import { Avatar, Box, Button, TextareaAutosize, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import html2pdf from "html2pdf.js";
import { MarkModal } from "../components/MarkModal";
import logo from "../assets/logo.png";
import { Result } from "postcss";
import apiClient from "../api/apiClient";
import { formatDate } from "../modules/helpers";
import { toast } from "react-toastify";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";

const Fbdetailstaff = () => {
  const [data, setdata] = useState("");
  const [comment, setcomment] = useState("");
  const [feedbackStatus, setFeedbackStatus] = useState(false);
  const [modalshow, setmodalshow] = useState(false);
 
  const [isChecked, setIsChecked] = useState(false);

  const [isClicked, setIsClicked] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate()
  const location = useLocation()
  const { id } = useParams();

  // const toggleShowMore = () => setShowMore(!showMore);
  const [expandedComments, setExpandedComments] = useState([]);

  const toggleShowMore = (index) => {
    if (expandedComments.includes(index)) {
      setExpandedComments(expandedComments.filter(i => i !== index));
    } else {
      setExpandedComments([...expandedComments, index]);
    }
  };

console.log("ma a giaaaa")
  const handelsubmit = async (e) => {
    e.preventDefault();
    let shouldNavigate = false;

    if (isChecked && isClicked) {
      const updateSuccessful =  await updateFeedback();
      if (updateSuccessful) {
        shouldNavigate = true; // Set the flag to true if the update is successful
      } else {
        // Reset the state if the update fails
        setFeedbackStatus("");
        return; // Exit if the status update fails
      }
    }

    if (comment !== "") {
      const result = await apiClient.post(`comment`, { comment, feedbackId: id });
      if (!result.ok) return toast.error("error");
      toast.success("comment added");
      setcomment("");
      fetchdata();
    }
    if (shouldNavigate) {
      navigate(`/all-feedback`)
      }
    
  };

  const fetchdata = async () => {
    const result = await apiClient.get(`feedback/${id}`);
    setdata(result.data);
    // setFeedbackStatus(result.data.getSingelFeedback?.status);
    // console.log(result.data.getSingelFeedback?.status)

    try {
      let status = result.data.getSingelFeedback?.status === "completed" ? true : false
      setIsChecked(status);
    } catch (error) {
      console.log(error)
    }

   
    // data?.getSingelFeedback?.status
    //   console.log(result, "detal fd")

    //   const commentdata = await apiClient.get("comment")
    //   console.log(commentdata, "cmnt data")
    //   setgetcomment(commentdata.data.comment)
    //   console.log(commentdata.data.comment, "getcomment data")
  };

  useEffect(() => {
    fetchdata();
  }, []);

  const [hide, sethide] = useState("d-none");
  const [show, setshow] = useState("d-block");

  // const downloadPdf = async () => {
  //   await sethide("d-block");
  //   await setshow("d-none");
  //   // setCommentDownload('d-block')
  //   const element = await document.getElementById("forDownload");
  //   const pdfOptions = {
  //     margin: 1,
  //     filename: "feedback.pdf",
  //     image: { type: "jpeg", quality: 0.98 },
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  //   };

  //   html2pdf().from(element).set(pdfOptions).save();

  //   sethide("d-none");
  //   setshow("d-block");
  //   // setCommentDownload('d-none')
  // };
  
  // const state = location.state;
  const name = location.pathname.split("/")[1].split("-")[0];
  let state = name.charAt(0).toUpperCase() + name.slice(1);
  console.log(state , "state")
  //   console.log(location, "state");

  const updateFeedback = async () => {
    try {

      const response = await apiClient.put(`feedback/${id}`, {
        status: "completed"
      });
      console.log(response)
      if (response.status === 200) {

          console.log("Feedback status updated successfully to completed");
          toast.success("Feedback status updated successfully")
          return true; // Return true if the update is successful

        }else{
        setFeedbackStatus("")
        toast.error("Error updating feedback status");
        return false; // Return false if the update fails

      }
  
    } catch (error) {
      setFeedbackStatus("")
      toast.error("Error updating feedback status");
      console.error("Error updating feedback status:", error.message);
      return false; // Return false if the update fails

    }
  };

  const handleCheckboxChange = () => {
    if (!isChecked) {
      setmodalshow(true);
    } else {
      setIsChecked(false);
    }
  };

  const downloadPdf = async () => {
    const doc = new jsPDF();
    
    doc.text(`${state} Feedback`, 85, 22);
    doc.addImage(logo, 'PNG', 170, 4, 30, 20); // Adjust the x, y, width, height as needed


    const feedbackData = [
      ["Ref Number:", data?.getSingelFeedback?.serialName],
      ["Date of submitted:", formatDate(data?.getSingelFeedback?.dateOfRequest)],
      ["Date of visit:", formatDate(data?.getSingelFeedback?.dateOfVisit)],
      ["First Name:", data?.getSingelFeedback?.first_name],
      ["Last Name:", data?.getSingelFeedback?.last_name],
    ...(data?.getSingelFeedback?.additionalFields?.capacity ? [["Capacity:", data.getSingelFeedback.additionalFields.capacity]]   : []), 
      ["Status:", data?.getSingelFeedback?.status],
      ["Does your feedback relate to a specific member of staff?:", data?.getSingelFeedback?.staf_name !== "" ? "Yes" : "No"],
      ["Staff Name:", data?.getSingelFeedback?.staf_name || ""],
      ["Visitor Name:", data?.getSingelFeedback?.additionalFields?.who_visit || "N/A"],
      ["Feedback:", data?.getSingelFeedback?.additionalFields?.feedback],
      ["Would you like us to respond to your feedback?:", data?.getSingelFeedback?.additionalFields?.contact !== "" ? "Yes" : "No"],
      ["Response Required:", data?.getSingelFeedback?.additionalFields?.contact],
    ...(data?.getSingelFeedback?.additionalFields?.how_improve         ? [["How to Improve:", data.getSingelFeedback.additionalFields.how_improve]]    : []),
    data?.getSingelFeedback?.markCompletedDate &&["Mark as Completed Date:", formatDate(data?.getSingelFeedback?.markCompletedDate) || "N/A"],
  ].filter(Boolean);;


  
  
  
    doc.autoTable({
      startY: 30,
      head: [['Field', 'Value']],
      body: feedbackData,
      
      headStyles: {
        fillColor: '#00a098', // Background color for header cells
        textColor: '#FFFFFF', // Text color inside the header cells (optional)
      },
    });
  
    if (data?.comment?.length) {
      const commentData = data.comment.map((item) => [
        item.user.name,                                    
        item.comment,                                     // Comment text
        moment(item.createdAt).format("DD-MM-YY"),       // Date in DD-MM-YYYY format
        moment(item.createdAt).format("HH:mm"),            // Time in HH:mm format
      ]);
      
      
      
      
  
      doc.text("Comments", 14, doc.autoTable.previous.finalY + 10);
  
      doc.autoTable({
        startY: doc.autoTable.previous.finalY + 15,
        head: [['User', 'Comment','Date' ,'Time']],
        body: commentData,
        columnStyles: {
          0: { cellWidth: 30 },  // Adjust these widths as needed
          2: { cellWidth: 30 },
          3: { cellWidth: 20 },
        },
      
        
        headStyles: {
          fillColor: '#00a098', // Background color for header cells
          textColor: '#FFFFFF', // Text color inside the header cells (optional)
        },
      });
    }
  
    doc.save(`${data?.getSingelFeedback?.serialName || 1}-${state}-feedback.pdf`);
  };
  
  return (
    <div id="forDownload" className="bg-white pt-3">
      <div className="d-sm-flex w-100 justify-content-between align-items-center">
        <div>
          <Link style={{ textDecoration: "none" }} className="" to={`/${state.toLowerCase()}-feedback`}>
            <Button
              variant="contained"
              className={`btn btn_bg m-3 d-flex gap-2  ${show}`}
            >
              <ArrowBackIosIcon /> Back
            </Button>
          </Link>
        </div>
        <div className=" d-flex justify-content-between align-items-center w-100 ">
          <h4 className="m-3 text-center w-100">{state} Feedback</h4>
          <img
            id="hidden-image"
            className={`${hide}`}
            src={logo}
            style={{ width: "100px", height: "100px" }}
            alt=""
          />
        </div>
        <div className=" me-lg-5 pe-lg-5 m-3">
          <button
            style={{ backgroundColor: "#00A098" }}
            className={`btn text-light me-lg-5 me-3 ${show}`}
            onClick={downloadPdf}
          >
            Download
          </button>
        </div>
      </div>

      <Box className="container ">
        <Grid container spacing={2}>
          <Grid sx={{ width: "100%" }} className="" item md={8}>

            <Box sx={{ my: 3, width: "100%" }}>
              <Typography variant="body1">Date of visit:</Typography>

              <Typography
                sx={{ p: 2, width: "100%" }}
                className="border"
                variant="body1"
              >
                {console.log(data, "data")}
                {formatDate(data?.getSingelFeedback?.dateOfVisit)}
              </Typography>
            </Box>

            <Box sx={{ my: 3, width: "100%" }}>
              <Typography variant="body1">First Name</Typography>

              <Typography
                sx={{ p: 2, width: "100%" }}
                className="border"
                variant="body1"
              >
                {data?.getSingelFeedback?.first_name}
              </Typography>
            </Box>

            <Box sx={{ my: 3, width: "100%" }}>
              <Typography variant="body1">Last Name</Typography>

              <Typography
                sx={{ p: 2, width: "100%" }}
                className="border"
                variant="body1"
              >
              
                {data?.getSingelFeedback?.last_name}
              </Typography>
            </Box>


            {

data?.getSingelFeedback?.additionalFields?.hasOwnProperty('capacity') &&
<Box sx={{ my: 3, width: "100%" }}>
<Typography variant="body1">Capacity</Typography>

<Typography
  sx={{ p: 2, width: "100%" }}
  className="border"
  variant="body1"
>
  {data?.getSingelFeedback?.additionalFields?.capacity}
</Typography>
</Box>

}


                  <div className="col-lg-7 col-12 mb-3">
                        <label for="sFeedback" className="form-label fw-bold">Does your feedback relate to a specific member of staff?</label><br />
                        <input
                            type="checkbox"
                            id="sFeedback1"
                            className='checkbox-btn'
                            name="sFeedback"
                            disbale={true}
                            checked={data?.getSingelFeedback?.staf_name ? true : false}
                        />
                        <label for="sFeedback1" className='me-2'>Yes</label>
                        <input
                            type="checkbox"
                            id="sFeedback2"
                            className='checkbox-btn'
                            name="sFeedback"
                            disbale={true}
                            checked={data?.getSingelFeedback?.staf_name === ""? true : false}
                        />
                        <label for="sFeedback2">No</label>
              
                    </div>


                    {data?.getSingelFeedback?.hasOwnProperty('staf_name') && (
  <Box sx={{ my: 3, width: "100%" }}>
    <Typography variant="body1">Staff Name</Typography>
    <Typography
      sx={{ p: 2, width: "100%" }}
      className="border"
      variant="body1"
    >
      {data?.getSingelFeedback?.staf_name }
    </Typography>
  </Box>
)}



{
  (data?.getSingelFeedback?.additionalFields?.hasOwnProperty('who_visit') ||
   data?.getSingelFeedback?.additionalFields?.hasOwnProperty('who_visiting')) && (
    <Box sx={{ my: 3, width: "100%" }}>
      <Typography variant="body1">Visitor Name</Typography>
      <Typography
        sx={{ p: 2, width: "100%" }}
        className="border"
        variant="body1"
      >
        {data?.getSingelFeedback?.additionalFields?.who_visit ?? data?.getSingelFeedback?.additionalFields?.who_visiting}
      </Typography>
    </Box>
  )
}









            {
data?.getSingelFeedback?.additionalFields?.hasOwnProperty('contact') &&

<Box sx={{ my: 3, width: "100%" }}>
<Typography variant="body1">
 Contact
</Typography>

<Typography
  sx={{ p: 2, width: "100%" }}
  className="border"
  variant="body1"
>
  {data?.getSingelFeedback?.additionalFields?.contact}
</Typography>
</Box>
  
}

            <div className="col-lg-7 col-12">
                        <label for="sFeedback" className="form-label fw-bold">Would you like us to respond to your feedback?</label>
                        <div>
                            <input
                                type="checkbox"
                                id="rFeedback1"
                                name="rFeedback"
                                // disabled={true}
                                className='checkbox-btn'
                                checked={data?.getSingelFeedback?.additionalFields?.contact === "" ? false : true}
                            />
                            <label for="rFeedback1" className='me-2'>Yes</label>
                            <input
                                type="checkbox"
                                id="rFeedback2"
                                name="rFeedback"
                                // disabled={true}
                                className='checkbox-btn'
                                checked={data?.getSingelFeedback?.additionalFields?.contact === "" ? true : false}
                            />
                            <label for="rFeedback2">No</label>
                        </div>
                      
                    </div>

            
                    <Box sx={{ my: 3, width: "100%" }}>
              <Typography variant="body1">Feedback</Typography>

              <Typography
                sx={{ p: 2, width: "100%" }}
                className="border"
                variant="body1"
              >
                {data?.getSingelFeedback?.additionalFields?.feedback}
              </Typography>
            </Box>



{
data?.getSingelFeedback?.additionalFields?.hasOwnProperty('how_improve') &&

<Box sx={{ my: 3, width: "100%" }}>
<Typography variant="body1">
 How we can Improve
</Typography>

<Typography
  sx={{ p: 2, width: "100%" }}
  className="border"
  variant="body1"
>
  {data?.getSingelFeedback?.additionalFields?.how_improve}
</Typography>
</Box>
  
}



            {
  data?.getSingelFeedback?.hasOwnProperty('markCompletedDate') && (
    <Box sx={{ my: 3, width: "100%" }}>
      <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
      Mark as Completed Date:
      </Typography>
      <Box
        sx={{
          p: 2,
          width: "100%",
          bgcolor: "#f5f5f5", // Light gray background to simulate a disabled input
          border: "1px solid #ccc", // Border similar to a text field
          borderRadius: "4px", // Rounded corners
          color: "rgba(0, 0, 0, 0.6)", // Slightly muted text color
        }}
      >
        <Typography variant="body1">
          {formatDate(data?.getSingelFeedback?.markCompletedDate)}
        </Typography>
      </Box>
    </Box>
  )
}





          </Grid>
      


        


          
          <Grid
            // className=""
            // className={`${show}`}

            item
            md={4}
          >
            <Paper
              sx={{ p: 3, my: 6 }}
              elevation={1}
               className={`${show}`}

              // className="your-paper-class first" 
            >
              <FormGroup
              //  className={`${show}`}
               >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      disabled={data?.getSingelFeedback?.markCompletedDate ? true: false}
                      onChange={handleCheckboxChange}
                    //   onChange={(e) => {
                    //     if (feedbackStatus !== "completed") {
                    //         setFeedbackStatus("completed");
                    //         setIsChecked(true)

                    //     //   if (e.target.checked) {
                    //     //   } else {
                    //     //     setFeedbackStatus("");
                    //     //   }
                    //     }
                    //     else {
                    //         setFeedbackStatus("");
                    //       }
                    //     // if (feedbackStatus !== "completed"){
                    //     //     // setmodalshow(true);
                    //     //     setIsChecked(true)
                    //     // } 

                    //   }}
                    />
                  }
                  label="Mark as complete"
                // onClick={}
                />
              </FormGroup>
              <Typography className={`${show}`} variant="body1">
                Add comment
              </Typography>

              {/* <Typography sx={{p:2}} className='border' variant="body1">
                        Type.....
                    </Typography> */}

              <form 
              className={`${show}`} 
              onSubmit={handelsubmit}>
                
                <TextareaAutosize
                    minRows={1}
                    id="outlined-basic"
                    value={comment}
                    onChange={(e) => setcomment(e.target.value)}
                    variant="outlined"
                    placeholder="Type your comment"
                    style={{ width: '100%', padding: '10px', fontSize: '14px' }}
                  />
                <Box className="d-flex justify-content-end " sx={{ my: 3 }}>
                  <button
                    type="submit"
                    className={`btn btn_bg ${!isChecked && comment === "" ? "disable" : ""}`}
                    disabled={comment === "" && !isChecked}
                  >
                    Save
                  </button>
                </Box>
              </form>

              <Typography sx={{ fontWeight: "bold" }} variant="body1">
                Comments
              </Typography>

              <div
                //  className=''
                className="scroll"
                
                style={{ height: "400px", width:"100%" ,overflowY: "auto" }}
              >
                {data?.comment?.map((item, index) => (
                  <div
                  className="m-3 "
                  >
                    {/* {item.comment.comment, "comment"} */}
                    <Box
                     className="  justify-content-between" 
                    sx={{ marginBottom: '16px', 
                    padding: '8px 0', 
                    }}>
    <Box
      sx={{
        display: '',
        flexDirection: 'column',
        gap: 2,
        p: 2,
        borderRadius: 2,
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#fff',
        '&:hover': {
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Avatar
          alt={item.user.name.toUpperCase()}
          src="/static/images/avatar/1.jpg"
          sx={{ width: 50, height: 50, }}
        />
        <Box>
          <Typography sx={{ fontWeight: 'bold', fontSize: '1rem', color: '#333' }} variant="body1">
            {item.user.name}
          </Typography>
          <Typography sx={{ fontSize: '0.875rem', color: 'text.secondary' }} variant="body2">
            {moment(item.createdAt).format("DD-MM-YYYY HH:mm")}
          </Typography>
        </Box>
      </Box>
      <Typography
        sx={{
          fontSize: '0.875rem',
          color: '#555',
          lineHeight: 1.6,
          marginTop: '12px',
          whiteSpace: 'normal',
          wordWrap: 'break-word',
        }}
        variant="body2"
      >
        {expandedComments.includes(index)
          ? item.comment
          : `${item.comment.slice(0, 100)}${item.comment.length > 100 ? "..." : ""}`}
      </Typography>
      {item.comment.length > 100 && (
        <Typography
          onClick={() => toggleShowMore(index)}
          className="text-primary"
          sx={{
            fontSize: '0.875rem',
            cursor: 'pointer',
            textAlign: 'right',
            marginTop: '8px',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
          variant="body2"
        >
          {expandedComments.includes(index) ? 'Show Less' : 'Read More'}
        </Typography>
      )}
    </Box>
    </Box>

                    <hr />
                  </div>
                ))}
              </div>
            </Paper>
          </Grid>

          <Paper
              sx={{ p: 3, my: 0 }}
              elevation={1}
              className={"your-paper-clas  w-100 download-comment-section ".concat(hide) }
            >
              <FormGroup
              //  className={`${show}`}
               >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      disabled={data?.getSingelFeedback?.markCompletedDate ? true: false}

                      onChange={handleCheckboxChange}
                    //   onChange={(e) => {
                    //     if (feedbackStatus !== "completed") {
                    //         setFeedbackStatus("completed");
                    //         setIsChecked(true)

                    //     //   if (e.target.checked) {
                    //     //   } else {
                    //     //     setFeedbackStatus("");
                    //     //   }
                    //     }
                    //     else {
                    //         setFeedbackStatus("");
                    //       }
                    //     // if (feedbackStatus !== "completed"){
                    //     //     // setmodalshow(true);
                    //     //     setIsChecked(true)
                    //     // } 
                            
                    //   }}
                    />
                  }
                  label="Mark as complete"
                  // onClick={}
                />
              </FormGroup>



              <Typography 
               className={`${show}`}
              
              variant="body1">
                Add comment
              </Typography>




              {/* <Typography sx={{p:2}} className='border' variant="body1">
                        Type.....
                    </Typography> */}

              <form 
              className={`${show}`} 
              onSubmit={handelsubmit}>
                <TextField
                  id="outlined-basic"
                  value={comment}
                  onChange={(e) => setcomment(e.target.value)}
                  label="Type"
                  variant="outlined"
                />

                <Box className="d-flex justify-content-end " sx={{ my: 3 }}>
                  <button
                    type="submit"
                    className={`btn btn_bg ${!isChecked && comment === "" ? "disable" : ""}`}
                    disabled={comment === "" && !isChecked}
                  >
                    Save
                  </button>
                </Box>
              </form>

              <Typography sx={{ fontWeight: "bold" }} variant="body1">
                Comments
              </Typography>

              <div
                //  className=''
              //  className={`${show + ' w-100'}`}
                
                style={{  overflowY: "visible" }}
              >
                {data?.comment?.map((item) => (
                  <div>
                    {/* {item.comment.comment, "comment"} */}
                    <Box className=" d-flex gap-1 justify-content-between">
                      <Avatar
                        alt={item.user.name.toUpperCase()}
                        src="/static/images/avatar/1.jpg"
                      />
                      <Box className="">
                        <Typography sx={{ fontWeight: "bold" }} variant="body1">
                          {item.user.name}
                        </Typography>
                        <Typography sx={{ fontSize: "12px" }} variant="body1">
                          {item.comment}
                          {/* jiji */}
                        </Typography>
                      </Box>
                      <Box className="d-flex gap-2">
                        <span className="fs_10px">
                          {moment(item.createdAt).format("DD-MM-YYYY-HH:mm")}

                          {/* {item.createdAt} */}
                        </span>
                        {/* <MoreVertIcon /> */}
                      </Box>
                    </Box>
                    <hr />
                  </div>
                ))}
              </div>
            </Paper>
        </Grid>
      </Box>

      {modalshow && (
        <MarkModal
          id={id}
          show={modalshow}
          setmodalshow={setmodalshow}
          setIsChecked={setIsChecked}
          setIsClicked={setIsClicked}
          // onExit={onExit}
          onHide={() => {
            setmodalshow(false);
            if (!isChecked) {
              setFeedbackStatus("");
            }
          }}
        />
      )}
    </div>
  );
};

export default Fbdetailstaff;
