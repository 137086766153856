import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useMediaQuery, useTheme, Box, Typography } from '@mui/material';
// Define your color palette
const colors = {
  positive: '#00A098',    // Light Green
  negative: '#EF5350',    // Light Red
  pending: '#FFEE58',     // Light Orange
  completed: '#29B6F6',   // Light Blue
  overdue: 'orange',     // Light Yellow
  showround: '#AB47BC',   // Light Purple
};

const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const Example = ({ dataSummary }) => {
  const [data, setData] = useState([]);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  // useEffect(() => {
  //   setData(dataSummary);
  // }, [dataSummary]);

  useEffect(() => {
    // Map through dataSummary to reformat the date field
    const formattedData = dataSummary.map(item => ({
      ...item,
      date: formatDate(item.date) // Reformat the date field
    }));
    setData(formattedData);
  }, [dataSummary]);




  return (
<>
{!isLargeScreen ? (
         <div style={{ width: '100%', overflowX: 'auto' }}>
         <BarChart
           width={data.length * 80} // Adjust width based on data length
           height={400}
           data={data}
           margin={{
             top: 20,
             right: 30,
             left: 20,
             bottom: 5,
           }}
         >
           <CartesianGrid strokeDasharray="3 3" />
           <XAxis dataKey="date" />
           <YAxis />
           <Tooltip cursor={{ fill: 'transparent' }}  />
           <Legend />
           {Object.keys(colors).map((key) => (
             <Bar key={key} dataKey={key} fill={colors[key]} stackId="a" />
           ))}
         </BarChart>
       </div>
      ) : (
 <div 
      style={{ 
        width: '100%', 
        overflowX: 'auto' // Enable horizontal scrolling
      }}
    >
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
        
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date"  />
          <YAxis />
          <Tooltip cursor={{ fill: 'transparent' }} />
          <Legend />
          {Object.keys(colors).map((key) => (
            <Bar key={key} isAnimationActive={false} dataKey={key} fill={colors[key]} stackId="a" />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
      )}
</>

 
   
  );
};

export default Example;
