import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TableMui from '../mui/TableMui'; // Adjust the path accordingly
import Typography from '@mui/material/Typography';

const AllNegative = () => {
  const data = [
    {
      number: "1",
      date: "David",
      feedback: "Negative",
      name: "herry",
      Description: "Really pleased with the home, looking after my relative really well. I am so pleased",
      Due: "12-2-23",
      Status: "Status",
    },
    {
      number: "2",
      date: "Alice",
      feedback: "Negative",
      name: "Bob",
      Description: "Another positive experience with the service. Everything is great!",
      Due: "13-2-23",
      Status: "Status",
    },
    {
      number: "3",
      date: "John",
      feedback: "Negative",
      name: "Eva",
      Description: "Great experience so far. Keep up the good work!",
      Due: "14-2-23",
      Status: "Status",
    },
    {
      number: "4",
      date: "Sarah",
      feedback: "Negative",
      name: "Alex",
      Description: "Exceptional service and care. Highly recommended!",
      Due: "15-2-23",
      Status: "Status",
    },
    {
      number: "5",
      date: "Mia",
      feedback: "Negative",
      name: "Chris",
      Description: "Extremely satisfied with the facilities and staff. Thank you!",
      Due: "16-2-23",
      Status: "Status",
    },
  ];

  return (
    <div>
      <Box sx={{ my: 5 }}>
        <TableMui
          styleTableThead={{
            '& th': {
              color: "white",
              backgroundColor: "#4dbdb7",
              fontWeight: "700",
              whiteSpace: "nowrap",
            },
          }}
          th={{
            number: "Ref Number",
            date: "Date Submitted",
            feedback: "Positive/Negative",
            name: "Name",
            Description: "Description of Feedback",
            Due: "Due Date",
            Status: "Status",
          }}
          td={data}
          customFields={[
            {
              name: "Status",
              data: (value, item) => (
                <Box className="d-flex gap-3" style={{width:"100px"}}>
                  {/* Conditional Rendering: Positive Button */}
                  {(
                    <Button
                    variant="contained"
                    className='btn-complete'
                    // style={{ backgroundColor: "#2FAF01", textDecoration: "none" }}
                    // onClick={() => {
                    //   // Handle Complete button click
                    // }}
                  >
                    <span className='btn-inner-complete'>Complete</span>
                  </Button>
                  )}
                </Box>
              ),
            },
            {
              name: "feedback",
              data: (value, item) => (
                // Custom logic for rendering the "Details" field
                <Typography sx={{color:"red"}}>{item.feedback}</Typography>
              ),
            },
          ]}
        />
      </Box>
    </div>
  );
};

export default AllNegative;
