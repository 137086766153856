import React, { useEffect, useState } from "react";
import apiClient from "../api/apiClient";
import { toast } from "react-toastify";
import Paginate from "../mui/Paginate";
import Spinner from "../components/Spinner";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "react-bootstrap/Modal";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputBase,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Map from "../components/Map";
import MapComponent from "../components/MapComponent";

const limit = 10;
export const Location = () => {
  const [loc_no, setLocationNumber] = useState("");
  const [location, setlocation] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setdata] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [ShowDel, setShowDel] = useState(false);
  const [id, setId] = useState();
  const [address, setAddress] = useState({});
  const [locationDetails, setLocationDetails] = useState(null);
  const handleLocationDetails = (locDet) => {
    setLocationDetails(locDet);
  };
  const [coordinates, setCoordinates] = useState([]);
  const [area, setArea] = useState(0);

  const handleCoordinatesUpdate = (newCoordinates) => {
    setCoordinates(newCoordinates);
  };

  const handleAreaUpdate = (newArea) => {
    console.log("martin", newArea);
    setArea(newArea);
  };
  const handlePageChange = (event, page) => {
    console.log(page, "pagejjjj");
    setPage(page);
  };
  const handelSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // setError(null); // Reset error state before submitting data

    try {
      console.log("handleLocationDetails", locationDetails);

      const result = await apiClient.post(
        `/location/?page=${page}&limit=${limit}`,
        { loc_no, location: locationDetails.name, area, coordinates }
      );

      if (!result.ok) {
        // If response is not OK, show error toast and throw an error
        toast.error(result?.data?.message || "Something went wrong");
        throw new Error(result?.data?.message || "API request failed");
      }

      // Show success toast and log result
      toast.success(result.data?.message);
      console.log(result, "result");

      // Refresh data after successful submission
      fetchdata();
    } catch (error) {
      // Log error and show error message
      console.error("Error submitting location data:", error);
      // setError("Failed to submit data. Please try again later.");
    } finally {
      // Ensure loading state is reset
      setLoading(false);
    }
  };


  const fetchdata = async (e) => {
    setLoading(true);
    // seterror(null); // Reset error state before fetching data

    try {
      const result = await apiClient.get(`/location/?page=${page}&limit=${limit}`);
      console.log(result, "result getted");

      setdata(result?.data?.location || []);
      setCount(result?.data?.count || 0);
    } catch (error) {
      console.error("Error fetching location data:", error);
      // seterror("Failed to fetch data. Please try again later.");
    } finally {
      console.log("working", "pppp");
      setLoading(false);
    }
  };



  // const fetchdata = async (e) => {
  //   // e.preventDefault();
  //   setLoading(true);

  //   // const result = await apiClient.get(`/location?page=${page}&limit=${limit}`);
  //   const result = await apiClient.get(
  //     `/location/?page=${page}&limit=${limit}`
  //   );
  //   // const result = await apiClient.get(`/location/users`);
  //   console.log(result, "result getted");
  //   setdata(result.data.location);
  //   setCount(result?.data.count);
  //   console.log("working", "pppp");
  //   setLoading(false);

  // };

  // console.log(data, "dataaaa");

  // const handlePageChange = (event, page) => {
  //   console.log(page, "pagejjjj");
  //   setPage(page);
  // };

  useEffect(() => {
    // setAddress({
    //   ...address,
    //   address_latitude: latitude?.toFixed(10),
    //   address_longitude: longitude?.toFixed(10),
    //   address: address?.formatted_address
    // });
    // setAddress({ ...address, address: address })

    fetchdata();
  }, [page]);

  // console.log(data, "fetched data")

  const handelDel = (e) => {
    e.preventDefault();
    console.log("del clcik");
  };

  // const data = [
  //     {
  //         number: "2",
  //         location:"Grand Canyon, Arizona",
  //     },
  //     {
  //         number: "2",
  //         location:"Statue of Liberty, New York",
  //     },
  //     {
  //         number: "3",
  //         location:"Yellowstone National Park",
  //     },
  // ]
  return (
    <>
      <div>
        <div className="row">
          <form onSubmit={handelSubmit}>
            <div
              className="container"
            // className="col-md-8 offset-md-2 d-flex gap-3"
            >
              {/* <div className="col-6">
               
              </div> */}

              <div className="col-md">
                {/* <Map
                         address={address} 
                         setAddress={setAddress} 
                         location={location}

                          /> */}
                <input
                  type="Number"
                  className="form-control mb-3"
                  placeholder="Number"
                  required
                  value={loc_no}
                  onChange={(e) => setLocationNumber(e.target.value)}
                  min={0}
                />
                <MapComponent
                  onCoordinatesUpdate={handleCoordinatesUpdate}
                  onAreaUpdate={handleAreaUpdate}
                  handleLocationDetails={handleLocationDetails}
                />
              </div>
            </div>
            <div className="col-md-8 offset-md-2 p-3">
              <button
                type="submit"
                className="btn btn-success btn-block"
                style={{ minWidth: "150px" }}
              >
                Add
              </button>
            </div>
          </form>

          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              <CircularProgress />
            </div>
          ) : (
            Array.isArray(data) &&
            data?.map((item) => (
              <div className="  col-lg-6 col-md-8 offset-lg-3 offset-md-2 p-3 border rounded-pill bg-white my-2">
                <div className="d-sm-flex justify-content-between align-items-center">
                  <div className="d-flex col-sm-4  ">
                    <label htmlFor="" className="h5 fw-bold mb-0">
                      Number:
                    </label>
                    <p className="mb-0 ms-2">{item.loc_no}</p>
                  </div>
                  <div className="d-flex col-sm-4 ">
                    <label htmlFor="" className="h5 fw-bold mb-0">
                      Location:
                    </label>
                    <p className="mb-0 ms-2">{item.location}</p>
                  </div>

                  <div className="col-sm-4 d-flex justify-content-end pe-3">
                    <DeleteIcon
                      onClick={() => {
                        setShowDel(true);
                        setId(item._id);
                      }}
                      style={{ color: "red" }}
                    />
                    {ShowDel && (
                      <DelModal
                        fetchdata={fetchdata}
                        id={id}
                        show={ShowDel}
                        onHide={() => {
                          setShowDel(false);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))
          )}

          <Paginate
            count={count}
            limit={limit}
            page={page}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

function DelModal(props) {
  console.log(props, "pppp");
  const handleDelete = async () => {
    const result = await apiClient.delete(`/location/${props.id}`);
    console.log(result.data.message, "ress");
    if (result.ok) return toast.success(result.data.message);
    props.fetchdata();
  };
  console.log(props.fetchdata, "jon 123");
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className=" " closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="text-center w-100"
        >
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              backgroundColor: "#3E4755",
              p: "4px",
            }}
          >
            <DeleteIcon style={{ fontSize: "18px", color: "white" }} />
          </Box>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="text-center">Delete Location</h4>
        <p className="text-center">
          Are you sure you want to delete <br /> this Location?
        </p>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-column">
        <Button
          onClick={() => {
            handleDelete();
            props.onHide();
            props.fetchdata();
          }}
          sx={{ borderRadius: "20px" }}
          fullWidth
          variant="contained"
          className="btn_bg_secondery"
        >
          Yes
        </Button>

        <Button
          sx={{ borderRadius: "20px" }}
          onClick={() => {
            props.onHide();
          }}
          fullWidth
          variant="contained"
          className="mt-2 btn_bg_secondery"
        >
          No
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
