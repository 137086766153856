import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Pages/Login';
import Sendmail from './Pages/SendMail';
import DashboardnavUser from './sidenav-user/Dashboard';
import Home from './screens/Home';
import All from './screens/All';
import Relative from './screens/Relative';
import Resident from './screens/Resident';
import HCP from './screens/HCP';
import Vistor from './screens/Vistor';
import Staff from './screens/Staff';
import Manger from './screens/Manger';
import SgowRound from './screens/SgowRound';
import Addmanger from './screens/Addmanger';
import EditManager from './screens/EditManager';
import AllPositive from './Feedbacks/AllPositive';
import AllNegative from './Feedbacks/AllNegative';
import Feedbackdetails from './screens/Feedbackdetails';
import Overdue from './Feedbacks/Overdue';
import Pending from './Feedbacks/Pending';
import ResetPassword from './Pages/ResetPassword';
import PositiveReceived from './Feedbacks/PositiveRecieved';
import { Location } from './screens/Location';
import LoginManger from './Pages/LoginManger';
import FbdetailShowround from './screens/FbdetailShowround';
import Fbdetailstaff from './screens/Fbdetailstaff';
import ReqMetting from './screens/ReqMetting';
import { useSelector } from 'react-redux';
import FbReqdetal from './screens/FbReqdetal';
import AddReqMeeting from './screens/Froms/PositiveReqMeeting';
import ReqCareMeeting from './screens/ReqCareMeeting';
import PositiveRelativeForm from './screens/Froms/PositiveRelativeForm';
import PositiveStaffForm from './screens/Froms/PositiveStaffForm';
import PositiveHCP from './screens/Froms/PositiveHCP';
import PositiveVisitor from './screens/Froms/PositiveVisitor';
import PositiveResidentForm from './screens/Froms/PositiveResidentForm'
import NegativeHCP from './screens/Froms/NegativeHCP';
import NegativeStaff from './screens/Froms/NegativeStaff';
import NegativeVisitor from './screens/Froms/NegativeVistor';
import NegativeRelative from './screens/Froms/NegativeRelative';
import NegativeResident from './screens/Froms/NegativeResident';
import PositiveShowRoundForm from './screens/Froms/PositiveShowRound';
import NegativeShowRoundForm from './screens/Froms/NegativeShowround';
import PositiveCareMeeting from './screens/Froms/PositiveReqCareMeeting';
import NegativeCareMeeting from './screens/Froms/NegativeCareMeeting';
import PositiveReqMeeting from './screens/Froms/PositiveReqMeeting';
import NegativeReqMeeting from './screens/Froms/NegativeReqMeeting';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import ProtectedRoute from './components/ProtectedRoute';
import PublicRoutes from './components/PublicRoutes';
function App() {
  const { userType ,isLoggedIn} = useSelector((state) => state.auth);
  return (
    <>
      <Routes>


        <Route path='/' element={
           <PublicRoutes>
              <Login/>
            </PublicRoutes>
              }
          />
        <Route path='/login-manger' element={<LoginManger/>}/>

        <Route path='/sendmail' element={<Sendmail/>}/>
        <Route path='/reset-password/:token' element={<ResetPassword/>}/>

        <Route element={     <ProtectedRoute> <DashboardnavUser/></ProtectedRoute>}>


          <Route path='/dashboard' element={              <Home/>       } />
          <Route path='/all-feedback' element={<All/>} />
          <Route path='/relative-feedback' element={<Relative/>} />
          <Route path='/resident-feedback' element={<Resident/>} />
          <Route path='/hcp-feedback' element={<HCP/>} />
          <Route path='/visitor-feedback' element={<Vistor/>} />
          <Route path='/staff-feedback' element={<Staff/>} />
          <Route path='/showround-feedback' element={<SgowRound/>} />
          {userType !== "MN" && ( <>
            <Route path='/manager' element={<Manger/>} />
            <Route path='/add-manager' element={<Addmanger/>}/>
            <Route path='/edit-manager' element={<EditManager/>}/>
          
            {/* EditManager */}
          </>
          )}
          {/* /fbstaff-details/:id */}
          {/* fbreq-details */}
          <Route path='/feedback-complete' element={<AllPositive/>}/>
          <Route path='/negative-feedback-recieved' element={<AllNegative/>}/> 
          <Route path='/feedback-details/:id' element={<Feedbackdetails/>}/>
          <Route path='/showround-details/:id' element={<FbdetailShowround/>}/>
          <Route path='/request-meeting-details/:id' element={<FbReqdetal/>}/>
          <Route path='/:sectionName-details/:id' element={<Fbdetailstaff/>}/>
          <Route path='/feedback-overdue' element={<Overdue/>}/>
          <Route path='/feedback-pending' element={<Pending/>} />
          <Route path='/positive-recieved-feedback' element={<PositiveReceived/>}/>
          <Route path='/location' element={<Location/>}/>
          <Route path='/request-meeting' element={<ReqMetting/>}/>
          <Route path='/request-care-metting' element={<ReqCareMeeting />}/>
          <Route path='/request-caremetting' element={<ReqMetting/>}/>
          {/* <Route path='/add-show-round-feedback' element={<AddShowRoundForm />} /> */}
          <Route path='/add-request-meeting' element={<AddReqMeeting />} />
          <Route path='/add-positive-relative-feedback' element={<PositiveRelativeForm />}/>
          <Route path='/add-positive-Staff-feedback' element={<PositiveStaffForm />}/>
          <Route path='/add-positive-HCP-feedback' element={<PositiveHCP />}/>
          <Route path='/add-positive-Visitor-feedback' element={<PositiveVisitor />}/>
          <Route path='/add-positive-Resident-feedback' element={<PositiveResidentForm />}/>
          <Route path='/add-negative-Resident-feedback' element={<NegativeResident />}/>
          <Route path='/add-negative-relative-feedback' element={<NegativeRelative />}/>
          <Route path='/add-negative-Vistor-feedback' element={<NegativeVisitor />}/>
          <Route path='/add-negative-Staff-feedback' element={<NegativeStaff />}/>
          <Route path='/add-negative-HCP-feedback' element={<NegativeHCP />}/>
          <Route path='/add-showround-feedback' element={<PositiveShowRoundForm />}/>
          <Route path='/add-negative-showround-feedback' element={<NegativeShowRoundForm />}/>
          <Route path='/add-positive-care-meeting-feedback' element={<PositiveCareMeeting />}/>
          <Route path='/add-negative-care-meeting-feedback' element={<NegativeCareMeeting />}/>
          <Route path='/add-request-meeting-feedback' element={<PositiveReqMeeting />}/>
          <Route path='/add-negative-request-meeting-feedback' element={<NegativeReqMeeting />}/>
        </Route>



      </Routes>
  
   
  </>
  );
}

export default App;
