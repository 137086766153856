import React from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import TableMui from '../mui/TableMui';
const PositiveReceived = () => {
  const data = [
    {
      number: "1",
      date: "27-5-24",
      feedback: "Positive",
      name: "certificate-1",
      Description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      Due: "27-5-24",
      Types: "nil",
    },
    {
      number: "2",
      date: "28-5-24",
      feedback: "Negative",
      name: "certificate-2",
      Description: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      Due: "28-5-24",
      Types: "type2",
    },
    {
      number: "3",
      date: "29-5-24",
      feedback: "Positive",
      name: "certificate-3",
      Description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      Due: "29-5-24",
      Types: "type3",
    },
    {
      number: "4",
      date: "30-5-24",
      feedback: "Negative",
      name: "certificate-4",
      Description: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
      Due: "30-5-24",
      Types: "type4",
    },
    // Add more objects as needed
  ];
  
  
  return (
    <div>

<Box className="container ">
<Grid container spacing={2}>

<Grid item xs={12} sm={6} md={4}>
 
    <Card sx={{ maxWidth: 300, backgroundColor:"#FFE7D1", padding:1 }}>
    <CardContent sx={{display:"flex", justifyContent:"start", alignItems:"center", gap:"12px"}}>
      <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width:"40px", height:"40px", backgroundColor:"#FFC793"  }} color="text.secondary" gutterBottom>
        34
      </Typography>
      <Typography className='white_space fw-bold custom-text' variant="h7" component="div">
      Showround Feedback Recieved 
      </Typography>
    </CardContent>
  </Card>
 
</Grid>

<Grid item xs={12} sm={6} md={4}>

  <Card sx={{ maxWidth: 300, backgroundColor:"#DFF8E3", padding:1 }}>
    <CardContent sx={{display:"flex", justifyContent:"start", alignItems:"center", gap:"12px"}}>
      <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width:"40px", height:"40px", backgroundColor:"#96E9A4"  }} color="text.secondary" gutterBottom>
        6
      </Typography>
      <Typography className='white_space fw-bold custom-text' variant="h6" component="div">
      Positive Relative Feedback Recieved 
      </Typography>
    </CardContent>
  </Card>
  
</Grid>

<Grid item xs={12} sm={6} md={4}>

  <Card sx={{ maxWidth: 300, backgroundColor:"#FEFFD1", padding:1 }}>
    <CardContent sx={{display:"flex", justifyContent:"start", alignItems:"center", gap:"12px"}}>
      <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width:"40px", height:"40px", backgroundColor:"#E9EB92"  }} color="text.secondary" gutterBottom>
        1%
      </Typography>
      <Typography className='white_space fw-bold custom-text' variant="h6" component="div">
         Percentage Feedback
                </Typography>
    </CardContent>
  </Card>


</Grid>


</Grid>

   </Box>


      <Box sx={{my:5}}>

      <TableMui
  styleTableThead={{ 
    '& th': {
      color:"white",
      backgroundColor:"#4dbdb7",
      fontWeight: "700",
    whiteSpace: "nowrap",
    },
  }}
  th={{
    number: "Ref Number",
    date: "Date Submitted",
    feedback: "Positive/Negative",
    name: "Name",
    Description: "Description of Feedback",
    Due: "Due Date",
    Status: "Status",
  }}
  td={data}
  customFields={[
    {
      name: "Status",
      data: (value, item) => (
        <Box className="d-flex gap-3" style={{width:"100px"}}>
              {/* Conditional Rendering: Overdue Button */}
              {item.Types === "type2" ? (
                <Button
                variant="contained"
                className='btn-overdue'
                // style={{ backgroundColor: "#FF5252", textDecoration: "none" }}
                // onClick={() => {
                //   // Handle Overdue button click
                // }}
              >
                <span className='btn-inner-overdue'>Overdue</span>
              </Button>
              ) : item.Types === "type3" ? (
                // Conditional Rendering: Pending Button
                <Button
                  variant="contained"
                  className='btn-pending'
                  // style={{ backgroundColor: "#FEB804", textDecoration: "none" }}
                  // onClick={() => {
                  //   // Handle Pending button click
                  // }}
                >
                  <span className='btn-inner-pending'>Pending</span>
                </Button>
              ) : (
                <Button
                variant="contained"
                className='btn-complete'
                // style={{ backgroundColor: "#2FAF01", textDecoration: "none" }}
                // onClick={() => {
                //   // Handle Complete button click
                // }}
              >
                <span className='btn-inner-complete'>Complete</span>
              </Button>
                  )}
            </Box>
      
      ),
    }
  ]}

/>


</Box>


    </div>
  )
}

export default PositiveReceived