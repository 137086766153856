import { Link, useNavigate, useParams } from "react-router-dom";
import { Grid, Box, Typography, TextField } from '@mui/material';
import Paper from '@mui/material/Paper';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import { useEffect, useState } from "react";
import apiClient from "../api/apiClient";
import { toast } from "react-toastify";



const ResetPassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState()
  console.log(password);
  const { token } = useParams()

  const handleChange = (e) => {
    setPassword(e.target.value)
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await apiClient.patch(`/auth/resetPassword/${token}`, { password: password });
      // console.log(data);
      if (data.ok) {
        toast.success("Password Changed");
        navigate("/");
      } else {
        toast.error(data?.data.message); 
      }
    } catch (error) {
      console.error('Error:', error); 
      toast.error('An error occurred');
    }
  };


  return (
    <>
      <Grid className='d-flex justify-content-center align-items-center rounded-5' style={{ height: "100vh" }} container spacing={0} >

        <Grid className=' rounded-5' item xs={12} md={10} >
          <Paper elevation={20} sx={{ height: '90vh', borderRadius: "30px" }}>
            <Grid className='  rounded-5' sx={{ height: "90vh" }} item container spacing={0} xs={12} >
              <Grid className=' py-5 rounded-start-5 d-none d-sm-block h-100' item xs={12} md={6} sx={{ backgroundColor: "#e9faf9" }} >
                <Box className="d-flex justify-content-center p-4">
                  <img src={require("../assets/smalllogo.svg").default} alt="" />
                  <Typography sx={{ p: 3, color: "#00968f" }} variant="h5">
                    Care Feedback Solutions
                  </Typography>
                </Box>


                <Box className="d-flex justify-content-center p-4">
                  <div style={{ width: "20rem" }}>
                    <img className="img-fluid" src={require("../assets/homepage.svg").default} alt="" />
                  </div>
                </Box>

              </Grid>

              <Grid className=" py-5 px-md-5 px-3 " item xs={12} md={6}>
                <Typography className="pt-3 fw-bold" variant="h4" align="">
                  Choose Your Password
                </Typography>

                <Typography className="py-3" variant="body1" align="" sx={{ color: "rgb(154,157,157)" }}>
                  Please enter your email address and new password below
                </Typography>

                <form onSubmit={handleSubmit}>
                  <Grid container sx={{ py: 6 }} spacing={2}>
                    {/* <Grid item xs={12}>
                    <TextField
                      id="outlined-email"
                      label="Email"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <EmailIcon style={{ marginRight: '8px', color: 'rgba(0, 0, 0, 0.54)' }} />
                        ),
                      }}
                    />
                  </Grid> */}

                    <Grid item xs={12}>
                      <TextField
                        id="outlined-password"
                        label="Password"
                        type="password"
                        value={password}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <LockIcon style={{ marginRight: '8px', color: 'rgba(0, 0, 0, 0.54)' }} />
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        id="outlined-password"
                        label="Confirm Password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <LockIcon style={{ marginRight: '8px', color: 'rgba(0, 0, 0, 0.54)' }} />
                          ),
                        }}
                      />
                    </Grid>

                  </Grid>


                  <Box className="d-sm-flex justify-content-between  mt-3">
                    <div className="d-flex gap-2 align-items-center  my-sm-0 my-3">
                      <input type="checkbox" name="" id="rem" />
                      <label htmlFor="rem">
                        Remember Me
                      </label>
                    </div>
                    {/* <Link to={'/'} style={{ textDecoration: "none", color: "white" }}> */}
                    <button type="submit" variant="contained" className="btn_bg btn px-5 rounded-pill" >
                      Reset
                    </button>
                    {/* </Link> */}
                  </Box>
                </form>



              </Grid>
            </Grid>



          </Paper>

        </Grid>
      </Grid>
    </>
  );
}

export default ResetPassword;