import React from 'react';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function NegativeShowRoundForm() {

    function handleData(e) {
        e.preventDefault();
    }

    const currentDate = new Date().toISOString().split('T')[0];
    return (
        <div className='container'>

            <form onSubmit={handleData} className=' py-4'>
                <Link to='/showround-feedback' className='btn btn_bg'><ArrowBackIosIcon />Back</Link>

                <h3 className='h1Color text-center'>Please complete this form to submit your feedback</h3>

                <div className="row justify-content-center">

                    <div className="col-lg-7 col-12 mb-3">
                        <label for="calendar" class="form-label fw-bold">Date of Submit:</label>
                        <input type="date" class="form-control pointer" id="calendar" defaultValue={currentDate} />
                    </div>
                    <div className="col-lg-7 col-12 mb-3">
                        <label for="fname" class="form-label fw-bold">Rate your visit:</label>
                        <input type="name" min={1} max={5} class="form-control" id="fname" placeholder="Please enter rating of your visit" />
                    </div>
                    <div className="col-lg-7 col-12 mb-3">
                        <label for="mostFeedback" class="form-label fw-bold">What did you like the most?</label>
                        <textarea rows="4" class="form-control" id="mostFeedback" placeholder="What did you like the most?" />
                    </div>

                    <div className="col-lg-7 col-12 mb-3">
                        <label for="couldFeedback" class="form-label fw-bold">What could we improve?</label>
                        <textarea rows="4" class="form-control" id="couldFeedback" placeholder="What could we improve?" />
                    </div>
                    <div className="col-lg-7 col-12 mb-3">
                        <label for="comments" class="form-label fw-bold">Any other comments?</label>
                        <textarea className="form-control" id='comments' rows="4" placeholder='Please enter your feedback here'></textarea>
                    </div>
                    <div className="col-lg-7 col-12">
                        <input type="submit" value="Submit" className='my-3 text-white customBtn' />
                    </div>
                </div>

            </form>
        </div>
    )
}

export default NegativeShowRoundForm
