import { AppBar, Avatar, Button, Grid, Hidden, IconButton, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu'
import { ArrowForward, NotificationAdd } from '@mui/icons-material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import Avatar from '@mui/material/Avatar';
import apiClient from '../api/apiClient';
import { MdOutlineMarkChatUnread } from 'react-icons/md';
import { List, ListItem, ListItemText } from '@mui/material';
import { RiLockPasswordLine } from 'react-icons/ri'; 
import CircularProgress from '@mui/material/CircularProgress';

import { UpdatePasswordModal } from '../components/UpdatePasswordModal';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { login1 } from '../redux/filterSlice';
// import NotificationsPopover from '../components/notification-bar/Notifications';
// import { setFilter, setFilterii } from '../redux/filterSlice';
import { IoIosNotificationsOutline } from "react-icons/io";
import { FaKey, FaUser, FaQuestionCircle, FaCogs } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';
import { logout } from '../redux/authSlice';
const limit = 10;


function Navbar({ handleDrawerToggle }) {
  const navigate = useNavigate()
  const userType = useSelector((state) => state.auth.userType);
  const dispatch = useDispatch()
  console.log(userType, "usertype in nav")

  const [modalShow, setModalShow] = useState(false);
  const location = useLocation()
  const [data, setdata] = useState([])
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [selectedLocation, setSelectedLocation] = useState('');
  const [userInfo, SetuserInfo]= useState("")
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);


  

  const fetchdata = async (e) => {
    try {
      setLoading(true);
  
      const result = await apiClient.get(`/location`);
      console.log(result, "result fetched");
  
      if (result?.data?.location) {
        setdata(result.data.location);
      } else {
        console.error("Unexpected response structure for location:", result);
      }
  
      if (result?.data?.count !== undefined) {
        setCount(result.data.count);
      } else {
        console.error("Unexpected response structure for count:", result);
      }
  
      const locationres = await apiClient.get(`/feedback?location`);
      console.log(locationres, "location response fetched");
  
      if (!locationres) {
        console.error("Failed to fetch location feedback data:", locationres);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Optionally, set an error state here to display an error message in the UI
      setError(true); // Assuming you have an error state to handle UI updates
    } finally {
      setLoading(false);
    }
  };

  
  const fetchUser = async (e) => {
    const result = await apiClient.get(`/user/user-info`);
    console.log(result, "result");
    SetuserInfo(result?.data);
    // setdata(result.data.user)
    // setCount(result?.data.count);
  }

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleLocationChange = async (event) => {
    const selectedValue = event.target.value;
    // console.log(selectedValue, "selected valddddd")

    setSelectedLocation(selectedValue);
    dispatch(login1({ filter: selectedValue }))
    // console.log("llll", "selected val")
  };


  useEffect(() => {
    fetchdata()
    fetchUser()
  }, [page]);
  
  console.log(userInfo, "userInfo");


  // const path = location.pathname
  // .split("/")
  // .filter(Boolean) // Remove empty elements
  // .map(word => word.charAt(0).toUpperCase() + word.slice(1))
  // .join(" ")
  // .replace(/-/g, ' ')
  // .replace(/(\s|^)[a-z]/g, match => match.toUpperCase());


  //     const [userinfo, Setuserinfo] = useState()
  // const location=useLocation()
  // const path=location.pathname.replace("-", " ").substring(1).replace("inovice","invoices").replace("booking","bookings").toLocaleUpperCase() 
  // const path = location.pathname.split("/")[1].replace("inovice", "invoices").replace("-", " ").replace("booking", "bookings").toLocaleUpperCase()
  const path = location.pathname.split("/")[1]
  .replace("inovice", "invoices")
  .replace("-", " ")
  .replace("booking", "bookings")
  .toLocaleUpperCase()
  .replace(" MEETING-DETAILS", " MEETING DETAILS"); // This removes the hyphen between MEETING and DETAILS

  console.log(path, "jik")


  // const fetchData = async ()=>{
  // const result = await apiClient.get("/users/my-info")
  // Setuserinfo(result.data.result)
  // }

  // useEffect(() => {
  //  fetchData()
  // }, []);

  return (


    <Box className="" >

      <AppBar className='' position="fixed" sx={{ background: "#00a098", boxShadow: "0" }}>
        <Toolbar className='' style={{ display: 'flex', justifyContent: 'space-between' }} >
          <Box className=' w-100 d-flex  align-items-center  justify-content-between nav-bg nav-responsive'>
       
       {/* select location */}
              {/* {(userType === "AD" || userType === "SU") && ( */}
            {/* <Box className=" ms-md-5 ps-md-5 mb-0 w-100 d-flex justify-content-center align-items-center">
                <div style={{ maxWidth: "300px" }}>
                  <select
                    name="location"
                    id="location"
                    className='form-select text-white'
                    style={{ backgroundColor: "rgb(0,160,152)" }}
                    onChange={handleLocationChange}
                    value={selectedLocation}>
                    <option value="noLocation" selected  >
                      Select location
                    </option>
                    {data?.map((location, index) => (
                      <option key={index} value={location._id}>
                        {location.loc_no} {location.location}
                      </option>
                    ))}
                  </select>
                </div>


              <h5 className=' my-0 mx-3'>  {path}</h5>

            </Box> */}
                <InfiniteScrollList
                handleLocationChange={handleLocationChange}
                selectedLocation={selectedLocation}
                path={path}
                />

{/* ---------------------------- */}

            <div className='m-1 d-flex  align-items-center flex-wrap  me-2'>




              {/* <NotificationsPopover /> */}





              {/* <Button
                href="#contained-buttons"
              >
              <MdOutlineMarkChatUnread style={{color:"black", fontSize:"25px"}}/>
              </Button> */}
              {/* <span  onClick={()=>{

              }} className='ps-2'>
                {userInfo?.name}
              </span> */}

<div className='d-flex flex-wrap'>


  

<div className="dropdown d-flex align-items-center gap-3 m-2">
      <span
        style={{ cursor: 'pointer' }}
        className="dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {userInfo?.name}
      </span>
      <Link to={"/admin/dashboard"}>
              {/* <Avatar className=' ' alt="Travis Howard" /> */}
              </Link>
      <ul className="dropdown-menu mt-4">
        
      {/* <li>
          <a
         
          className="dropdown-item" href="#">
            <FaUser style={{ marginRight: '8px' }} />
            Profile
          </a>
        </li>

         */}
        
        <li>
        {/* <Link to="/edit-manager" style={{textDecoration:'none'}}  state={userInfo}> */}
          <a
          style={{cursor:"pointer"}}
           onClick={() => setModalShow(true)}
          className="dropdown-item" >
            <FaUser style={{ marginRight: '8px' }} />
           Profile
          </a>
        {/* </Link> */}

        </li>




        <li>
          <a  
              onClick={() => {
                dispatch(login1(""))
                dispatch(logout())
                navigate('/')
              }}
          className="dropdown-item" href="#">
          <FiLogOut size={24} />
            Logout
          </a>
        </li>
      </ul>
    </div>


    <div className=' d-flex align-items-center'>
              {/* <span>

              </span> */}
              {/* <Avatar onClick={() => setModalShow(true)} alt="Remy Sharp" src={require("../assets/care log.svg")} /> */}


              <Hidden
                mdUp
              >

                <IconButton onClick={handleDrawerToggle}>
                  <MenuIcon style={{ color: "black" }} />
                </IconButton>
              </Hidden>
            </div>






</div>



{/* <button style={{color:'#00A098'}} className='btn shadow text-white border d-md-inline d-none '>Logout</button> */}



             
            </div>


         


          </Box>
        </Toolbar>
      </AppBar>
      {modalShow && <UpdatePasswordModal
      data={userInfo}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />}
    </Box>
  );
}

export default Navbar;



function InfiniteScrollList({ handleLocationChange, selectedLocation, path }) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const limit = 5;

  const fetchCoursesData = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const result = await apiClient.get(`/location/?page=${page}&limit=${limit}`);

      if (!result.ok) {
        console.log("Something went wrong while fetching data", result.problem);
        return;
      } else {
        setData(prevData => [...prevData, ...result.data.location]); 
        setCount(result.data.count); 
        setPage(prevPage => prevPage + 1);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); 
    }
  };

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (data.length < count && scrollTop + clientHeight >= scrollHeight - 1) {
      fetchCoursesData();
    }
  };

  useEffect(() => {
    fetchCoursesData(); // Initial data fetch
  }, []);

  return (
<Box className="ms-md-5 pt-2 pb-2 ps-md-5 mb-0 w-100 d-flex flex-wrap justify-content-center align-items-center">
  <Select
    // name="location"

      labelId="demo-simple-select1-label"
    id="demo-simple1-select"
    label="Location"
    className=" p-2"
    style={{
      backgroundColor: "rgb(0,160,152)",
      height: '50px',
      width: '200px',
      cursor: 'pointer',
      color: selectedLocation === "noLocation" ?  "black"  : "white", // White color when no location is selected
    }}
    onChange={handleLocationChange}
    value={selectedLocation || "noLocation"} 
    displayEmpty 
    MenuProps={{
      PaperProps: {
        onScroll: handleScroll,
        style: {
          maxHeight: "200px",
          overflowY: "auto",
        },
      },
    }}
  >
    <MenuItem value="noLocation" disabled>
      Select location
    </MenuItem>
    {data.map((location, index) => (
      <MenuItem key={index} value={location._id}>
        {location.loc_no} {location.location}
      </MenuItem>
    ))}
  </Select>

  <h5 className="my-0 mx-3 d-none d-sm-block ">{path}</h5>
  
  <h5 className="my-0 mx-3 my-2 d-block d-sm-none ">{path}</h5>


  {/* <h5 className="my-0 mx-3 ">{path}</h5> */}
</Box>

  );
}





// //////////////////////////////////////
// function AddCourseModal(props) {
//   const { id, onHide, fetchTutorData } = props;
//   console.log(id, "id in modal");
//   const [businessData, setBusinessData] = useState([])
//   const [page, setpage] = useState(1)
//   const [count, setCount] = useState(0);
//   const [course, SetCourse] = useState({
//     courses: [],
//   })
//   const handleChange = (e) => {
//     const { name, value, type, checked } = e.target;
//     if (type === 'checkbox') {
//       SetCourse(prevData => ({
//         ...prevData,
//         [name]: checked,
//         courses: checked ? [...prevData.courses, value] : prevData.courses.filter(course => course !== value)
//       }));
//     } else if (name === 'courses') {
//       // If the change is in courses selection
//       SetCourse(prevData => ({
//         ...prevData,
//         [name]: value
//       }));
//     } else {
//       // For other inputs
//       SetCourse(prevData => ({
//         ...prevData,
//         [name]: value
//       }));
//     }
//   };
//   const handleSubmit = async (e) => {
//     const businessIds = course.courses.map(item => item._id);
//     // e.preventDefault();
//     console.log(course, "tt");
//     // businessIds.filter(x => x).forEach((string, index) => {
//     //   course.append(`courses[${index}]`, string);
//     // });
//     console.log(businessData, "bs id");
//     const result = await apiClient.patch(`user/business-users/assign-courses/${id}`, { courses: businessIds });
//     if (!result.ok) {
//       toast.error("Something wrong while adding Courses")
//       return
//     }
//     toast.success('Courses added successfully');
//     props.fetchTutorData()
//   }
//   let limit = 10
//   const fetchCoursesData = async () => {
//     try {
//       const result = await apiClient.get(`/course/?&&page=${page}&limit=${limit}`);
//       // console.log(result, "rr");
//       if (!result.ok) {
//         console.log("something wrong while fetching user data", result.problem);
//         return;
//       } else {
//         setBusinessData([...businessData, ...result?.data.results]);
//         setCount(result?.data.count);
//         console.log("learner data", result?.data.results);
//         setpage(page + 1);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   const handleScroll = (event) => {
//     const { scrollTop, clientHeight, scrollHeight } = event.target;
//     if (businessData.length < count && scrollTop + clientHeight >= scrollHeight - 1) {
//       fetchCoursesData();
//     }
//   };
//   useEffect(() => {
//     // if (idUser) {
//     fetchTutorData()
//     // }
//     fetchCoursesData()
//   }, [id]);
//   return (
//     <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="model-border-radius z-100">
//       <Modal.Header className="bg-success-subtle model-border-radius ">
//         <div className="w-100">
//           <h5 className="text-nowrap  py-2 fw-bold text-center mb-0"> Add Courses</h5>
//         </div>
//       </Modal.Header>
//       <Modal.Body className="custom-body p-4 p-md-5">
//         <div className="d-flex align-items-center justify-content-center">
//           <div className=" col-lg-8 col-10">
//             <h5 className="text-center">
//               Add courses.
//             </h5>
//             <FormControl fullWidth>
//               <InputLabel id="demo-simple-select-label">Courses</InputLabel>
//               <Select
//                 labelId="demo-simple-select-label"
//                 id="demo-simple-select"
//                 multiple
//                 name="courses"
//                 label="assigned_businesses"
//                 onChange={handleChange}
//                 renderValue={(selected) => selected.map((item) => item.name).join(', ')}
//                 value={Array.isArray(course.courses) ? course.courses : []}
//                 MenuProps={{
//                   PaperProps: {
//                     onScroll: handleScroll,
//                     style: {
//                       maxHeight: "100px",
//                       overflowY: "auto",
//                     },
//                   },
//                 }}
//               >
//                 {businessData?.length > 0 && businessData?.map((business) => (
//                   <MenuItem key={business._id} value={business}>
//                     <Checkbox checked={Array.isArray(course.courses) && course.courses.some(item => item._id === business._id)} />
//                     {business.name}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
//             <div className="d-flex justify-content-center align-items-center gap-3 mt-4">
//               <button
//                 type="submit"
//                 className="btn btn-gray rounded-3 w-100"
//                 onClick={props.onHide}
//               >
//                 Cancel
//               </button>
//               <button
//                 type="submit"
//                 className="btn btn-green rounded-3 w-100"
//                 onClick={() => { props.onHide(); handleSubmit(props.id); }}
//               >
//                 Add
//               </button>
//             </div>
//           </div>
//         </div>
//       </Modal.Body>
//     </Modal>
//   );
// }

