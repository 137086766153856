import React from 'react'

export const Footer = () => {
  return (
    <>
    <footer className=' py-4 d-flex justify-content-center align-items-end  mt-5' style={{height:"25%"}} >
        <p className='text-center'>
        © Care Feedback Solutions Ltd. 2023
        </p>
    </footer>
    </>
  )
}
