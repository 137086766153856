import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CircularProgress, Grid } from '@mui/material';
import TableMui from '../mui/TableMui';
import { toast } from "react-toastify";
import apiClient from '../api/apiClient';
import { formatDate } from '../modules/helpers';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Paginate from '../mui/Paginate';
import { FaTrash } from 'react-icons/fa';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover, // Hover background color
    color: theme.palette.error.main, // Change icon color on hover
  },
}));
const limit = 10;
const ReqMetting = () => {
  const filter = useSelector((state) => state.filter?.filter);
  const [feedbackcount, setfeedbackcount] = useState("")
  const [loading, setloading] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0)
  const [error, setError] = useState(null);
  const [data, setdata] = useState([]);
  const {userType} = useSelector((state)=> state.auth )

  // const fetchdata1 = async () => {
  //   setloading(true);
  //   // let page = 1
  //   const result = await apiClient.get(`/req-metting?location=${filter || ""}&page=${page}&limit=${limit}`);
  //   // console.log(result, "staff bilal");
  //   setloading(false);
  //   // setdata(result?.data.getMeetingFeedback.map(item=>({...item,dateOfRequest:formatDate(item.dateOfRequest)})))
  //   // let limit = 11
  //   // let data = result?.data?.getMeetingFeedback?.map((item) => ({ ...item, dateOfRequest: formatDate(item.dateOfRequest) }))
  
  //   let data = result?.data?.feedback?.map((item) => ({ ...item, 
  //     fullName: `${item.firstName} ${item.lastName}`, // Concatenate firstName and lastName
  //     dateOfRequest: formatDate(item.dateOfRequest) }))
    
  //   let counts = result?.data;
  //   if (!count) setCount(result?.data.count);
  //   for (let index = 0; index < data?.length; index++) {
  //     let sr;
  //     if (page === 1) {
  //       sr = (page - 1) + (index + 1); // For page 1
  //     } else {

  //       sr = (page - 1) * limit + (index + 1); // For other pages
  //     }
  //     data[index].sr1 = sr
  //   }
  //   console.log(data,"staff bilal")
  //   setdata(data)

  // }
  // console.log('rLoc', typeof(filter))
  const handleDelete = async (id) => {
    try {
      // Make the delete request
      const response = await apiClient.delete(`/req-metting/${id}`);
  
      // Check if the response was successful
      if (response.status === 204) {
        toast.success("Feedback deleted successfully!");
      } else {
        // Handle the case where the deletion was not successful
        toast.error(response.data?.message || "Failed to delete feedback.");
      }
    } catch (error) {
      // Handle any errors that occurred during the request
      toast.error(`Error: ${error.message || "An error occurred while deleting feedback."}`);
    }
    fetchdata()
//    setdata((pre)=>
// pre.map((feedback)=>feedback._id.toString() !== id)  
//   )
  };
  
  const fetchdata = async () => {
    setloading(true);
    // seterror(null); // Reset error state before fetching data
  
    if (filter === "") {
      // If filter is empty, stop further execution
      console.log("Filter is empty, no API request will be made.");
      setdata([]);
      setfeedbackcount(0);
      setloading(false);
      return;
    }
  
    try {
      const result = await apiClient.get(`/req-metting?location=${filter || ""}&page=${page}&limit=${limit}`);
      console.log(result.data, "staff data");
  
      let fetchedData = result?.data?.getMeetingFeedback?.map((item) => ({
        ...item,
        fullName: `${item.firstName} ${item.lastName}`,
        dateOfRequest: formatDate(item.dateOfRequest),
      })) || [];
  
      let counts = result?.data || {};
      setfeedbackcount(counts || 0);
      setCount(counts.count);
  
      fetchedData = fetchedData.map((item, index) => ({
        ...item,
        sr1: page === 1 ? index + 1 : (page - 1) * limit + (index + 1),
      }));
  
      setdata(fetchedData);
    } catch (error) {
      console.error("Error fetching staff data:", error);
      // seterror("Failed to fetch data. Please try again later.");
    } finally {
      console.log('pagination-count', count);
      setloading(false);
    }
  };

  useEffect(() => {
    fetchdata()
  }, [filter,page]);

  // console.log(data, "data api")

  const handlePageChange = (event, page) => {
    setPage(page);
  };



  return (
    <div
    className=' mt-5 mt-md-0'
    
    >
      {/* <Box className="container test">
        <Grid container spacing={2}>

          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ maxWidth: 300, backgroundColor: "#FEFFD1", padding: 1 }}>
              <CardContent sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "12px" }}>
                <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width: "40px", height: "40px", backgroundColor: "#EAEC92" }} color="text.secondary" gutterBottom>
                  {feedbackcount?.count}
                </Typography>
                <Typography className='fs_10px custom-text' component="div">
                  Feedback Received
                </Typography>
              </CardContent>
            </Card>
          </Grid>


          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ maxWidth: 300, backgroundColor: "#C9F4D1", padding: 1 }}>
              <CardContent sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "12px" }}>
                <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width: "40px", height: "40px", backgroundColor: "#3CAB4F" }} color="text.secondary" gutterBottom>
                  {feedbackcount?.positiveFeedbackCount}
                </Typography>
                <Typography className='fs_10px custom-text' variant="h7" component="div">
                  Positive Feedback
                </Typography>
              </CardContent>
            </Card>
          </Grid>


          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ maxWidth: 300, backgroundColor: "#C9F4D1", padding: 1 }}>
              <CardContent sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "12px" }}>
                <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm white_space rounded' sx={{ width: "50px", height: "50px", backgroundColor: "#3CAB4F", fontSize: "11px" }} color="text.secondary" gutterBottom>
                  {feedbackcount?.positiveFeedbackCountPercentage} %
                </Typography>
                <Typography className='fs_10px custom-text' variant="h7" component="div">
                  Percentage Positive
                </Typography>
              </CardContent>
            </Card>
          </Grid>



          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ maxWidth: 300, backgroundColor: "#FEEBB4", padding: 1 }}>
              <CardContent sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "12px" }}>
                <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width: "40px", height: "40px", backgroundColor: "#fbbc05" }} color="text.secondary" gutterBottom>
                  {feedbackcount?.pendingFeedbackCount}
                </Typography>
                <Typography className='fs_10px custom-text' variant="h7" component="div">
                  Feedback Pending
                </Typography>
              </CardContent>
            </Card>
          </Grid>


          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ maxWidth: 300, backgroundColor: "#C9F4D1", padding: 1 }}>
              <CardContent sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "12px" }}>
                <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width: "40px", height: "40px", backgroundColor: "#3CAB4F" }} color="text.secondary" gutterBottom>
                  {feedbackcount?.completedFeedbackCount}
                </Typography>
                <Typography className='fs_10px custom-text' variant="h7" component="div">
                  Feedback Completed
                </Typography>
              </CardContent>
            </Card>
          </Grid>


          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ maxWidth: 300, backgroundColor: "#ffb3b3", padding: 1 }}>
              <CardContent sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "12px" }}>
                <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width: "40px", height: "40px", backgroundColor: "#ef5959" }} color="text.secondary" gutterBottom>
                  {feedbackcount?.overdueFeedbackCount}
                </Typography>
                <Typography className='fs_10px custom-text' variant="h7" component="div">
                  Feedback Overdue
                </Typography>
              </CardContent>
            </Card>
          </Grid>


        </Grid>
      </Box> */}

      <div className="row ">
        <div className="col-12 d-flex justify-content-end">
          <button class="btn customBtnColor 
          dropdown-toggle
          " 
          data-bs-toggle="dropdown" aria-expanded="false"
          type="button">
              Submit Meeting
          </button>
          <ul class="dropdown-menu">
          <Link style={{ textDecoration: "none", color: "white" }} className='' to="/add-request-meeting-feedback">
    <li><a class="dropdown-item" href="#">Request Meeting</a></li>
    </Link>
    
    <Link style={{ textDecoration: "none", color: "white" }} className='' to="/add-positive-care-meeting-feedback">

    <li><a class="dropdown-item" href="#">Care Meeting</a></li>
    </Link>
  
  </ul>
        </div>
      </div>

      <Box sx={{ my: 5 }}>

        {!loading?<TableMui
          styleTableThead={{
            '& th': {
              color: "white",
              backgroundColor: "#4dbdb7",
              fontWeight: "700",
              whiteSpace: "nowrap",
            },
          }}
          th={{
            // "location.loc_no": "Ref Number",
            "serial": "Ref Number",
            dateOfRequest: "Date Submitted",

            "type": "Type",
            // "manager.name": "Name",
            // "firstName + lastName": "Name",
            "fullName": "Name", 
            // "additionalFields.feedback": "Description of Feedback",
            // "dateOfRequest": "Due Date",
            Status: "Status",
          ...(userType !== 'MN' && { Delete: 'Delete' }) 
          }}
          td={data}
          // td={data.map(item => ({
          //   ...item,
          //   fullName: `${item.firstName} ${item.lastName}`, // Concatenate firstName and lastName
          // }))}
          customFields={[
            {
              name: "Delete",
              data: (value, item) => (
                <Box className="" style={{ width: "100px" }}>
                 
  
                 <StyledIconButton className='' onClick={()=> {
                  // console.log('assasa')
                  // alert(JSON.stringify(item._id))
                  handleDelete(item._id)
                  
                  }}>
        <FaTrash />
      </StyledIconButton>
  
      
  
                </Box>
  
              ),
            },
        //     {
        //       name:"sr1",
        //       data:(value,item) => (
        //         <>
        //              <Link style={{ textDecoration: "none" }} to={`/request-meeting-details/${item._id}`} state={'All'}>
         
        //         <span style={{fontSize:14,color:'#0D6EFD'}}>
                  
        //           {  "Rm" + value}
                  
        //           </span>
        //  </Link>
        //           {/* {
  
        //         } */}
        //         </>
  
  
        //       )
        //     },
            {
              name: "Status",
              data: (value, item) => (
                <Box className="" style={{ width: "100px" }}>
                
                <Link style={{ textDecoration: "none" }} to={`/request-meeting-details/${item._id}`} state={'All'}>


                  <Button
                    variant="contained"
                    // className='btn-pending'
                    style={{
                      // backgroundColor: "#FEBE1A",
                      textDecoration: "none",
                      position: "relative",
                      display: "inline-block",
                      color: "#fff",
                      padding: "8px",
                      // width: "100%",
                      // backgroundColor:
                      background:
                        item.status === "pending" ? "linear-gradient(to bottom, #fed56d, #FEB804)" :
                          item.status === "overdue" ? "linear-gradient(to bottom, #ff7272, #FF5252)" :
                            item.status === "completed" ? "linear-gradient(to bottom, #75b45e, #2FAF01)" :
                              ""
                    }}
                    >
                    <span
                      // className='btn-inner-pending'
                      style={{
                        display: "block",
                        color: "#fff",
                        width: "100%",
                        paddingTop: "6px",
                        paddingBottom: "6px",
                        background:
                          item.status === "pending" ? "linear-gradient(to top, #fed56d, #FEB804)" :
                            item.status === "overdue" ? "linear-gradient(to top, #ff7272, #FF5252)" :
                              item.status === "completed" ? "linear-gradient(to top, #75b45e, #2FAF01)" :
                                "",
                        minWidth: "120px"

                      }}

                    >
                      {item.status || "no status"}
                    </span>
                  </Button>
</Link>

                  {/* {item.Types === "type2" ? (
            ) : item.Types === "type3" ? (
              // Conditional Rendering: Pending Button
              <Button
                variant="contained"
                className='btn-pending'
                // style={{ backgroundColor: "#FEB804", textDecoration: "none" }}
                // onClick={() => {
                  // }}
                >
                  <span className='btn-inner-pending'>Pending</span>
                </Button>
              ) : (
                <Button
                variant="contained"
                className='btn-complete'
              >
                <span className='btn-inner-complete'>Complete</span>
              </Button>
                  )

                  } */}

                </Box>

              ),
            },
            {
              // name: "location.loc_no",
              name: "serial",
              data: (value, item) => (
                <Box className="d-flex gap-3 " style={{ width: "100px" }}>
                  <Link style={{ textDecoration: "none" }} to={`/request-meeting-details/${item._id}`}>
                    {/* {item.location.loc_no} */}
                    {   item.serialName}
                    </Link>


                </Box>

              ),
            },
            {
              // name: "location.loc_no",
              name: "type",
              data: (value, item) => (
                <Box className="d-flex gap-3 " style={{ width: "100px" }}>
                  <span >
                    {/* {item.location.loc_no} */}
                    {/* {item.type==='ReqMetting'?'Request  Meeting':item.type} */}

                    {item.type === 'ReqMetting' ? 'Request Meeting' : 
 item.type === 'RequestCareMetting' ? 'Request Care Meeting' : 
 item.type}

                  </span>


                </Box>

              ),
            }
          ]}

        />:<div className="d-flex justify-content-center align-items-center"><CircularProgress/></div> }


      </Box>
      <div className=" d-flex  justify-content-end my-3">
        <Paginate count={count} limit={limit} page={page} onChange={handlePageChange} />
      </div>


    </div>
  )
}

export default ReqMetting