import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TableMui from '../mui/TableMui'; // Adjust the path accordingly
import Typography from '@mui/material/Typography';
import { Card, CardContent, Grid } from '@mui/material';

const Overdue = () => {
  const data = [
    {
      number: "1",
      date: "David",
      feedback: "Negative",
      name: "herry",
      Description: "Really pleased with the home, looking after my relative really well. I am so pleased",
      Due: "12-2-23",
      Status: "Status",
    },
    {
      number: "2",
      date: "Alice",
      feedback: "Negative",
      name: "Bob",
      Description: "Another positive experience with the service. Everything is great!",
      Due: "13-2-23",
      Status: "Status",
    },
    {
      number: "3",
      date: "John",
      feedback: "Negative",
      name: "Eva",
      Description: "Great experience so far. Keep up the good work!",
      Due: "14-2-23",
      Status: "Status",
    },
    {
      number: "4",
      date: "Sarah",
      feedback: "Negative",
      name: "Alex",
      Description: "Exceptional service and care. Highly recommended!",
      Due: "15-2-23",
      Status: "Status",
    },
    {
      number: "5",
      date: "Mia",
      feedback: "Negative",
      name: "Chris",
      Description: "Extremely satisfied with the facilities and staff. Thank you!",
      Due: "16-2-23",
      Status: "Status",
    },
  ];

  return (
    <div>
       <Grid container spacing={2}>

<Grid item xs={12} sm={6} md={4}>
 
    <Card sx={{ maxWidth: 300, backgroundColor:"#FFE7D1", padding:1 }}>
    <CardContent sx={{display:"flex", justifyContent:"start", alignItems:"center", gap:"12px"}}>
      <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width:"40px", height:"40px", backgroundColor:"#FFC793"  }} color="text.secondary" gutterBottom>
        34
      </Typography>
      <Typography className='white_space fw-bold custom-text' variant="h7" component="div">
      Relative Feedback Recieved 
      </Typography>
    </CardContent>
  </Card>
 
</Grid>

<Grid item xs={12} sm={6} md={4}>

  <Card sx={{ maxWidth: 300, backgroundColor:"#DFF8E3", padding:1 }}>
    <CardContent sx={{display:"flex", justifyContent:"start", alignItems:"center", gap:"12px"}}>
      <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width:"40px", height:"40px", backgroundColor:"#96E9A4"  }} color="text.secondary" gutterBottom>
        6
      </Typography>
      <Typography className='white_space fw-bold custom-text' variant="h6" component="div">
      Positive Relative Feedback Recieved 
      </Typography>
    </CardContent>
  </Card>
  
</Grid>

<Grid item xs={12} sm={6} md={4}>

  <Card sx={{ maxWidth: 300, backgroundColor:"#FEFFD1", padding:1 }}>
    <CardContent sx={{display:"flex", justifyContent:"start", alignItems:"center", gap:"12px"}}>
      <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width:"40px", height:"40px", backgroundColor:"#E9EB92"  }} color="text.secondary" gutterBottom>
        1 %
      </Typography>
      <Typography className='white_space fw-bold custom-text' variant="h6" component="div">
         Percentage Feedback
                </Typography>
    </CardContent>
  </Card>


</Grid>


</Grid>
      <Box sx={{ my: 5 }}>
        <TableMui
          styleTableThead={{
            '& th': {
              color: "white",
              backgroundColor: "#4dbdb7",
              fontWeight: "700",
              whiteSpace: "nowrap",
            },
          }}
          th={{
            number: "Ref Number",
            date: "Date Submitted",
            feedback: "Positive/Negative",
            name: "Name",
            Description: "Description of Feedback",
            Due: "Due Date",
            Status: "Status",
          }}
          td={data}
          customFields={[
            {
              name: "Status",
              data: (value, item) => (
                <Box className="d-flex gap-3" style={{width:"100px"}}>
                  {/* Conditional Rendering: Positive Button */}
                  {(
                    <Button
                    variant="contained"
                    className='btn-overdue'
                    // style={{ backgroundColor: "#FF5252", textDecoration: "none" }}
                    // onClick={() => {
                    //   // Handle Overdue button click
                    // }}
                  >
                    <span className='btn-inner-overdue'>Due</span>
                  </Button>
                  )}
                </Box>
              ),
            },
            {
              name: "feedback",
              data: (value, item) => (
                // Custom logic for rendering the "Details" field
                <Typography sx={{color:"red"}}>{item.feedback}</Typography>
              ),
            },
          ]}
        />
      </Box>
    </div>
  );
};

export default Overdue;
