import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, FormControl, InputLabel, MenuItem, Select, IconButton  ,InputAdornment} from "@mui/material";
import apiClient from "../api/apiClient";
import { toast } from "react-toastify";
import Chip from "@mui/material/Chip";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useLocation } from "react-router-dom";
import { Add, Remove } from '@mui/icons-material';




{
  /* <MenuItem key={index} value={location._id}>
{location.loc_no} {location.location}
</MenuItem>
 */
}

const limit = 10;
const EditManager = () => {
  // const [role, setrole] = useState('');
  const { state } = useLocation();
  //   alert(  JSON.stringify(location) )
  // console.log(state.location[0],'kkkkkkkkkkkkkkkkkkkkk')
  const navigate = useNavigate();

  const [data, setdata] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [personName, setPersonName] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(
    state?.location[0]
  );
  const [multipleSelectedLocation,setMultipleSelectedLocation] = useState(
    state?.location
  );
  const [emailperiodWeek, setEmailPeriodWeek] = useState("");
  const multiLoc = state.location.map((el) => {
    return el;
  });

  let initalState = {
    role: state.role,
    singleLocation: state?.location[0]?._id,
    multipleLocations: [],
    name: state.name,
    emailperiodWeek: state?.emailperiodWeek,
    email: state.email,
    contact: state.contact,
    password: "",
  };
  const [manger, setmanger] = useState(initalState);

  const handleLocationChange = async (event) => {
    const selectedValue = event.target.value;
    // console.log(selectedValue, "selected valddddd")

    setSelectedLocation(selectedValue);

    // console.log("llll", "selected val")
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    // setmanger({...manger,location:multipleLocations||singleLocation})
    console.log(manger, "manger");
    // alert(JSON.stringify(manger));

    // manger?.multipleLocations.length>0?manger?.multipleLocations:manger?.singleLocation
    const result = await apiClient.put("/user/user",
    {...manger,location:manger?.multipleLocations.length>0?manger?.multipleLocations:manger?.singleLocation})
    if(!result.ok) return toast.error("Failed!")
    toast.success(result.data?.message || "Manager Updated successfully")

     navigate("/manager")
  };

  const handleSingleLocationChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedLocation(selectedValue);
    

    // console.log(e.target.value,'ddasaasasasasd')
    setmanger({ ...manger, singleLocation: event.target.value });
  };

  const handleMultipleLocationsChange = (e) => {
    // alert(JSON.stringify(e.target.value))
    console.log(e.target.value)
    // setSMuleelectedLocation(multipleSelectedLocation);
    setMultipleSelectedLocation(e.target.value)
    setmanger({ ...manger, multipleLocations: e.target.value });
  };

  const handleChange = (name, value) => {
    console.log("Selected Value:", value);
    if (name === "emailperiodWeek") {
      value = parseInt(value);
    }
    setmanger((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const fetchdata = async (e) => {
    // e.preventDefault();
    // const result = await apiClient.get(`/location?page=${page}&limit=${limit}`);
    const result = await apiClient.get(`/location`);
    console.log(result.data, "location res");
    setdata(result.data.location);
    setCount(result?.data.count);
  };

  console.log(data, "data");

  useEffect(() => {
    fetchdata();
  }, [page]);

  return (
    <div>
      <Box sx={{ py: 3 }}>
        <Link to="/manager">
          <Button
            variant="contained"
            className="btn btn_bg"
            startIcon={<ArrowBackIosIcon />}
          >
            Back
          </Button>
        </Link>
      </Box>

      <form className="container " onSubmit={handelSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Role</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={manger.role}
                label="Role"
                name="role"
                required
                // onChange={handleChange}
                onChange={(e) => handleChange("role", e.target.value)}
              >
                <MenuItem value="SU">Super User</MenuItem>
                <MenuItem value="MN">Manager</MenuItem>
              </Select>
            </FormControl>
          </Grid>

        

          {manger.role === "MN" && (
            <Grid item xs={12} md={5}>
              <InfiniteScrollList
                selectedLocation={selectedLocation}
                handleLocationChange={handleSingleLocationChange}
                role={"MN"}
                singleLoc={manger.singleLocation}
              />
            </Grid>
          )}


{manger.role === "SU" && (
            <Grid item xs={12} md={5}>
              <InfiniteScrollList
                selectedLocation={multipleSelectedLocation}
                handleLocationChange={handleMultipleLocationsChange}
                role={"SU"}
              />
            </Grid>
          )}



          {/* {manger.role === "MN" && (
            <Grid item xs={12} md={5}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-location">
                  Location
                </InputLabel>
                <Select
                  labelId="demo-simple-select-location"
                  id="demo-simple-select-location"
                  // value={manger.location}
                  value={manger.singleLocation}
                  label="Location"
                  required
                  // onChange={(e) => handleChange("location", e.target.value)}
                  onChange={handleSingleLocationChange}
                >
                  {data?.map((location, index) => (
                    <MenuItem key={index} value={location._id}>
                      {location.loc_no} {location.location}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )} */}

          {/* {manger.role === "SU" && (
            <Grid item xs={12} md={5}>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-chip-label">Location</InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={manger.multipleLocations}
                  onChange={handleMultipleLocationsChange}
                  // onChange={(e) => handleChange("location", e.target.value)}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Location" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        // alert(value);
                        <Chip key={value} label={value.location} />
                      ))}
                    </Box>
                  )}
                >
                  {Array.isArray(data) &&
                    data.map((location, index) => (
                      <MenuItem key={index} value={location}>
                        {location.loc_no} {location.location}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          */}

          <Grid item xs={12} md={5}>
            <TextField
              fullWidth
              type="text"
              id="outlined-basic"
              label="Name"
              variant="outlined"
              size="large"
              name="name"
              required
              value={manger.name}
              onChange={(e) => handleChange("name", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={5}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Email"
              type="email"
              variant="outlined"
              size="large"
              name="email"
              required
              value={manger.email}
              onChange={(e) => handleChange("email", e.target.value)}
              // onChange={handleChange}
            />
          </Grid>




          {manger.role === "MN" && (
  <Grid item xs={12} md={5}>
    <TextField
      fullWidth
      type="text"
      id="outlined-basic"
      value={`${manger.emailperiodWeek} Week${manger.emailperiodWeek > 1 ? 's' : ''}`}
      label="Email Notification Period"
      onChange={(e) => {
        const numberValue = parseInt(e.target.value.replace(/\D/g, ''), 10);
        const weekValue = isNaN(numberValue) ? 1 : Math.max(1, numberValue);
        handleChange("emailperiodWeek", weekValue);
      }}
      variant="outlined"
      size="large"
      inputProps={{ min: 1 }}
      name="name"
      required
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                handleChange("emailperiodWeek", Math.max(1, manger.emailperiodWeek - 1));
              }}
              edge="end"
            >
              <Remove />
            </IconButton>
            <IconButton
              onClick={() => {
                handleChange("emailperiodWeek", manger.emailperiodWeek + 1);
              }}
              edge="end"
            >
              <Add />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  </Grid>
)}




          {/* {manger.role === "MN" && (
            <Grid item xs={12} md={5}>
              <TextField
                fullWidth
                type="number"
                id="outlined-basic"
                value={manger.emailperiodWeek}
                label="Email Notification Period"
                onChange={(e) =>
                  handleChange("emailperiodWeek", e.target.value)
                }
                variant="outlined"
                size="large"
                inputProps={{ min: 1 }}
                name="name"
                required
              />
            </Grid>
          )} */}

          <Grid item xs={12} md={5}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Contact"
              type="number"
              variant="outlined"
              size="large"
              name="contact"
              required
              value={manger.contact}
              onChange={(e) => handleChange("contact", e.target.value)}
              inputProps={{ min: 0 }}
            />
          </Grid>
        </Grid>
        <Box sx={{ py: 3 }}>
          <button className="btn btn_bg" type="submit">
            Submit
          </button>
        </Box>
      </form>
    </div>
  );
};

export default EditManager;



function InfiniteScrollList({ handleLocationChange, selectedLocation, role ,singleLoc }) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // Track if the menu is open

  const limit = 5;

  const fetchCoursesData = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const result = await apiClient.get(
        `/location/?page=${page}&limit=${limit}`
      );

      if (!result.ok) {
        console.log("Something went wrong while fetching data", result.problem);
        return;
      }

      setData((prevData) => [...prevData, ...result.data.location]);
      setCount(result.data.count);
      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (!loading && data.length < count && scrollTop + clientHeight >= scrollHeight - 5) {
      fetchCoursesData();
    }
  };

  useEffect(() => {
    fetchCoursesData();
  }, []);

  return (
    <>
      {role === "SU" && (
        <>
          {data.length > 0 && (
            <FormControl fullWidth>
              <InputLabel id="demo-multiple-chip-label">Location</InputLabel>
              <Select
                name="location"
                id="location"
                autoWidth={false}
                label="Location"
                multiple
                style={{
                  width: "100%",
                  cursor: "pointer",
                }}
                onChange={handleLocationChange}
                value={selectedLocation || []}
                displayEmpty
                onOpen={() => setMenuOpen(true)}
                onClose={() => setMenuOpen(false)} 
                MenuProps={{
                  PaperProps: {
                    onScroll: handleScroll,
                    style: {
                      maxHeight: "200px",
                      overflowY: "auto",
                    },
                  },
                }}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value?._id} label={value?.location} />
                    ))}
                  </Box>
                )}
                input={
                  <OutlinedInput id="select-multiple-chip" label="Location" />
                }
              >
                <MenuItem value="noLocation" disabled>
                  Select location
                </MenuItem>
                {data.map((location, index) => (
                  <MenuItem key={index} value={location}>
                    {location?.loc_no} {location?.location}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </>
      )}

      {role === "MN" && (
    <>
    {
data.length >0 && (
  <FormControl fullWidth>
          <InputLabel id="demo-multiple2-chip-label">Location</InputLabel>
          <Select
            name="location"
            id="location"
            label="Location"
            className=""
            style={{
              width: "100%",
              cursor: "pointer",
            }}
            onChange={handleLocationChange}

            value={singleLoc}
            displayEmpty
            onOpen={() => setMenuOpen(true)} // Set menu open state
            onClose={() => setMenuOpen(false)} // Set menu close state
            MenuProps={{
              PaperProps: {
                onScroll: handleScroll,
                style: {
                  maxHeight: "200px",
                  overflowY: "auto",
                },
              },
            }}
          >
            <MenuItem value={selectedLocation?._id} >
            {selectedLocation?.loc_no} {selectedLocation?.location}
            </MenuItem>


            {data.map((location, index) => (
              <MenuItem key={index} value={location?._id}>
                {location?.loc_no} {location?.location}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
)}
        </>
    )}
    </>
  );
}

