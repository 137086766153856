import React, { useState } from 'react';

const TextDisplay = ({ text, lines = 2 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Calculate the maximum number of characters to show based on the number of lines
  const getMaxLength = () => {
    // Adjust this value as needed to better fit your text
    const avgCharsPerLine = 40;
    return lines * avgCharsPerLine;
  };

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const maxLength = isExpanded ? text.length : getMaxLength();
  const displayedText = text.length > maxLength ? text.substring(0, maxLength) : text;

  return (
    <div>
      <p style={{ margin: 0 }}>
        {displayedText}
        {text.length > getMaxLength() && (
          <span
            style={{ color: 'blue', cursor: 'pointer', marginLeft: '5px' }}
            onClick={handleToggle}
          >
            {isExpanded ? 'Show Less' : 'Show More'}
          </span>
        )}
      </p>
    </div>
  );
};

export default TextDisplay;
