import { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function ProtectedRoute({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, userType } = useSelector((state) => state.auth);

//   const redirectToDashboard = (userType, dashboardPath) => {
//     if (!location.pathname.includes(userType)) {
//       navigate(dashboardPath);
      
//     }
//   };

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/"); 
    } 
    // else {
    //     navigate("/dashboard");
    //     console.log(location.pathname,'johnsdsds')
    // }
    // if (userType) {
    //   switch (userType) {
    //     case "A":
    //       redirectToDashboard("admin", "/dashboard");
    //       break;
    //     case "R":
    //       redirectToDashboard("restaurant", "/restaurant/dashboard");
    //       break;
    //     default:
    //       break;
    //   }
    // }
  }, [isLoggedIn, userType, location.pathname, navigate]);

  return isLoggedIn ? children : null; // Return children only if logged in
}
