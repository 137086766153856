import React, { useEffect, useLayoutEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CircularProgress, Grid } from '@mui/material';
import DashboardStackBarChart from '../components/DashboardStackBarChart'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chart.js/auto'
import { Bar, Line } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import apiClient from '../api/apiClient';
import { useSelector } from 'react-redux';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Home = () => {

  const filter = useSelector((state) => state.filter?.filter);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filterData, setFilterData] = useState("")
  const [selectedStatus, setSelectedStatus] = useState('');
  const [data, setdata] = useState([]);

  const [feedbackSummary,setFeedbackSummary] = useState([])
  const [showroundSummary,setShowroundSummary] = useState([])

  const resetFilters = () => {
    setFilterData("")
    setSelectedStatus('');
    setStartDate('');
    setEndDate('');
    setFeedbackSummary([])
    setShowroundSummary([])
    fetchdata(true);
    // setPage(1);
    // setData([])
  };



  const [showround, setshowround] = useState([])
  let { count, completedFeedbackCount, 
    positiveFeedbackCount, negativeFeedbackCount,
     overdueFeedbackCount, pendingFeedbackCount } = data;
  const [loading, setloading]= useState(false)
  // console.log(positiveFeedbackCount, count, "ppopopopo")
// console.log(showround, 'positiveFeedbackCount')
  const data5 = {
    labels: ['Feedbacks', 'Positive Feedback', 'Negative Feedback', 'Completed Feedback', 'Pending Feedback', 'Overdue Feedback', 'Showround Feedback'],

    datasets: [
      {
        label: 'No of Feedbacks',
        data: [count, positiveFeedbackCount, negativeFeedbackCount, completedFeedbackCount, pendingFeedbackCount, overdueFeedbackCount, showround.count],
        backgroundColor: '#E13881',
        borderColor: '#E13881',
        borderWidth: 1,
        fill: false,
      },
    ],
  };

  const options5 = {
    scales: {
      x: {
        type: 'category',
        beginAtZero: true,
        grid: {
          display: true,
        },
      },
      y: {
        beginAtZero: true,
        display: false,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      title: {
        display: true,
        color: 'gray',
        text: 'Total Response',
        position: 'top',
        font: {
          size: 16,
        },
      },
    },
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setFilterData(selectedValue);
    // console.log(filterData, "fff")
  };

  const handleStatusChange = (event) => {
    const status = event.target.value;
    setSelectedStatus(status);
    console.log(selectedStatus, "ssss")
  };
// console.log(filter, "location of Manager")
console.log("showRoundData",filterData)

function fillDatesBetween(dataArray, startDate, endDate) {
  // Helper function to get an array of dates within a range
  const getDateRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);
    const lastDate = new Date(endDate);
    while (currentDate <= lastDate) {
      dates.push(currentDate.toISOString().split('T')[0]); // Format as YYYY-MM-DD
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  // Generate the date range between startDate and endDate
  const dateRange = getDateRange(startDate, endDate);

  // Create a map from the existing data array for quick lookup
  const dataMap = new Map(dataArray.map(item => [item.date, item]));

  // Add missing dates with default values
  dateRange.forEach(date => {
    if (!dataMap.has(date)) {
      dataMap.set(date, {
        date: date,
        completed: 0,
        negative: 0,
        overdue: 0,
        pending: 0,
        positive: 0,
        showround: 0
      });
    }
  });

  // Convert the map to an array and sort by date
  const combinedArray = Array.from(dataMap.values());
  combinedArray.sort((a, b) => new Date(a.date) - new Date(b.date));

  return combinedArray;
}



function fillMissingDates(dataArray) {
  // Helper function to get an array of dates within a range
  const getDateRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dates.push(currentDate.toISOString().split('T')[0]); // Format as YYYY-MM-DD
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  // Get the current date and 30 days ago
  const today = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(today.getDate() - 30);

  // Generate the date range for the last 30 days
  const dateRange = getDateRange(thirtyDaysAgo, today);

  // Create a map from the existing data array for quick lookup
  const dataMap = new Map(dataArray.map(item => [item.date, item]));

  // Add missing dates with default values
  dateRange.forEach(date => {
    if (!dataMap.has(date)) {
      dataMap.set(date, {
        date: date,
        completed: 0,
        negative: 0,
        overdue: 0,
        pending: 0,
        positive: 0,
        showround: 0
      });
    }
  });

  // Convert the map to an array and sort by date
  const combinedArray = Array.from(dataMap.values());
  combinedArray.sort((a, b) => new Date(a.date) - new Date(b.date));

  return combinedArray;
}

function mergeSummaries(feedbackSummary = [], showroundSummary = []) {
  // Create a map for feedback summary based on date
  const feedbackMap = new Map(feedbackSummary.map(item => [item.date, { ...item }]));

  // Merge showround summary into the feedbackMap by date
  showroundSummary.forEach(showround => {
    const date = showround.date;

    if (feedbackMap.has(date)) {
      const entry = feedbackMap.get(date);
      // Merge showround data by summing relevant fields
      entry.showround = (entry.showround || 0) + (showround.pending || 0) + (showround.completed || 0) + (showround.overdue || 0);
    } else {
      // If the date doesn't exist in feedbackMap, add a new entry
      feedbackMap.set(date, {
        date: showround.date,
        showround: (showround.pending || 0) + (showround.completed || 0) + (showround.overdue || 0) // Sum showround values
      });
    }
  });

  // Convert the merged map back to an array
  const mergedArray = Array.from(feedbackMap.values());

  return mergedArray;
}




function combineSummaries(feedbackSummary = [], showroundSummary = []) {
  // Helper function to get the date 30 days ago
  const getThirtyDaysAgo = () => {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    return date.toISOString().split('T')[0]; // Return date in YYYY-MM-DD format
  };
  
  const thirtyDaysAgo = getThirtyDaysAgo();
  let filteredFeedbackSummary = [];
  let filteredShowroundSummary = [];

  // Apply filtering based on the `isDateFiltered` flag
  if (!data.isDatefiltered) {
    console.log('Date filter applied: True');
    filteredFeedbackSummary = feedbackSummary.filter(item => item.date >= thirtyDaysAgo);
    filteredShowroundSummary = showroundSummary.filter(item => item.date >= thirtyDaysAgo);
  } else {
    filteredFeedbackSummary = feedbackSummary;
    filteredShowroundSummary = showroundSummary;
  }

  // Merge the summaries using the `mergeSummaries` function
  const combined = mergeSummaries(filteredFeedbackSummary, filteredShowroundSummary);

  let arrayFilled = [];
  if (data.isDatefiltered) {
    // Return the merged data without adding missing dates
    arrayFilled = fillDatesBetween(combined,startDate,endDate)
  } else {
    // Fill missing dates and return the modified array
    arrayFilled = fillMissingDates(combined);
  }

  // Sort the final array by date
  arrayFilled.sort((a, b) => new Date(a.date) - new Date(b.date));

  console.log('combined', arrayFilled);
  return arrayFilled;
}

// Merging logic function that combines the two summaries


// function combineSummaries(feedbackSummary = [], showroundSummary = []) {
//   // Helper function to get the date 30 days ago
//   const getThirtyDaysAgo = () => {
//     const date = new Date();
//     date.setDate(date.getDate() - 30);
//     return date.toISOString().split('T')[0]; // Return date in YYYY-MM-DD format
//   };
//   const thirtyDaysAgo = getThirtyDaysAgo();
 
// let  filteredFeedbackSummary =[];
// let  filteredShowroundSummary =[];

//   if(!data.isDateFiltered){
//     alert('True isFilter');
//      filteredFeedbackSummary = feedbackSummary.filter(item => item.date >= thirtyDaysAgo);
//      filteredShowroundSummary = showroundSummary.filter(item => item.date >= thirtyDaysAgo);
//   }else{
//     filteredFeedbackSummary = feedbackSummary;
//   filteredShowroundSummary = showroundSummary;
// }



//   const feedbackMap = new Map(filteredFeedbackSummary.map(item => [item.date, { ...item }]));

//   filteredShowroundSummary.forEach(showround => {
//     const date = showround.date;

//     if (feedbackMap.has(date)) {
//       const entry = feedbackMap.get(date);
//       entry.showround = (entry.showround || 0) + (showround.pending || 0) + (showround.completed || 0) + (showround.overdue || 0); // Sum showround values
//     } else {
//       feedbackMap.set(date, {
//         date: showround.date,
//         showround: (showround.pending || 0) + (showround.completed || 0) + (showround.overdue || 0) // Sum showround values
//       });
//     }
//   });





//   const combined = Array.from(feedbackMap.values());
// let arrayFilled= []
// if(!data.isDateFiltered){
//    arrayFilled =  fillMissingDates(combined)
// }else{
//    arrayFilled =  combined;
// }


//    arrayFilled.sort((a, b) => new Date(a.date) - new Date(b.date));

//   console.log('combined', arrayFilled);
//   return arrayFilled;
// }


// function combineSummaries(feedbackSummary = [], showroundSummary = []) {
//   // Create a map for feedbackSummary for quick lookup
//   const feedbackMap = new Map(feedbackSummary.map(item => [item.date, { ...item }]));

//   showroundSummary.forEach(showround => {
//     const date = showround.date;

//     if (feedbackMap.has(date)) {
//       // Add showround data without modifying feedbackSummary fields
//       const entry = feedbackMap.get(date);
//       entry.showround = (entry.showround || 0) + (showround.pending || 0) + (showround.completed || 0) + (showround.overdue || 0); // Sum showround values
//     } else {
//       // Create new entry with only the showround field
//       feedbackMap.set(date, {
//         date: showround.date,
//         showround: (showround.pending || 0) + (showround.completed || 0) + (showround.overdue || 0) // Sum showround values
//       });
//     }
//   });

//   // Convert map back to array
//   const combined = Array.from(feedbackMap.values());

//   // Sort by date if needed
//   combined.sort((a, b) => new Date(a.date) - new Date(b.date));

//   console.log('combined', combined);
//   return combined;
// }

const fetchdata = async (reset = false) => {
  setShowroundSummary([])
  setFeedbackSummary([])

  try {
    setloading(true);
    // setError(false); // Reset error state before starting new request


    // const result = await apiClient.get(`/feedback?first_name=${reset ? '' : name}&location=${filter || ""}&status=${reset ? '' : selectedStatus}&startDate=${ reset ? '' : startDate}&endDate=${reset ? '' : endDate}&page=${page}&limit=${limit}`);



    const result = await apiClient.get(`/feedback?location=${filter || ""}&feedbackType=${ reset ? '' :filterData}&status=${ reset ? '' :selectedStatus}&startDate=${reset ? '' :startDate}&endDate=${reset ? '' :endDate}`);
    console.log(filterData, 'feedbackType john');


    if (filterData === 'showround' || filterData === '') {
      const showroundRes = await apiClient.get(`show-round?location=${filter || ""}&
        feedbackType=${  reset ? '' : filterData }&status=${reset ? '' :selectedStatus}&startDate=${reset ? '' :startDate}&endDate=${reset ? '' :endDate}`);
      console.log(showroundRes, "showround data");

      if (filterData === 'showround') {
        // Process showroundRes data if needed
        console.log('Processing showround data');
      }

      if (showroundRes?.data) {
        setshowround(showroundRes.data);
// alert(filterData.length)
if(filterData ==='showround' || filterData.length ===0 ){
  setShowroundSummary(showroundRes.data.showRoundSummary)
}

      } else {
        console.error("Unexpected response structure for showround:", showroundRes);
      }
    } else {
      setshowround([]);
    }

    if (result?.data) {
      setdata(result.data);
      setFeedbackSummary(result.data.feedbackSummary)

    } else {
      console.error("Unexpected response structure for feedback:", result);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    // setError(true); // Set error state to handle UI updates
  } finally {
    setloading(false);
  }
};


  // useEffect(()=>{
  //   console.log('martin filter',filter)
    
  //   setdata([]);
  // },[])


  useEffect(() => {
    fetchdata()
  }, [filter, filterData, selectedStatus]);

  // console.log(data, "data")

  return (
    <div  className='mt-5 mt-md-0' >
      <Box className="container ">
        <Grid container spacing={2}>

          <div className='ps-3 row w-100 align-items-end      ' >

            <Grid className='' item xs={12} md={8}>
              
              
              <label htmlFor=""> Date Range:</label>
              <div className="d-flex  flex-column flex-md-row gap-2 mb-2">
                <input type="date" className='form-control' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                <span className='p-2'>to</span>
                <input type="date" className='form-control' min={startDate} value={endDate} onChange={(e) => setEndDate(e.target.value)} />
         
         
               <Grid item xs={12} md={3} className=" d-flex align-items-center  gap-2 ">
       

       
          <Button 
          style={{backgroundColor:"#00A098" ,  color:'white', border:"none" }}
          className="button-normal-case"
          variant="primary" onClick={() => fetchdata()}>Search</Button>
       
          <Button 
            className="button-normal-case"

          style={{ backgroundColor: "gray", color:'white', border:"none"}}
          
          onClick={resetFilters}>Reset</Button>


        </Grid>
                {/* <button className='btn btn-secondary' onClick={() => fetchdata(true)}>Search</button> */}
              
              </div>



            </Grid>

            <div className='col-md-6 p-2 '>
              <select name="" className='form-select' id="" onChange={handleSelectChange}  value={filterData}>
                <option value="" selected disabled>Select Type</option>
                <option value="">All</option>
                <option value="relative">Relative</option>
                <option value="resident">Resident</option>
                <option value="hcp">HCP</option>
                <option value="vistor">Visitor</option>
                <option value="staff">Staff</option>
                <option value="showround">Showround</option>
              </select>
            </div>

            <div className='col-md-6 p-2'>
              <select name="" className='form-select' id="" onChange={handleStatusChange}  value={selectedStatus}>
                <option value="" selected disabled>Select Feedback</option>
                <option value="">All</option>
                <option value="completed">Feedback completed</option>
                <option value="pending">Feedback pending</option>
                <option value="overdue">Feedback overdue</option>
                {/* <option value="positive">Positive feedback</option>
                <option value="negative">Negative feedback received</option> */}
              </select>
            </div>

          </div>

          <Grid item xs={12} sm={6} md={4}>
            <Link style={{ textDecoration: "none" }}>
              {/* <Link to={"/feedback-complete"} style={{textDecoration:"none"}}> */}
              <Card sx={{ maxWidth: 300, backgroundColor: "#b3eec3", padding: 1 }}>
                <CardContent sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "12px" }}>
                  <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width: "40px", height: "40px", backgroundColor: "#03bd5e" }} color="text.secondary" gutterBottom>
                    {data?.completedFeedbackCount?data?.completedFeedbackCount:0}
                  </Typography>
                  <Typography className='white_space fw-bold custom-text' variant="h7" component="div">
                    Feedback Completed
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            {/* <Link to={"/feedback-pending"} style={{textDecoration:"none"}}> */}
            <Link style={{ textDecoration: "none" }}>

              <Card sx={{ maxWidth: 300, backgroundColor: "#feebb4", padding: 1 }}>
                <CardContent sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "12px" }}>
                  <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width: "40px", height: "40px", backgroundColor: "#fbbc05" }} color="text.secondary" gutterBottom>
                    {data?.pendingFeedbackCount?data?.pendingFeedbackCount:0}
                  </Typography>
                  <Typography className='white_space fw-bold custom-text' variant="h6" component="div">
                    Feedback Pending
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            {/* <Link to={"/feedback-overdue"} style={{textDecoration:"none"}}> */}
            <Link style={{ textDecoration: "none" }}>

              <Card sx={{ maxWidth: 300, backgroundColor: "#ffb3b3", padding: 1 }}>
                <CardContent sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "12px" }}>
                  <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width: "40px", height: "40px", backgroundColor: "#ef5959" }} color="text.secondary" gutterBottom>
                    {data?.overdueFeedbackCount?data?.overdueFeedbackCount:0}
                  </Typography>
                  <Typography className='white_space fw-bold custom-text' variant="h6" component="div">
                    Feedback Overdue
                  </Typography>
                </CardContent>
              </Card>
            </Link>

          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            {/* <Link to={"/positive-recieved-feedback"} style={{textDecoration:"none"}}> */}
            <Link style={{ textDecoration: "none" }}>

              <Card sx={{ maxWidth: 300, backgroundColor: "#b3eec3", padding: 1 }}>
                <CardContent sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "12px" }}>
                  <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width: "40px", height: "40px", backgroundColor: "#03bd5e" }} color="text.secondary" gutterBottom>
                    {data?.positiveFeedbackCount?data?.positiveFeedbackCount:0}
                  </Typography>
                  <Typography className='white_space fw-bold text-wrap custom-text' variant="h6" component="div">
                    Positive feedback received
                  </Typography>
                </CardContent>
              </Card>
            </Link>

          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            {/* <Link to={"/negative-feedback-recieved"} style={{textDecoration:"none"}}> */}

            <Link style={{ textDecoration: "none" }}>

              <Card sx={{ maxWidth: 300, backgroundColor: "#ffb3b3", padding: 1 }}>
                <CardContent sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "12px" }}>
                  <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width: "40px", height: "40px", backgroundColor: "#ef5959" }} color="text.secondary" gutterBottom>
                    {data?.negativeFeedbackCount?data?.negativeFeedbackCount:0}
                  </Typography>
                  <Typography className='white_space fw-bold text-wrap custom-text' variant="h6" component="div">
                    Negative feedback received
                  </Typography>
                </CardContent>
              </Card>
            </Link>

          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            {/* <Link to={"/showround-feedback"} style={{textDecoration:"none"}}> */}
            <Link style={{ textDecoration: "none" }}>

              <Card sx={{ maxWidth: 300, backgroundColor: "#feebb4", padding: 1 }}>
                <CardContent sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "12px" }}>
                  {filterData=='showround'||filterData==''?<Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width: "40px", height: "40px", backgroundColor: "#fbbc05" }} color="text.secondary" gutterBottom>
                    {showround?.count?showround?.count:0}
                  </Typography>:
                  <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width: "40px", height: "40px", backgroundColor: "#fbbc05" }} color="text.secondary" gutterBottom>
                    0
                  </Typography>}
                  <Typography className='white_space fw-bold text-wrap custom-text' variant="h6" component="div">
                    Showround feedback received
                  </Typography>
                </CardContent>
              </Card>
            </Link>

          </Grid>

        </Grid>




        <div className="col-12  py-5" >
          <div className='p-md-4 rounded-4 bg-white w-100' style={{ minHeight: "300px" }}>
            {!loading?
            <DashboardStackBarChart 
            dataSummary={combineSummaries(feedbackSummary,showroundSummary)}
            isFiltered={{}}
            filteredData={{}}
            // dataSummary={feedbackSummary}

            className='w-100 h-100' 
            />:
          <div className="d-flex justify-content-center align-items-center"><CircularProgress/></div>  
          }
          </div>
        </div>
      </Box>



    </div>
  )
}

export default Home