import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CircularProgress, Grid } from '@mui/material';
import TableMui from '../mui/TableMui';
import apiClient from '../api/apiClient';
import { formatDate } from '../modules/helpers';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';


const ReqMetting = () => {
  const filter  = useSelector((state) => state.filter?.filter);
  const [feedbackcount, setfeedbackcount] = useState("")



  const [data, setdata] = useState([]);
  const [load, setload] = useState(false);

  const fetchdata = async()=>{
    setload(true);
    const result = await apiClient.get(`/req-metting?location=${filter||""}`);
    console.log(result , "staff data");
    setload(false);
    // setdata(result?.data.getMeetingFeedback.map(item=>({...item,dateOfRequest:formatDate(item.dateOfRequest)})))
    let page=1
    let limit=11
    let data=result?.data?.getMeetingFeedback?.map((item)=>({...item,dateOfRequest:formatDate(item.dateOfRequest)}))
    let counts = result?.data;
    setfeedbackcount(counts)
    for (let index = 0; index < data?.length; index++) {
     
      let sr;
      if (page === 1) {
          sr = (page - 1) + (index + 1); // For page 1
      } else {

          sr = (page - 1) * limit+ (index + 1); // For other pages
      }
      data[index].sr1=sr
      
    }
    console.log(data)
    setdata(  data)

  }
useEffect(() => {
  fetchdata()
}, [filter]);

console.log(data, "data api")




  
  return (
    <div>
<Box className="container ">
<Grid container spacing={2}>
  
 <Grid item xs={12} sm={6} md={4}>
<Card sx={{ maxWidth: 300, backgroundColor:"#FEFFD1", padding:1 }}>
      <CardContent sx={{display:"flex", justifyContent:"start", alignItems:"center", gap:"12px"}}>
        <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width:"40px", height:"40px", backgroundColor:"#EAEC92"  }} color="text.secondary" gutterBottom>
        {feedbackcount?.count}
        </Typography>
        <Typography className='fs_10px custom-text' component="div">
        Feedback Received 
        </Typography>
      </CardContent>
    </Card>
</Grid>


<Grid item xs={12} sm={6} md={4}>
    <Card sx={{ maxWidth: 300, backgroundColor:"#C9F4D1", padding:1 }}>
      <CardContent sx={{display:"flex", justifyContent:"start", alignItems:"center", gap:"12px"}}>
        <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width:"40px", height:"40px", backgroundColor:"#3CAB4F"  }} color="text.secondary" gutterBottom>
        {feedbackcount?.positiveFeedbackCount}
        </Typography>
        <Typography className='fs_10px custom-text' variant="h7" component="div">
        Positive Feedback
          </Typography>
      </CardContent>
    </Card>
</Grid>


<Grid item xs={12} sm={6} md={4}>
    <Card sx={{ maxWidth: 300, backgroundColor:"#C9F4D1", padding:1 }}>
      <CardContent sx={{display:"flex", justifyContent:"start", alignItems:"center", gap:"12px"}}>
      <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm white_space rounded' sx={{ width:"50px", height:"50px", backgroundColor:"#3CAB4F", fontSize:"11px"  }} color="text.secondary" gutterBottom>
        {feedbackcount?.positiveFeedbackCountPercentage} %
        </Typography>
        <Typography className='fs_10px custom-text' variant="h7" component="div">
        Percentage Positive    
        </Typography>
      </CardContent>
    </Card>
</Grid>



<Grid item xs={12} sm={6} md={4}>
    <Card sx={{ maxWidth: 300, backgroundColor:"#FEEBB4", padding:1 }}>
      <CardContent sx={{display:"flex", justifyContent:"start", alignItems:"center", gap:"12px"}}>
      <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width:"40px", height:"40px", backgroundColor:"#fbbc05"  }} color="text.secondary" gutterBottom>
      {feedbackcount?.pendingFeedbackCount}
        </Typography>
        <Typography className='fs_10px custom-text' variant="h7" component="div">
        Feedback Panding    
        </Typography>
      </CardContent>
    </Card>
</Grid>


<Grid item xs={12} sm={6} md={4}>
    <Card sx={{ maxWidth: 300, backgroundColor:"#C9F4D1", padding:1 }}>
      <CardContent sx={{display:"flex", justifyContent:"start", alignItems:"center", gap:"12px"}}>
        <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width:"40px", height:"40px", backgroundColor:"#3CAB4F"  }} color="text.secondary" gutterBottom>
        {feedbackcount?.completedFeedbackCount}
        </Typography>
        <Typography className='fs_10px custom-text' variant="h7" component="div">
        Feedback Completed     
        </Typography>
      </CardContent>
    </Card>
</Grid>


<Grid item xs={12} sm={6} md={4}>
<Card sx={{ maxWidth: 300, backgroundColor:"#ffb3b3", padding:1 }}>
      <CardContent sx={{display:"flex", justifyContent:"start", alignItems:"center", gap:"12px"}}>
      <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width:"40px", height:"40px", backgroundColor:"#ef5959"  }} color="text.secondary" gutterBottom>
      {feedbackcount?.overdueFeedbackCount}
        </Typography>
        <Typography className='fs_10px custom-text' variant="h7" component="div">
        Feedback Overdue
                </Typography>
      </CardContent>
    </Card>
</Grid>


  </Grid>
      </Box>


      <div className="row mt-5">
        <div className="col-12 d-flex justify-content-end">
          <div class="dropdown">
            <button class="btn customBtnColor dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              Submit Care Meeting
            </button>
            <ul class="dropdown-menu">
              <li><Link className='dropdown-item' to="/add-positive-care-meeting-feedback">Add Positive Feedback</Link>
              </li>
              <li><Link className='dropdown-item' to="/add-negative-care-meeting-feedback">Add Negative Feedback</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Box sx={{my:5}}>

      {!load?<TableMui
  styleTableThead={{ 
    '& th': {
      color:"white",
      backgroundColor:"#4dbdb7",
      fontWeight: "700",
    whiteSpace: "nowrap",
    },
  }}
  th={{
    // "location.loc_no": "Ref Number",
    "sr1": "Ref Number",
    dateOfRequest: "Date Submitted",
    "type": "Type",
    "manager.name": "Name",
    // "additionalFields.feedback": "Description of Feedback",
    // "dateOfRequest": "Due Date",
    Status: "Status",
  }}
  td={data}
  customFields={[
    {
      name: "Status",
      data: (value, item) => (
        <Box className="" style={{width:"100px"}}>
        {console.log(item.status, "stst")}
              <Button
              variant="contained"
              // className='btn-pending'
              style={{ 
                // backgroundColor: "#FEBE1A",
               textDecoration: "none", 
               position: "relative",
              display: "inline-block",
              color: "#fff",
              padding: "8px",
              // width: "100%",
              // backgroundColor:
              background:
              item.status === "pending" ? "linear-gradient(to bottom, #fed56d, #FEB804)" :
              item.status === "overdue" ? "linear-gradient(to bottom, #ff7272, #FF5252)" :
              item.status === "completed" ? "linear-gradient(to bottom, #75b45e, #2FAF01)" :
              ""
             }}
            >
              <span 
              // className='btn-inner-pending'
              style={{
                display: "block",
                color: "#fff",
                width: "100%",
                paddingTop: "6px",
                paddingBottom: "6px",
                background: 
                item.status === "pending" ? "linear-gradient(to top, #fed56d, #FEB804)":
                item.status === "overdue" ? "linear-gradient(to top, #ff7272, #FF5252)":
                item.status === "completed" ? "linear-gradient(to top, #75b45e, #2FAF01)":
                "",
                minWidth: "120px"

              }}
              
              >
                {item.status || "no status"}
                </span>
            </Button>

            {/* {item.Types === "type2" ? (
            ) : item.Types === "type3" ? (
              // Conditional Rendering: Pending Button
              <Button
                variant="contained"
                className='btn-pending'
                // style={{ backgroundColor: "#FEB804", textDecoration: "none" }}
                // onClick={() => {
                  // }}
                >
                  <span className='btn-inner-pending'>Pending</span>
                </Button>
              ) : (
                <Button
                variant="contained"
                className='btn-complete'
              >
                <span className='btn-inner-complete'>Complete</span>
              </Button>
                  )

                  } */}

          </Box>
      
      ),
    },
    {
      // name: "location.loc_no",
      name: "sr1",
      data: (value, item) => (
        <Box className="d-flex gap-3 " style={{width:"100px"}}>
          <Link style={{textDecoration:"none"}} to={`/request-meeting-details/${item._id}`}>
           {/* {item.location.loc_no} */}
           { item.sr1}
          </Link>


       </Box>
      
      ),
    }
  ]}

/>:<div className="d-flex justify-content-center align-items-center"><CircularProgress/></div> }


</Box>



    </div>
  )
}

export default ReqMetting