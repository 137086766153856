import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CircularProgress, Grid } from '@mui/material';
import TableMui from '../mui/TableMui';
import { toast } from "react-toastify";
import { FiSearch } from "react-icons/fi";
import apiClient from '../api/apiClient';
import { formatDate } from '../modules/helpers';
import { Link, useNavigation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Paginate from '../mui/Paginate';
import TextDisplay from '../components/TextDisplay'
import { FaTrash } from 'react-icons/fa';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover, // Hover background color
    color: theme.palette.error.main, // Change icon color on hover
  },
}));


const limit = 10;
const SgowRound = () => {
  const filter = useSelector((state) => state.filter?.filter);

  const [data, setdata] = useState([]);
  const [feedbackcount, setfeedbackcount] = useState("")
  const [loading, setloading] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0)
  const {userType} = useSelector((state)=> state.auth )


  const handleDelete = async (id) => {
    try {
      // Make the delete request
      const response = await apiClient.delete(`/show-round/${id}`);

      // Check if the response was successful
      if (response.status === 204) {
        toast.success("Feedback deleted successfully!");
      } else {
        // Handle the case where the deletion was not successful
        toast.error(response.data?.message || "Failed to delete feedback.");
      }
    } catch (error) {
      // Handle any errors that occurred during the request
      toast.error(`Error: ${error.message || "An error occurred while deleting feedback."}`);
    }
    fetchdata()
    //    setdata((pre)=>
    // pre.map((feedback)=>feedback._id.toString() !== id)  
    //   )
  };


  const fetchdata = async () => {
    setloading(true);
    // seterror(null); // Reset error state before fetching data

    try {
      const result = await apiClient.get(`/show-round?location=${filter || ""}&page=${page}&limit=${limit}`);
      console.log(result, result?.data?.getShowround, "showround data");

      let data = result?.data?.getShowround?.map((item) => ({
        ...item,
        dateOfRequest: formatDate(item.dateOfRequest),
        dateOfVisit: formatDate(item.dateOfVisit)
      })) || [];

      setdata(data);

      let counts = result?.data || {};
      setfeedbackcount(counts || 0);

      if (!count) setCount(result?.data.count);

      for (let index = 0; index < data?.length; index++) {
        let sr;
        if (page === 1) {
          sr = (page - 1) + (index + 1); // For page 1
        } else {
          sr = (page - 1) * limit + (index + 1); // For other pages
        }
        data[index].sr1 = sr;
      }
    } catch (error) {
      console.error("Error fetching showround data:", error);
      // seterror("Failed to fetch data. Please try again later.");
    } finally {
      setloading(false);
    }
  };



  useEffect(() => {
    fetchdata()
  }, [filter, page]);



  const handlePageChange = (event, page) => {
    setPage(page);
  };
  return (
    <div
    className=' mt-5 mt-md-0'
    
    >


      {/* <Box className="test container ">
        <Grid container spacing={2}>


          <Grid item xs={12} sm={6} md={4}>

            <Card sx={{ maxWidth: 300, backgroundColor: "#FEFFD1", padding: 1 }}>
              <CardContent sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "12px" }}>
                <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width: "40px", height: "40px", backgroundColor: "#E9EB92" }} color="text.secondary" gutterBottom>
                  {feedbackcount?.count}
                </Typography>
                <Typography className='white_space fw-bold custom-text' variant="h7" component="div">
                  Feedback Recieved
                </Typography>
              </CardContent>
            </Card>

          </Grid>

          <Grid item xs={12} sm={6} md={4}>

            <Card sx={{ maxWidth: 300, backgroundColor: "#C9F4D1", padding: 1 }}>
              <CardContent sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "12px" }}>
                <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm  rounded' sx={{ width: "40px", height: "40px", backgroundColor: "#3CAB4F" }} color="text.secondary" gutterBottom>
                  {feedbackcount?.positiveFeedbackCount}
                </Typography>
                <Typography className='white_space fw-bold custom-text' variant="h6" component="div">
                  Positive Feedback
                </Typography>
              </CardContent>
            </Card>

          </Grid>

          <Grid item xs={12} sm={6} md={4}>

            <Card sx={{ maxWidth: 300, backgroundColor: "#C9F4D1", padding: 1 }}>
              <CardContent sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "12px" }}>
                <Typography className='d-flex justify-content-center  align-items-center text-white p-3 shadow-sm white_space rounded' sx={{ width: "50px", height: "50px", backgroundColor: "#3CAB4F", fontSize: "11px" }} color="text.secondary" gutterBottom>
                  {feedbackcount?.positiveFeedbackCountPercentage} %
                </Typography>
                <Typography className='white_space fw-bold custom-text' variant="h6" component="div">
                  Percentage Positive
                </Typography>
              </CardContent>
            </Card>


          </Grid>


        </Grid>

      </Box> */}

      <div className="row ">
        <div className="col-12 d-flex justify-content-end">
          <button class="btn customBtnColor " type="button">
            <Link style={{ textDecoration: "none", color: "white" }} className='' to="/add-showround-feedback">
              Submit Showround
            </Link>
          </button>
        </div>
      </div>


      <Box sx={{ my: 5 }}>

        {!loading ? <TableMui
          styleTableThead={{
            '& th': {
              color: "white",
              backgroundColor: "#4dbdb7",
              fontWeight: "700",
              whiteSpace: "nowrap",
            },
          }}
          th={{
            "serial": "Ref Number",

            // "location.loc_no": "Ref Number",
            // dateOfRequest: "Date Submitted",
            // feedback: "Positive/Negative",
            // rating:"Rating",
            // mostLike:"Likes",
            // otherComment:"Comments",

            improvement: "Name",
            otherComment: "Description of Feedback",
            "dateOfRequest": "Date Submitted",
            dateOfVisit: "Date of Visit",
            Status: "Status",
          ...(userType !== 'MN' && { Delete: 'Delete' }) 
          }}
          td={data}
          customFields={[
            {
              name: "Delete",
              data: (value, item) => (
                <Box className="" style={{ width: "100px" }}>


                  <StyledIconButton className='' onClick={() => {
                    // // console.log('assasa')
                    handleDelete(item._id)
                    // alert(item.showround._id)

                  }}>
                    <FaTrash />
                  </StyledIconButton>



                </Box>

              ),
            },
            {
              name: "serial",
              data: (value, item) => (
                <>
                  <Link style={{ textDecoration: "none" }} to={`/showround-details/${item._id}`}>
                    <span style={{ fontSize: 14, color: '#0D6EFD' }}>
                    {   item.serialName}

                    </span>
                  </Link>
                  {/* {
  
                } */}
                </>


              )
            },
            {
              name: "otherComment",
              data: (value, item) => (
                <>
                  <TextDisplay
                     text={item.otherComment}
                  />
                </>
              )
            },
            {
              name: "Status",
              data: (value, item) => (

                <Box className="" style={{ width: "100px" }}>
                  {console.log(item.status, "stst")}
                  <Link style={{ textDecoration: "none" }} to={`/showround-details/${item._id}`}>

                    <Button
                      variant="contained"
                      // className='btn-pending'
                      style={{
                        // backgroundColor: "#FEBE1A",
                        textDecoration: "none",
                        position: "relative",
                        display: "inline-block",
                        color: "#fff",
                        padding: "8px",
                        // width: "100%",
                        // backgroundColor:
                        background:
                          item.status === "pending" ? "linear-gradient(to bottom, #fed56d, #FEB804)" :
                            item.status === "overdue" ? "linear-gradient(to bottom, #ff7272, #FF5252)" :
                              item.status === "completed" ? "linear-gradient(to bottom, #75b45e, #2FAF01)" :
                                "",
                        minWidth: "120px"

                      }}
                    >
                      <span
                        // className='btn-inner-pending'
                        style={{
                          display: "block",
                          color: "#fff",
                          width: "100%",
                          paddingTop: "6px",
                          paddingBottom: "6px",
                          background:
                            item.status === "pending" ? "linear-gradient(to top, #fed56d, #FEB804)" :
                              item.status === "overdue" ? "linear-gradient(to top, #ff7272, #FF5252)" :
                                item.status === "completed" ? "linear-gradient(to top, #75b45e, #2FAF01)" :
                                  ""
                        }}

                      >
                        {item.status || "no status"}
                      </span>
                    </Button>
                  </Link>
                  {/* {item.Types === "type2" ? (
            ) : item.Types === "type3" ? (
              // Conditional Rendering: Pending Button
              <Button
                variant="contained"
                className='btn-pending'
                // style={{ backgroundColor: "#FEB804", textDecoration: "none" }}
                // onClick={() => {
                  // }}
                >
                  <span className='btn-inner-pending'>Pending</span>
                </Button>
              ) : (
                <Button
                variant="contained"
                className='btn-complete'
              >
                <span className='btn-inner-complete'>Complete</span>
              </Button>
                  )

                  } */}

                </Box>

              ),
            },
            // {
            //   name: "sr1",
            //   data: (value, item) => (
            //     <Box className="d-flex gap-3 " style={{ width: "100px" }}>
            //       <Link style={{ textDecoration: "none" }} to={`/showround-details/${item._id}`}>
            //         {/* {item.location.loc_no} */}
            //         {item.sr1}
            //       </Link>


            //     </Box>

            //   ),
            // }

          ]}
        /> : <div className="d-flex justify-content-center align-items-center"><CircularProgress /></div>}



      </Box>
      <div className=" d-flex justify-content-end my-3">
        <Paginate count={count} limit={limit} page={page} onChange={handlePageChange} />
      </div>

    </div>
  )
}

export default SgowRound