import React, { useEffect, useState } from 'react';
// import { GoogleMap, LoadScript, DrawingManager, useJsApiLoader } from '@react-google-maps/api';
import { GoogleMap, LoadScript, DrawingManager, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import { CircularProgress } from '@mui/material';

const libraries = ['drawing', 'places'];


const MapComponent = ({onCoordinatesUpdate, onAreaUpdate ,handleLocationDetails}) => {
    const [mapLoaded, setMapLoaded] = useState(false);
const [place,setPlace] = useState(null)
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyAu1gwHCSzLG9ACacQqLk-LG8oJMkarNF0',
        libraries: ['drawing', 'places'],
    });

    const [map, setMap] = useState(null);
    const [autocomplete, setAutocomplete] = useState(null);

    const mapContainerStyle = {
        width: '100%',
        height: '400px'
    };

    // const [center, setCenter] = useState({
    //     lat: 32.46796271058749,
    //     lng: 74.51528751199446,
    //   });
    const [center, setCenter] = useState({
      lat: 51.5074,
      lng: -0.1278,
    });

    const onPolygonComplete = (polygon) => {
        if (isLoaded) {
   
            const vertices = polygon.getPath().getArray();
            const coordinates = vertices.map((vertex) => {
                return { lat: vertex.lat(), lng: vertex.lng() };
            });

            onCoordinatesUpdate(coordinates)


            console.log('coordinates:', coordinates);

            const area = window.google.maps.geometry.spherical.computeArea(polygon.getPath());
            onAreaUpdate(area)
            console.log('Area:', area);
        } else if (loadError) {
            console.error('Error loading Google Maps API:', loadError);
        }
    };

    const onLoad = (autocompleteInstance) => {
        setAutocomplete(autocompleteInstance);
      };
    
      const onPlaceChanged = () => {
        if (autocomplete !== null) {
          const place = autocomplete.getPlace();
        //   console.log('johnPlace', place)
        //   setPlace(place)
          handleLocationDetails(place)
          if (place.geometry) {
            const newCenter = {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            };
            setCenter(newCenter);
            map.panTo(newCenter);
            map.setZoom(12);
          } else {
            console.log('No geometry for the place');
          }
        }
      };
    


    return isLoaded ? (
        // <LoadScript
        //     googleMapsApiKey="AIzaSyAu1gwHCSzLG9ACacQqLk-LG8oJMkarNF0"
        //     libraries={libraries}
        // >
        <>
        <Autocomplete 
        className=' '
        onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
          <input
          className="w-100 form-control mb-3"
            type="text"
            placeholder="Search for a city"
            // style={{
            //   boxSizing: `border-box`,
            //   border: `1px solid transparent`,
            //   width: `240px`,
            //   height: `32px`,
            //   padding: `0 12px`,
            //   borderRadius: `3px`,
            //   boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
            //   fontSize: `14px`,
            //   outline: `none`,
            //   textOverflow: `ellipses`,
            //   position: "absolute",
            //   left: "50%",
            //   marginLeft: "-120px",
            //   top: "10px",
            // }}
          />
        </Autocomplete>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={12}
          onLoad={(mapInstance) => setMap(mapInstance)}
          options={{
            disableDefaultUI: true,
            mapTypeControl: true,
            zoomControl: true,
          }}
        >
          <DrawingManager
            onPolygonComplete={onPolygonComplete}
            options={{
              drawingControl: true,
              drawingControlOptions: {
                position: window.google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
              },
              polygonOptions: {
                editable: true,
                draggable: true,
                fillColor: '#FF0000',
                fillOpacity: 0.35,
                strokeColor: '#FF0000',
                strokeWeight: 5,
              },
            }}
          />
        </GoogleMap>
      </>
    ) :
    <div className='d-flex justify-content-center mt-5'>
    
    <CircularProgress/>;
    </div>
        //    <GoogleMap
        //         mapContainerStyle={mapContainerStyle}
        //         center={center}
        //         zoom={12}
        //         options={{
        //             disableDefaultUI: true,
        //             mapTypeControl: true,
        //             zoomControl: true,
        //         }}
        //     >
        //         <DrawingManager
        //             onPolygonComplete={onPolygonComplete}
        //             options={{
        //                 drawingControl: true,
        //                 drawingControlOptions: {
        //                     position: window.google.maps.ControlPosition.TOP_CENTER,
        //                     drawingModes: [
        //                         window.google.maps.drawing.OverlayType.POLYGON
        //                     ]
        //                 },
        //                 polygonOptions: {
        //                     editable: true,
        //                     draggable: true,
        //                     fillColor: '#FF0000',
        //                     fillOpacity: 0.35,
        //                     strokeColor: '#FF0000',
        //                     strokeWeight: 5
        //                 }
        //             }}
        //         />
        //     </GoogleMap> 
            
        // </LoadScript>
    // ): null
};

export default MapComponent;
