import { Button, Modal } from 'react-bootstrap';
import React, { useState } from 'react'
import { FaCheckCircle } from "react-icons/fa";
import apiClient from '../api/apiClient';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
export const MarkModal = (props) => {
  const navigate = useNavigate()
  // const [status, setstatus] = useState("completed")
  const { id, setIsChecked,setIsClicked ,  setmodalshow, ...modalProps } = props;
  console.log(id, "id in props")
  // console.log(props, "props")

  const handleConfirm = () => {
    setIsChecked(true); // Update isChecked to true
    setmodalshow(false); // Close the modal
    setIsClicked(true); // Close the modal
  };
  

  return (
    <Modal
    {...modalProps} // Pass other props like show, onHide, etc.
    // onHide
      // show
      // onExit
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='rounded-3'
    >
      
      <Modal.Body className='text-center rounded-3'>
      {/* <FaCheckCircle  className="display-1" style={{color:"#029E96"}}/>
        <h5 className='mt-2'>Email Sent</h5> */}
        <p>Are you sure you want to mark this submission as complete?</p>
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-between align-items-center'>
        <div
         style={{ textDecoration: "none" ,color:'white'}} className='' to={-1}>
        <button className='btn btn-success btn-block m-1' onClick={handleConfirm}>
            
            Yes
            </button>
            </div>
        <button className='btn btn-danger btn-block m-1' onClick={props.onHide}>No</button>
      </Modal.Footer>
      
    </Modal>
  );
}
  